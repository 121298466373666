// Variable overrides
$pink-color: #c6538c;
$darkpurple:#253040;
$accordian:#F2F2F2;
$black:#000;
$white:#fff;
$bodyBg:#F9F6F7;
$file_bg:#FAFAFB;
$font-black : #1C2D41;
$placeHolder : #C8CED7;
$fontText2 : #28263D;
$fontText3 : #6C7B95;
$fontText4 : #B2B9C6;
$inpbg:#E5E9F2;
$lightgray:#EEECED;
$lightgray2:#E0E3E9;
$lightgray3:#F1ECEE;
$lightgray4: #F2F2F7;
$lightgray5: #E1E6F4;
$lightgray6: #b8b8b8;
$darkgray:#BABECA;
$border_light :#F0F3F4;
$border_1 :#e4e7ea;
$dropdowntext : #887b7f;
$light_text:#D6D0D2;
$border2:#DFD4D8;
$error :#cb3837;
$remove:#FE3434;
$light_remove:#FF788F;
$graydark:#8392A5;
$border_light2: #D9DEE4;
$light_errorBg:#FFEAEA;
$green:#96CF04;
$dark_green:#1b901b;
$light_blue:#4CE3DC;
$dark_blue:#5EEBE5;
$blue_btn:#4D8AAE;
$light_bg:#F7F8FB;
$linkcolor : #02A8A8;
$weight400: 400;
$weight500: 500;
$weight700: 700;
$blue_text: #5c95c6;

// report page variables

$thbg: #EAEEF5;
$tdbg: #F8F9FC;
$tblborder:#D1D5DA;
$columnhover:#EFF0F1;
$thcolor:#6B6B6F;
$reporttext:#182B49;
$btncolor :#4B4C4E;



$Roboto:'Roboto', ;
$HvRegular: "Helvetica Regular";
$Hvmed: "Helvetica Medium";
$Hvbold: "Helvetica Bold";
$Hvextrabold: "Helvetica ExtraBold";
$FontAwesome: "FontAwesome";
$boxshadow : 0 2px 2px rgba(131,146,165,0.1);



