/*      Dash Board + Header     */

/* this css set for checkbox appear at safari */
input[type="checkbox"]{
  -webkit-appearance: checkbox;
  appearance: auto;
}

.max_content{
  width: max-content !important;
}
.app-header {
  top: 0;
  right: 0;
  height: auto;
  h1 {
    margin-bottom: 0;
  }
  .nav-item {
    padding: 0 24px;
    margin-left: 10px;
    height: 60px;
    @include display_flex;
    @include align_center;
    @include just_center;
    border-left: 1px solid $border_light;
    @include mediaq(480px) {
      padding: 0;
    }
    .nav-link {
      .badge {
        width: 15px;
        height: 15px;
        margin: 0;
        top: 15px;
        padding: 0;
        font-size: 10px;
        @include display_flex;
        @include align_center;
        @include just_center;

      }
      .img-avatar {
        width: 40px;
        height: 40px;
        object-fit: cover;
      }
    }
  }
  .menu_toggle {
    font-size: 20px;
    cursor: pointer;
    @include mediaqmin(1025px) {
      display: none;
    }
  }
}
.text-center{
  text-align: center
}
.topheader {
 
  @include align_center;
  @include justify_sp;
  display: flex;
  padding: 0 30px;
  @include mediaq(991px) {
    padding: 0 10px;
  }
}

.sidebar-fixed .sidebar,
.sidebar {
  top: 0;
  z-index: 1021;
  width: 270px;
  height: 100% !important;
  background: url("/img/sidebar_bg.png") no-repeat;
  background-size: cover;
  @include mediaq(991px) {
    width: 90px; // margin-left: 0 !important;
  }
  .sidebar-header {
    padding: 0;
    background: rgba(0, 0, 0, 0);
    @include mediaqmin(992px) {
      display: block;
    }
    .create_event_row {
      .select_custom {
        width: 70%;
        margin: 15px auto 0;
        select:focus,
        select:active,
        select:hover {
          outline: none;
          appearance: none;
          width: 100%;
        }
      }
    }
    .sidebar_logo_dropdown {
      position: relative;
      padding: 15px 0px;
      display: block;
      a {
        max-width: 200px;
        display: block;
        margin: 0 auto;
        position: relative;
        i {
          position: absolute;
          color: $black;
          font-size: 16px;
          right: -30px;
          top: 25%;
          background: $light_bg;
          border-radius: 100%;
          width: 18px;
          height: 18px;
          display: flex;
          align-items: center;
          justify-content: center;
          transform: rotate(-90deg);
        }
        img {
          margin: 0 auto;
        }
      }
      ul {
        position: absolute;
        left: 100%;
        right: auto;
        width: 100%;
        background-color: $white;
        z-index: 99999;
        top: 0;
        transform: translateY(20%);
        opacity: 0;
        visibility: hidden;
        transition: all 0.5s ease 0s;
        box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.09);
        li {
          display: block;
          transition: all 0.5s ease 0s;
          background-color: $white;
          a {
            padding: 10px 15px;
            color: $black;
            display: block;
            text-decoration: none;
            transition: all 0.5s ease 0s;
            font-size: 16px;
            font-weight: 600;
          }
          &.active,
          &:hover {
            background-color: $linkcolor;
            a {
              color: $white;
              transition: all 0.5s ease 0s;
            }
          }
        }
      }
      &:hover {
        ul {
          transform: translateY(30%);
          opacity: 1;
          visibility: visible;
          transition: all 0.5s ease 0s;
        }
      }
    }
  }
  .nav-dropdown-toggle {
    position: relative;
    &::before {
      content: "\f105";
      font-family: "FontAwesome";
      background: none;
      background-image: none !important;
      top: 50%;
      transform: translateY(-50%);
      transition: 0.5s ease;
    }
  }
  .sidebar-nav,
  .nav {
    width: 270px;
  }
  .sidebar-nav {
    .nav {
      padding-bottom: 100px;
    }
  }
  .sidebar_logo {
    text-align: center;
    a {
      display: block;
      padding: 29px 80px 25px;
      @include mediaq(991px) {
        padding: 48px 14px;
      }
      @include mediaq(768px) {
        padding: 30px 14px;
      }
      img {
        max-width: 100px;
        object-fit: cover;
        margin: auto;
        display: block;
        transition: 0.5s ease;
        @include mediaq(991px) {
          max-width: 60px;
        }
      }
      &.bclogo {
        padding: 30px 15px;
        margin: 0 auto;
        @include mediaq(991px) {
          padding: 25px 15px;
        }
        img {
          max-width: 200px;
          object-fit: cover;
          margin: auto;
          display: block;
          transition: 0.5s ease;
          @include mediaq(991px) {
            max-width: 160px;
          }
        }
      }
    }
  }
  .nav-dropdown.open {
    background: rgba(77, 138, 174, 0.6);
    .nav-dropdown-toggle {
      &:hover {
        background: $black;
        color: $white;
      }
    }
    .nav-dropdown-toggle {
      &::before {
        transform: translateY(-50%);
        content: "\f107 ";
      }
    }
    .nav-dropdown-items {
      .nav-link {
        &:hover {
          background: $black;
        }
      }
    }
  }
  .nav-item {
    .nav-link {
      padding: 20px 30px 19px; // @include display_flex;
      // @include align_center;
      font-size: 15px;
      font-family: $Roboto;
      font-weight: $weight400;
      line-height: 20px;
      display: flex;
      align-items: center;
      .icon_add {
        background: url("/MenuIcons/plus.png") no-repeat;
        background-size: contain;
      }
      .icon_viewall {
        background: url("/MenuIcons/see.png") no-repeat;
        background-size: contain;
      }
      .icon_league {
        background: url("/MenuIcons/tournament.png") no-repeat;
        background-size: contain;
      }
      .icon_marketing {
        background: url("/MenuIcons/marketing-2.png") no-repeat;
        background-size: contain;
      }
      .icon_coupon {
        background: url("/MenuIcons/coupon.png") no-repeat;
        background-size: contain;
      }
      .icon_addbaner {
        background: url("/MenuIcons/ad.png") no-repeat;
        background-size: contain;
      }
      .icon_reward {
        background: url("/MenuIcons/.png") no-repeat;
        background-size: contain;
      }
      .icon_push_notification {
        background: url("/MenuIcons/notifications.png") no-repeat;
        background-size: contain;
      }
      .icon_social_media {
        background: url("/MenuIcons/heart.png") no-repeat;
        background-size: contain;
      }
      .icon_club_timline {
        background: url("/MenuIcons/graph.png") no-repeat;
        background-size: contain;
      }
      .icon_reported_post {
        background: url("/MenuIcons/spam.png") no-repeat;
        background-size: contain;
      }
      .icon_bc_report {
        background: url("/MenuIcons/bc_reporting.png") no-repeat;
        background-size: contain;
      }
      .icon_store {
        background: url("/MenuIcons/shopping-cart.png") no-repeat;
        background-size: contain;
      }
      .icon_product_catlog {
        background: url("/MenuIcons/Store.png") no-repeat;
        background-size: contain;
      }
      .icon_store_order {
        background: url("/MenuIcons/robot.png") no-repeat;
        background-size: contain;
      }
      .icon_product_cat {
        background: url("/MenuIcons/category.png") no-repeat;
        background-size: contain;
      }
      .icon_apptool {
        background: url("/MenuIcons/wrench.png") no-repeat;
        background-size: contain;
      }
      .icon_tooltip {
        background: url("/MenuIcons/Information-icon.png") no-repeat;
        background-size: contain;
      }
      .icon_welcome {
        background: url("/MenuIcons/edit.png") no-repeat;
        background-size: contain;
      }
      .icon_colleges {
        background: url("/MenuIcons/school-2.png") no-repeat;
        background-size: contain;
      }
      .icon_team_transfer {
        background: url("/MenuIcons/transfer.png") no-repeat;
        background-size: contain;
      }
      .icon_faq {
        background: url("/MenuIcons/faq.png") no-repeat;
        background-size: contain;
      }
      .icon_page_content {
        background: url("/MenuIcons/contract.png") no-repeat;
        background-size: contain;
      }
      .icon_contact {
        background: url("/MenuIcons/contact.png") no-repeat;
        background-size: contain;
      }
      .icon_uploaddata {
        background: url("/MenuIcons/uploaddata.png") no-repeat;
        background-size: contain;
      }
      .icon_productmaster {
        background: url("/MenuIcons/productmaster.png") no-repeat;
        background-size: contain;
      }
      .icon_productfeamaster {
        background: url("/MenuIcons/productfeamaster.png") no-repeat;
        background-size: contain;
      }
      .icon_productpackage {
        background: url("/MenuIcons/productpackage.png") no-repeat;
        background-size: contain;
      }
      .icon_usertransaction {
        background: url("/MenuIcons/usertransaction.png") no-repeat;
        background-size: contain;
      }
      .icon_uploadcsv {
        background: url("/MenuIcons/uploaddata.png") no-repeat;
        background-size: contain;
      }
      .icon_claimproteam {
        background: url("/MenuIcons/icon_claimproteam.png") no-repeat;
        background-size: contain;
      }
      &.active {
        background: rgba(77, 138, 174, 0.6);
        color: $dark_blue;
        font-weight: $weight700;
        // pointer-events: none;
        i {
          color: $dark_blue;
        }
        .icon_add {
          background: url("/ActivemenuIcons/plus.png") no-repeat;
          background-size: contain;
        }
        .icon_viewall {
          background: url("/ActivemenuIcons/see.png") no-repeat;
          background-size: contain;
        }
        .icon_league {
          background: url("/ActivemenuIcons/tournament.png") no-repeat;
          background-size: contain;
        }
        .icon_marketing {
          background: url("/ActivemenuIcons/marketing-2.png") no-repeat;
          background-size: contain;
        }
        .icon_coupon {
          background: url("/ActivemenuIcons/coupon.png") no-repeat;
          background-size: contain;
        }
        .icon_addbaner {
          background: url("/ActivemenuIcons/ad.png") no-repeat;
          background-size: contain;
        }
        .icon_reward {
          background: url("/ActivemenuIcons/.png") no-repeat;
          background-size: contain;
        }
        .icon_push_notification {
          background: url("/ActivemenuIcons/notifications.png") no-repeat;
          background-size: contain;
        }
        .icon_social_media {
          background: url("/ActivemenuIcons/heart.png") no-repeat;
          background-size: contain;
        }
        .icon_club_timline {
          background: url("/ActivemenuIcons/graph.png") no-repeat;
          background-size: contain;
        }
        .icon_reported_post {
          background: url("/ActivemenuIcons/spam.png") no-repeat;
          background-size: contain;
        }
        .icon_bc_report {
          background: url("/ActivemenuIcons/bc_reporting.png") no-repeat;
          background-size: contain;
        }
        .icon_store {
          background: url("/ActivemenuIcons/shopping-cart.png") no-repeat;
          background-size: contain;
        }
        .icon_product_catlog {
          background: url("/ActivemenuIcons/Store.png") no-repeat;
          background-size: contain;
        }
        .icon_store_order {
          background: url("/ActivemenuIcons/robot.png") no-repeat;
          background-size: contain;
        }
        .icon_product_cat {
          background: url("/ActivemenuIcons/category.png") no-repeat;
          background-size: contain;
        }
        .icon_apptool {
          background: url("/ActivemenuIcons/wrench.png") no-repeat;
          background-size: contain;
        }
        .icon_tooltip {
          background: url("/ActivemenuIcons/Information-icon.png") no-repeat;
          background-size: contain;
        }
        .icon_welcome {
          background: url("/ActivemenuIcons/edit.png") no-repeat;
          background-size: contain;
        }
        .icon_colleges {
          background: url("/ActivemenuIcons/school-2.png") no-repeat;
          background-size: contain;
        }
        .icon_team_transfer {
          background: url("/ActivemenuIcons/transfer.png") no-repeat;
          background-size: contain;
        }
        .icon_faq {
          background: url("/ActivemenuIcons/faq.png") no-repeat;
          background-size: contain;
        }
        .icon_contact {
          background: url("/ActivemenuIcons/contact.png") no-repeat;
          background-size: contain;
        }
        .icon_page_content {
          background: url("/ActivemenuIcons/contract.png") no-repeat;
          background-size: contain;
        }
        .icon_uploaddata {
          background: url("/ActivemenuIcons/uploaddata.png") no-repeat;
          background-size: contain;
        }
        .icon_productmaster {
          background: url("/ActivemenuIcons/productmaster.png") no-repeat;
          background-size: contain;
        }
        .icon_productfeamaster {
          background: url("/ActivemenuIcons/productfeamaster.png") no-repeat;
          background-size: contain;
        }
        .icon_productpackage {
          background: url("/ActivemenuIcons/productpackage.png") no-repeat;
          background-size: contain;
        }
        .icon_usertransaction {
          background: url("/ActivemenuIcons/usertransaction.png") no-repeat;
          background-size: contain;
        }
        .icon_uploadcsv {
          background: url("/ActivemenuIcons/uploaddata.png") no-repeat;
          background-size: contain;
        }
        .icon_claimproteam {
          background: url("/ActivemenuIcons/icon_claimproteam.png") no-repeat;
          background-size: contain;
        }
      }
      &:hover {
        .nav-dropdown-toggle {
          &::before {
            background-image: none;
          }
        }
      }
      .nav-icon {
        color: $white;
        font-size: 17px;
        margin-right: 30px;
        width: 25px;
        height: 20px;
        display: inline-block;
      }
      &:hover {
        background: rgba(77, 138, 174, 0.6);
        color: $dark_blue;
        .icon_add {
          background: url("/ActivemenuIcons/plus.png") no-repeat;
          background-size: contain;
        }
        .icon_viewall {
          background: url("/ActivemenuIcons/see.png") no-repeat;
          background-size: contain;
        }
        .icon_league {
          background: url("/ActivemenuIcons/tournament.png") no-repeat;
          background-size: contain;
        }
        .icon_marketing {
          background: url("/ActivemenuIcons/marketing-2.png") no-repeat;
          background-size: contain;
        }
        .icon_coupon {
          background: url("/ActivemenuIcons/coupon.png") no-repeat;
          background-size: contain;
        }
        .icon_addbaner {
          background: url("/ActivemenuIcons/ad.png") no-repeat;
          background-size: contain;
        }
        .icon_reward {
          background: url("/ActivemenuIcons/.png") no-repeat;
          background-size: contain;
        }
        .icon_push_notification {
          background: url("/ActivemenuIcons/notifications.png") no-repeat;
          background-size: contain;
        }
        .icon_social_media {
          background: url("/ActivemenuIcons/heart.png") no-repeat;
          background-size: contain;
        }
        .icon_club_timline {
          background: url("/ActivemenuIcons/graph.png") no-repeat;
          background-size: contain;
        }
        .icon_reported_post {
          background: url("/ActivemenuIcons/spam.png") no-repeat;
          background-size: contain;
        }
        .icon_bc_report {
          background: url("/ActivemenuIcons/bc_reporting.png") no-repeat;
          background-size: contain;
        }
        .icon_store {
          background: url("/ActivemenuIcons/shopping-cart.png") no-repeat;
          background-size: contain;
        }
        .icon_product_catlog {
          background: url("/ActivemenuIcons/Store.png") no-repeat;
          background-size: contain;
        }
        .icon_store_order {
          background: url("/ActivemenuIcons/robot.png") no-repeat;
          background-size: contain;
        }
        .icon_product_cat {
          background: url("/ActivemenuIcons/category.png") no-repeat;
          background-size: contain;
        }
        .icon_apptool {
          background: url("/ActivemenuIcons/wrench.png") no-repeat;
          background-size: contain;
        }
        .icon_tooltip {
          background: url("/ActivemenuIcons/Information-icon.png") no-repeat;
          background-size: contain;
        }
        .icon_welcome {
          background: url("/ActivemenuIcons/edit.png") no-repeat;
          background-size: contain;
        }
        .icon_colleges {
          background: url("/ActivemenuIcons/school-2.png") no-repeat;
          background-size: contain;
        }
        .icon_team_transfer {
          background: url("/ActivemenuIcons/transfer.png") no-repeat;
          background-size: contain;
        }
        .icon_faq {
          background: url("/ActivemenuIcons/faq.png") no-repeat;
          background-size: contain;
        }
        .icon_contact {
          background: url("/ActivemenuIcons/contact.png") no-repeat;
          background-size: contain;
        }
        .icon_page_content {
          background: url("/ActivemenuIcons/contract.png") no-repeat;
          background-size: contain;
        }
        .icon_uploaddata {
          background: url("/ActivemenuIcons/uploaddata.png") no-repeat;
          background-size: contain;
        }
        .icon_productmaster {
          background: url("/ActivemenuIcons/productmaster.png") no-repeat;
          background-size: contain;
        }
        .icon_productfeamaster {
          background: url("/ActivemenuIcons/productfeamaster.png") no-repeat;
          background-size: contain;
        }
        .icon_productpackage {
          background: url("/ActivemenuIcons/productpackage.png") no-repeat;
          background-size: contain;
        }
        .icon_usertransaction {
          background: url("/ActivemenuIcons/usertransaction.png") no-repeat;
          background-size: contain;
        }
        .icon_uploadcsv {
          background: url("/ActivemenuIcons/uploaddata.png") no-repeat;
          background-size: contain;
        }
        .icon_claimproteam {
          background: url("/ActivemenuIcons/icon_claimproteam.png") no-repeat;
          background-size: contain;
        }
        i {
          color: $dark_blue;
        }
      }
      @include mediaq(991px) {
        overflow: hidden;
        &:hover {
          width: 100%;
          overflow: visible;
        }
      }
    }
  }
  .sidebar-minimizer {
    position: absolute;
    border: 0;
    top: 92vh;
    width: 60px;
    height: 60px;
    background: none;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.3);
    margin: auto;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: auto;
    &:before {
      width: 60px;
      height: 60px;
    }
    @include mediaq(991px) {
      display: none;
    }
  }
}
.sidebar-minimized {
  .sidebar_logo_dropdown {
    ul {
      width: 175px !important;
    }
  }
}
.sidebar {
  .sidebar-minimized {
    position: absolute;
    border: 0;
    bottom: 250px;
    width: 60px;
    height: 60px;
    background: none;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.3);
    margin: auto;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    &:before {
      width: 60px;
      height: 60px;
    }
  } // .nav-item {
  //     .nav-link {
  //         padding: 15px;
  //         .nav-icon {
  //             margin: 0;
  //         }
  //     }
  // }
}

.app-header {
  .dropdown-item {
    color: $font-black;
    padding: 0;
    i {
      color: $font-black;
    }
    a {
      color: $font-black;
      text-decoration: none;
    }
    &.active {
      background: none;
      color: $font-black;
    }
    &:hover {
      color: $white;
      background-color: $linkcolor;
      a {
        color: $white;
      }
      i {
        color: $white;
      }
    }
  }
}

.header-fixed {
  .app-header {
    width: calc(100% - 270px);
    @include mediaq(1024px) {
      width: 100%;
    }
    .nav-item {
      a {
        @include display_flex;
        @include align_center;
        @include just_center;
        width: 100%;
        font-size: 17px;
        padding: 10px 20px;
        @include mediaq(480px) {
          padding: 0;
        }
      }
    }
    @include mediaq(480px) {
      .dropdown-item {
        a {
          padding: 5px 20px;
        }
      }
    }
  }
}

.sidebar-minimized.sidebar-fixed {
  // .sidebar-nav{
  //     width: 90px;
  // }
  .app-header {
    width: calc(100% - 90px);
    @include mediaq(1024px) {
      width: 100%;
    }
  }
  .sidebar {
    width: 90px;
    overflow: hidden;
    &:hover {
      overflow: visible;
    }
    .nav-item {
      width: 100%; // width: 90px;
      // overflow: hidden;
      @include mediaqmin(1024px) {
        .nav-dropdown-items {
          left: 90px !important;
          top: 0 !important;
        }
      }
      .nav-link {
        padding: 20px 10px 20px 0;
        width: 290px;
        .nav-icon {
          margin-right: 15px;
          width: 90px;
          background-size: 20px;
          background-position: center;
          &.icon_bc_report {
            background-size: 25px;
          }
        }
      }
      &:hover {
        .nav-link {
          background: rgba(43, 40, 51, 0.5);
        }
      }
      .nav-item {
        .nav-link {
          .nav-icon {
            width: 50px;
          }
        }
      }
    }
    .nav {
      width: 90px;
    }
    .nav-dropdown {
      &:hover {
        background: rgba(43, 40, 51, 0.5);
      }
    }
    .sidebar_logo {
      a {
        padding: 20px 10px;
        img {
          max-width: 60px;
        }
      }
    }
  }
  .main {
    margin-left: 90px !important;
    @include mediaq(1024px) {
      margin-left: 0px !important;
    }
  }
}

@include mediaq(1024px) {
  .sidebar {
    margin-left: -300px !important;
  }
  &.sidebar_active {
    .sidebar {
      margin-left: 0 !important;
      width: 270px;
    }
  }
  .overlay {
    &.active {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1021;
      width: 100vw;
      height: 100vh;
      background-color: #000015;
      transition: 0.25s;
      opacity: 0.5;
    }
  }
}

.main {
  margin-left: 270px !important;
  @include mediaq(1024px) {
    margin-left: 0px !important;
  }
  .container-fluid {
    padding: 30px;
    position: relative;
    @include mediaq(991px) {
      padding: 20px 10px;
    }
  }
}

//  Header Side bar end css
.select_dropdown {
  select {
    border: none;
    background: none;
    color: $dropdowntext;
    outline: none;
    font-size: 16px;
    font-family: $Hvmed;
    -webkit-app-region: none;
  }
}

.dashboard_top_event {
  // margin-bottom: 14px;
  margin:10px 0;
  @include mediaq(991px) {
    margin-top: 15px;
  }
  .select_dropdown {
    margin-left: 30px;
    .angle_down {
      font-size: 18px;
    }
  }
  select {
  }
  h2 {
    font-family: $Hvbold;
    margin-bottom: 0;
  }
  .btn_bg{
    &.event-filter{
      margin-left: 15px;
    }
  }
}
.error-text{
  color: $fontText2;
  font-size: 16px;
  line-height: 20px;
  font-family: $Hvmed;
}
.event_card_img {
  position: relative;
  img {
    max-width: 100%;
    min-height: 200px;
    max-height: 200px;
    width: 100%;
    object-fit: cover;
    margin: auto;
  }
  .event_card_address {
    display: block;
    font-size: 14px;
    line-height: 23px;
    font-family: $HvRegular;
    padding: 4px 10px 4px 40px;
    position: absolute;
    right: 0;
    bottom: 0;
    color: $white;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 10px 0 0 0;
    &::before {
      content: "";
      background: url("/img/map_marker.svg") no-repeat;
      width: 12px;
      height: 16px;
      background-size: 100% 100%;
      left: 7px;
      z-index: 999;
      position: absolute;
      top: 7px;
    }
  }
}
.event_card_opt {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  justify-content: space-between;

  .events_btns {
    position: relative;
    right: 0;
    bottom: -10px;
    span {
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
    @include mediaq(768px) {
      width: 100%;
      text-align: right;
    }
  }
}
.events_btns {
  position: absolute;
  right: 20px;
  bottom: 0px;
  z-index: 92;
  @include mediaq(1240px){
    position: relative;
    text-align: right;
    right: 0;
    bottom: 0px;
    z-index: 92;
    top: -13px;
  }
  @include mediaq(575px){
    text-align: left;
    right: 0;
    bottom: 0px;
    z-index: 92;
    top: -13px;
  }
  span {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
    color: $white;
    font-size: 14px;
    line-height: 29px;
    color: $white;
    transition: 0.5s;
    display: inline-block;
    margin: 5px;
  }
  .on_off_chk {
    margin: 0;
    width: 40px;
    label {
      width: auto;
      height: auto;
      margin: 0 !important;
      vertical-align: top;
      margin-right: 10px;
      margin-top: 5px !important;
    }
    .switch-slider {
      margin: 0;
      width: 40px;
      height: 25px;
      &::before {
        top: 2px;
        left: 2px;
        width: 18px;
        height: 18px;
      }
    }
    .switch-input:checked ~ .switch-slider::before {
      transform: translateX(15px);
    }
  }
  .event_report_box {
    background: $blue_btn;
    font-size: 12px;
  }

  .event_applicant_list_box {
    background: $blue_btn;
    font-size: 12px;
  }
  .event_delete_box {
    background: $error;

    &:hover {
      opacity: 0.8;
    }
  }
  .event_recap_info {
    background: $dark_green;

    &:hover {
      opacity: 0.8;
    }
  }
}

.event_card {
  background: $white;
  border: none;
  box-shadow: 0 2px 2px rgba(131, 146, 165, 0.1);
  overflow: hidden;
}

.event_list_card_wrap {
  background: $white;
  position: relative;
  box-shadow: 0 2px 2px rgba(131, 146, 165, 0.1);
  .event_list_card {
    .event_list_logo {
      width: 100%;
      max-width: 150px;
      padding: 21px;
      @include mediaq(576px) {
        padding: 10px;
      }
      img {
        width: 108px;
        height: 108px;
        object-fit: cover;
        display: block;
        margin: auto;
      }
    }
    .event_list_card_img {
      width: 100%;
      max-width: 400px;
      @include mediaq(1370px) {
        max-width: 300px;
      }
      img {
        height: 150px;
        width: 100%;
        object-fit: cover;
      }
    }
    .event_right_side {
      width: calc(100% - 550px);
      padding: 0 30px;
      @include mediaq(1370px) {
        width: calc(100% - 450px);
        padding: 15px;
      }
      @include mediaq(1240px) {
        width: 100%;
        margin-top: 15px;
      }
    }
    .event_list_tags {
      width: 50%;
      padding: 0 20px;
      padding-top: 11px;
      @include mediaq(576px) {
        width: 100%;
        padding: 15px 0px;
      }
      span {
        padding: 10px 30px;
        border-radius: 20px;
        background: $bodyBg;
        min-width: 200px;
        font-family: $Roboto;
        font-weight: 500;
        color: $fontText3;
        margin-right: 30px;
        margin-bottom: 10px;
        white-space: nowrap;
        @include mediaq(576px) {
          padding: 10px;
        }
        &:last-child {
          margin-right: 0;
        }
        img {
          margin-right: 10px;
          width: 100%;
          max-width: 17px;
          max-height: 15px;
        }
      }
    }
    .event_list_card_titles {
      width: 30%;
      @include mediaq(576px) {
        width: 100%;
      }
      h3 {
        font-family: $Hvextrabold;
        color: $fontText2;
        font-size: 20px;
      }
      p {
        font-size: 14px;
        line-height: 23px;
        color: $fontText3;
        font-family: $Hvmed;
      }
    }
    .event_list_budget {
      width: 20%;
      @include mediaq(576px) {
        width: 100%;
        text-align: left;
        padding-bottom: 10px;
      }
      text-align: right;
      h2 {
        font-family: $Hvextrabold;
        color: $fontText2;
      }
      p {
        font-size: 14px;
        font-family: $Hvmed;
        color: $fontText3;
      }
    }
  }
  // .events_btns {
  //   bottom: 5px;
  // }
  .event_delete_box {
    background: $error;
    font-size: 14px;
    // position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 92;
    cursor: pointer;
    width: 30px;
    height: 30px;
    color: $white;
    text-align: center;
    border-radius: 50%;
    line-height: 29px;
    transition: 0.5s;
    &:hover {
      opacity: 0.8;
    }
  }
}

.list_grid_btn {
  @include display_flex;
  @include align_center;
  span {
    color: $light_text;
    font-size: 20px;
    padding: 6px;
    width: 31px;
    height: 31px;
    @include display_flex;
    @include align_center;
    cursor: pointer;
    &.active {
      color: $dropdowntext;
      background: $white;
      border-radius: 4px;
    }
  }
}

.event_card_desc {
  padding: 12px 10px 10px;
  @include display_flex;
  @include align_center;

  .event_card_logo {
    margin-right: 12px;
    width: 78px;
    height: 78px;
    padding: 12px;
    @include mediaq(1200px) {
      // width: 60px;
      // height: 60px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }
  .event_card_titles {
    width: calc(100% - 90px);
    position: relative;
    @include mediaq(1200px) {
      width: calc(100% - 90px);
    }
    h3 {
      margin-bottom: 10px;
      font-family: $Hvextrabold;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .event_card_dates {
    @include display_flex;
    @include align_center;
    @include justify_sp;
    p {
      font-family: $Hvmed;
      position: relative;
      padding-left: 26px;
      padding-right: 10px;
      margin-bottom: 5px;
      line-height: 24px;
    }
  }
  .date_icon {
    &:before {
      position: absolute;
      content: "";
      background: url("/img/date_icon.svg") no-repeat;
      width: 15px;
      height: 16px;
      background-size: cover;
      left: 0;
      top: 3px;
    }
  }
  .user_icon {
    &:before {
      position: absolute;
      content: "";
      background: url("/img/user_icons.svg") no-repeat;
      width: 18px;
      height: 16px;
      background-size: contain;
      left: 0;
      top: 5px;
    }
  }
  .team_icons {
    &:before {
      position: absolute;
      content: "";
      background: url("/img/user_icons.svg") no-repeat;
      width: 19px;
      height: 15px;
      background-size: cover;
      left: 0;
      top: 3px;
    }
  }
}

/*    End  Dash Board + Header     */

/*         Custom Popup        */

.custom_popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.55);
  display: none;
  transition: 0.5s ease;
  z-index: 9999;
  &.open {
    display: block;
  }
  .popup_pad {
    padding: 0 10px;
   
    .inner_popup {
      padding: 25px;
      background: #fff;
      border-radius: 10px;
      position: relative;
      @include mediaq(768px) {
        padding: 15px;
      }
      h1 {
        margin-bottom: 45px;
        font-family: $Hvbold;
        @include mediaq(768px) {
          margin-bottom: 20px;
        }
      }
      label {
        font-size: 15px;
        line-height: 20px;
        color: $font-black;
        font-family: $Hvmed;
      }
      .close_popup {
        position: absolute;
        right: 17px;
        top: 20px;
        color: $graydark;
        cursor: pointer;
        font-size: 18px;
        z-index: 2;
        @include mediaq(768px) {
          top: 10px;
          right: 8px;
        }
      }
      .input_contents {
        width: 100%;
        .file_input {
          padding: 70px;
        }
      }
      .create_event_row {
        input {
          background: $inpbg;
          &.input-custome{
            background: #f2f2f7;
          }
        }
        textarea {
          background: $inpbg;
        }
        select {
          background: $inpbg;
        }
        input[type="text"],
        .form-control:focus {
          // border: 0;
          box-shadow: none;
        }
      }
    }
    max-width: 500px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 98%;
    &.popup_510 {
      max-width: 510px;
      background: $white;
      border-radius: 10px;
      .delete_player_container {
        max-width: 230px;
        margin: auto;
        h2 {
          margin-bottom: 15px;
        }
        p {
          font-size: 16px;
          font-family: $Hvmed;
        }
      }
    }
    &.popup_540 {
      max-width: 540px;
      background: $white;
      border-radius: 10px;
    }
    &.popup_600 {
      max-width: 600px;
    }
    &.popup_310 {
      max-width: 310px;
      background: $white;
      border-radius: 10px;
    }
    &.popup_700 {
      max-width: 700px;
      background: $white;
      border-radius: 10px;
      max-height: 100%;
      overflow: auto;
    }
    &.popup_710 {
      max-width: 700px;
      background: $white;
      border-radius: 10px;
    }
    &.popup_720 {
      max-width: 720px;
      background: $white;
      border-radius: 10px;
      .inner_popup {
        form {
          max-height: 500px;
          overflow: auto;
          overflow-y: scroll;
          &::-webkit-scrollbar {
            background-color: $lightgray2;
            width: 7px;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #555;
          }
        }
      }
      .popup_fix_btn {
        position: fixed;
        bottom: 0px;
        left: 0px;
        right: 0;
        padding: 10px 0;
        background: $white;
        width: 97%;
        margin: auto;
        border-top: 1px solid $border_1;
        border-radius: 5px;
      }
    }
    &.popup_1120{
      max-width: 1120px;
      background: $white;
      border-radius: 10px;
      height: 90%;
      overflow-x: auto;
      &.addgame{
        select{
          background-color: $lightgray4;
          option{
            background-color: $white;
          }
        }
        .bg-title{
          font-size: 20px;
          font-weight: 700;
          line-height: 30px;
          color: $fontText2;
          background-color: $lightgray4;
          padding: 10px;
          margin-bottom: 25px;
          border-top: 1px solid $lightgray5;
          border-bottom: 1px solid $lightgray5;
        }
        label i{
          color: #8392A5;
          font-size: 10px;
          line-height: 14px;
          vertical-align: text-bottom;
          cursor: pointer;
          &.active{
            color: $green;
          }
        }
      }
      
      .create_event_row {
        flex-wrap: wrap;
        .filter_colmn{
          width: 214px;
          @include mediaq(575px){
            width: 100%;
          }
        }
      }
      button{
        width: auto;
      }
    }
    &.copylink_popup{
      .inner_popup{
        padding: 20px 15px;
      }
      h1{
        margin-bottom: 20px;
      }
      .copy_btn{
        background: #96CF04;
        border: none;
        border-radius: 0 5px 5px 0;
        width: 45px;
        height: 45px;
        color: #fff;
        font-size: 18px;
        
      }
      .form-group{
        display: flex;
        align-items: center;
        .form-control{
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: calc(100% - 55px);
          height: 45px;
          color: #00000091;
          border-radius: 5px 0 0 5px;
        }
      }
    }
    &.fields-add-popup{
      .create_event_row{
        .fields-input-custome{
          > div{
            border: 1px solid #e4e7ea;
            border-radius: 0.25rem;
            &::before{
              background:#5c6873 ;
            }
            &::after{
              background:#5c6873 ;
            }
            &:focus{
              color: #5c6873;
              background-color: #fff;
              border-color: #8ad4ee;
              outline: 0;
            }
          }
          .css-1jthlm-control{
            &:focus{
              color: #5c6873;
              background: #fff;
              border: 1px solid #8ad4ee;
              outline: 0;
            }
          }
          .css-vaumpn-singleValue{
            font-size: 0.875rem;
            font-weight: 400;
            line-height: 1.5;
            color: #5c6873;
          }
        }
      }
      &:focus{
        color: #5c6873;
        background-color: #fff;
        border-color: #8ad4ee;
        outline: 0;
      }
      .btn_bg-transparent{
        background-color: transparent;
        color: $graydark;
        border: 1px solid $graydark;
        min-width: unset;
        padding: 0px 6px;
        position: absolute;
        right: 16px;
        top: 0;
      }
    }
    &.notification-popup {
      .close_popup {
        right: 0px;
        top: 1px;
      }
    }
    &.venue_score{
      .user_popup_content{
        button{
          text-align: center;
          display: inline-block;
          font-weight: 400;
          color: #23282c;
          -webkit-user-select: none;
          user-select: none;
          background-color: transparent;
          border: 1px solid transparent;
          padding: 0.375rem 0.75rem;
          font-size: 0.875rem;
          line-height: 1.5;
          width: unset;
          &.btn-primary {
            color: #fff;
            background-color: #20a8d8;
            border-color: #20a8d8;
            &:hover {
              color: #fff;
              background-color: #1b8eb7;
              border-color: #1985ac;
            }
          }
          &.btn-danger {
            color: #fff;
            background-color: #f86c6b;
            border-color: #f86c6b;
            &:hover {
              color: #fff;
              background-color: #f64846;
              border-color: #f63c3a;
            }
          }
        }
      }
    }
    &.library_popup{
      max-width: 1230px;
      .bracket_library_top{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 20px;
        @include mediaq(767px) {
          padding-right: 10px;
        }
        input{
          max-width: 180px;
          @include mediaq(767px) {
            width: 130px;
          }          
        }
        button{
          outline: none;
        }
        .create_event_row{
          margin-bottom: 10px;
          padding: 0 10px;
        }
        .bracket_search{
          padding-bottom: 0;          
          @include mediaq(767px) {
            display: flex;
            width: 100%;
            margin: 0 0 10px;
            justify-content: center;
          }  
          button{
            margin-left: 10px;
            @include mediaq(767px) {
              font-size: 14px;
            } 
          }
        }
        .bracket_name{
          padding-bottom: 0;      
          @include mediaq(767px) {
            display: flex;
            width: 100%;
            margin: 0;
            justify-content: center;
          }      
          button{
            margin-left: 10px;
            @include mediaq(767px) {
              font-size: 14px;
            }
          }
          & + .bracket_name{
            order: 5;
            button{
              margin-left: 0;
              @include mediaq(767px) {
                margin-top: 10px;
              }
            }
          }
        }
        .dc_comm_bracket_btn{
          padding-bottom: 0;
          @include mediaq(767px) {
            margin: 10px 0 0;
            text-align: center;
            width: 100%;
          }    
          .active{
            background-color: $green !important;
            border-color: $green !important;
          }
        }   
        .bracket_row_wrap {
          width: auto;
          display: flex;
          flex-wrap: wrap;
          @include mediaq(767px) {
            display: block;
            width: 100%;
          }
        }     
      }
      .library_name{
        font-family: $Hvbold;
        font-size: 20px !important;
        margin-bottom: 10px;
        @include mediaq(767px) {
          text-align: center;
          width: 100%;
        }
      }
      .library_table{
        max-height: 290px;
        overflow: auto;
        table{
          tr{
            th{
              min-width: 100px;
              padding: 10px;
              &:nth-child(2){
                min-width: 250px;
              } 
              &:nth-child(6){
                min-width: 220px;
              }
              &:nth-child(7){
                min-width: 200px;
              }
              
            }
          }
          td{
            padding: 10px;
            img{
              width: 20px;
              height: 20px;
            }
          }
          .btn{
            padding: 4px 9px;
          }
          .btn-success {
            color: $white;
            background-color: $green;
            border-color: $green;

          }
          .btn-danger {
            color: $white;
            background-color: #f86c6b;
            border-color: #f86c6b;
          }
          .btn-primary {
            color: $white;
            background-color: #20a8d8;
            border-color: #20a8d8;
          }
          &:disabled{
            pointer-events: none;
          }
        }
      }
     
    }
    &.brackt_library_dec{
      .close_popup{
        right: 10px;
        top: 9px;
      }
      .library_dec{
        font-size: 14px;
        font-family: "Helvetica Medium";
        color: #8392A5;
        margin-bottom: 0;
      }
    }
    &.library_preview_popup{
      max-width: 1230px;
      .preview_loading{
        margin: auto;
      }
    }
    &.add_library_popup{
      max-width: 1300px;
      overflow-x: auto;
      max-height: 800px;

      // .inner_popup{
      //   padding: 30px 12px;
      //   .close_popup{
      //     top: 5px;
      //     right: 12px;
      //   }
      // }
      .add_library_form{
        display: flex;
        @include mediaq(868px) {
          display: block;
        }
        .create_event_row{
          padding-bottom: 25px;
        }
        label{
          display: block;
        }
        .custom_checkbox{
          width: 18px;
          height: 18px;
        }
        .error-text{
          color: red;
          font-size: 13px;
          padding-left: 15px;
          display: block;
        }
        .library_form{
          width: 50%;
          margin-right: 15px;
          @include mediaq(868px) {
            width: 100%;
            margin-right: 0;
          }
        }
        .library_previews{
          width: 50%;
          margin-left: 15px;
          @include mediaq(868px) {
            width: 100%;
            margin-left: 0;
          }
          .close_popup{
            top: -15px;
            right: -9px;
            background: #9e9e9e;
            border-radius: 100%;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
          }
        }
        .bracket-tab-btn{
          i{
            color: $white;
            margin-left: 5px;
          }
        }
        .preview_show{
          position: relative;
          img{
            width: 100%;
            height: 236px;
            border-radius: 10px;
            border: 1px solid #e3e3e3;
            padding: 10px;
          }
        }
      }
    }
  }
}

.bracket_close_popup{
  position: fixed;
  right: 0;
  top: 35px;
  z-index: 1;
  left: 0;
  max-width: 1250px;
  width: 100%;
  margin: 0 auto;
  text-align: end;
  padding: 0 25px;
  >i{
    color: #fff;
    cursor: pointer;
  }
}


@include mediaq(480px) {
  .fan_inner_club_popup {
    .collaps_content {
      padding: 20px;
      &::before {
        content: none;
      }
    }
    .user_info_data {
      width: 100% !important;
      padding-left: 0 !important;
      p {
        padding: 0 7px;
      }
    }
  }
  .player_game_stat {
    overflow: auto;
  }
}
/*     End  Custom Popup        */

/*         Edit App Page Css        */

.edit_apps {
  .btn_bg2 {
    border: none;
    border-radius: 5px;
    padding: 7px 15px;
    color: $white;
  }
}

.onboarding_table {
  table {
    tr {
      th {
        padding: 0.75rem;
      }
      td {
        padding: 0.75rem;
        background: $white;
        img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
        }
      }
    }
    .bording_body_table {
      tr {
        border-bottom: 1px solid $border_1;
        &:hover {
          td {
            background-color: rgba(0, 0, 0, 0.075);
          }
        }
      }
    }
  }
}

.inner_Boading_step {
  padding: 25px 0;
  .thumb_wrapper {
    img {
      width: 100%;
      min-height: 150px;
      max-height: 150px;
      object-fit: cover;
    }
    h3 {
      margin-bottom: 0;
    }
  }
  .image_edit_list {
    margin-bottom: 10px;
  }
  .remove_card {
    position: absolute;
    top: -10px;
    right: -10px;
    padding: 10px;
    border-radius: 50%;
    background: $error;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    cursor: pointer;
  }
}

.custom-file-upload {
  position: relative;
  border: 1px solid #ddd;
  border-radius: 5px;
  height: 40px;
  #uploadFile {
    width: 100%;
    border: none;
    height: 100%;
  }
  .fileUpload {
    position: absolute;
    top: 50%;
    left: 0;
    height: 100%;
    transform: translateY(-50%);
    width: 100%;
  }
  #uploadBtn {
    height: 100%;
    width: 100%;
    padding: 6px;
  }
}

// colleges page
.college_Tab {
  &.applicant-table{
    table{
      th {
        min-width: 115px;
      }
      td {
        min-width: 250px;
      }
      .req_pay{
        padding-left: 5px;
      }
    }
  }
  table {
    th {
      background: $light_bg;
      color: $graydark;
    }
    td {
      vertical-align: middle;
      font-size: 14px;
      line-height: 20px;
      font-family: $Hvmed;
      color: $fontText2;
      img {
        object-fit: cover;
      }
    }
  }
  .applicant-table{
    .table-responsive-xl{
      display: block;
      width: 100%;
      overflow-x: auto;
    }
    .text-align-right{
      text-align: right;
      margin-bottom: 5px;
    }
    .add_btn{
      background: #02a8a8;
      color: #fff;
      font-size: 14px;
      font-weight: 400;
      margin-right: 5px;
      cursor: pointer;
      white-space: normal;
      position: relative;
    }
    .field_btn{
      background: #96CF04 !important;
      padding: 6px 16px;
    }
    table th {
      background: #f7f8fb;
      border: none;
      padding: 0.75rem;
    }
    table td {
      border: none;
      padding: 0.75rem;
    }
    table {
      tr{
        &:hover{
          background-color: #ececec;
        }
      }
    }
    // .ant-empty-image{
    //   display: none !important;
    // }
  }
  .ant-table-pagination{
    .ant-pagination{
      margin: 16px 20px;
    }
  }

}

span.noFound {
  color: black;
  width: 100%;
  text-align: center;
  font-weight: 500;
  opacity: 0.7;
}

.rdw-editor-wrapper {
  background-color: #fff;
  padding: 15px;
  .rdw-editor-main {
    border-radius: 2px;
    border: 1px solid #f1f1f1;
    padding: 10px 10px;
    height: 200px;
    // height: 75%;
  }
}
.pageContantEditor {
  height: 75% !important;
}

.event_tags {
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  .event_text{
    background: #96cf05;
    padding: 2px 8px;
    color: #fff;
    font-size: 11px;
    text-align: center;
    margin: 2px 0;
  }
  .event_status_tag{
    .event_text{
      margin-left: auto;
    }
  }
  .reg_close_in{
    font-size: 13px;
    font-weight: 800;
    padding-right: 5px;
  }
  .event_status_tag{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .bg_grey{
    background: #e4e7ea;
    color: #000;
  }
  // .team_type{
  //   position: absolute;
  //   left: 0;
  //   top: 2px;
  //   left: 10px;
    
  // }
}

.game_info_data {
  padding-left: 15px !important;
  margin: 8px 0;
  table {
    width: auto !important;
    th,
    td {
      border: 2px solid #cdcdcd;
      padding: 2px 5px;
      text-align: center;
    }
    td {
      max-width: 62px;
      min-width: 58px;
    }
  }
}

.game_info_data1 {
  padding-left: 15px !important;
  margin: 8px 0;
  table {
    width: 100% !important;
    height: 80px;
    th,
    td {
      border: 2px solid #cdcdcd;
      padding: 2px 5px;
      text-align: center;
    }
    td {
      max-width: 62px;
      min-width: 58px;
    }
  }
}
.roster_info {
  table {
    th,
    td {
      border: 2px solid #cdcdcd;
      padding: 2px 5px;
      text-align: center;
    }
  }
}

.leagues-table {
  .dataTable {
    // width: max-content;
  }
}

.pass {
  padding: 8px;
  height: auto;
  border: 1px solid #e4e7ea;
  border-radius: 4px;
  width: 100%;
}

// Product master table

.table_productmaster {
  table th {
    &:nth-child(1) {
      width: 5%;
      @include mediaq(1370px) {
        width: auto;
      }
    }
    &:nth-child(2) {
      width: 80%;
      @include mediaq(1200px) {
        width: auto;
      }
    }
  }
}

// add product

.add_product {
  .attach_link {
    position: absolute;
    right: 1px;
    top: 44%;
    pointer-events: none;
    background: #fff;
    padding: 11px;
  }
  .select-wrap {
    border: 1px solid $border_1;
    border-radius: 4px;
    height: 220px;
    overflow-y: scroll;
    ul {
      margin-bottom: 0;
      position: relative;
      li {
        &.active {
          background: rgba(77, 138, 174, 0.6);
          a {
            color: $white;
          }
        }
      }
      a {
        font-size: 16px;
        color: $fontText3;
        font-family: $HvRegular;
        padding: 10px;
        display: block;
        &:hover {
          text-decoration: none;
        }
      }
    }
    .search-box{
      position: sticky;
    top: 0;
    padding: 10px;
    background-color: $white;
    z-index: 1;
    input{
      height: 38px;
    }
    }
    .video_upload_progressbar {
      position: absolute;
      height: 278px;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.55);
      color: #4D8AAE;
      text-shadow: 1px 1px #fff;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      z-index: 1072;
      font-size: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
  }
  }
  .btngroup {
    margin: 40px;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
  .mb-15 {
    margin-bottom: 15px;
  }
  .w-25 {
    width: 25%;
    @include mediaq(575px) {
      width: 100% !important;
    }
  }
  .w-35 {
    width: 35%;
    @include mediaq(575px) {
      width: 100% !important;
    }
  }
  .no-deta{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 130px;
  }
}

// custom radio button with background

.multi-radio {
  display: flex;
  align-items: center;
  height: 45px;
  border-radius: 4px;
  margin-bottom: 15px;
  background-color: $inpbg;
  .check_inputradio {
    padding: 12px 15px;
    height: 100%;
    display: inline-block;
  }
  .round-radio {
    display: inline-block;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      background-color: $linkcolor;
      border-radius: 50%;
      &:after {
        content: "";
        position: absolute;
        top: 5px;
        left: 5px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
      }
    }
    input:checked ~ .checkmark {
      background-color: $linkcolor;
    }
    input:checked ~ .checkmark:after {
      background: $white;
    }
  }
}

.text-blue {
  color: $linkcolor !important;
}
.text-danger {
  color: $remove;
}

// uploadcsv page
.datearrow {
  display: inline-block;
  font-size: 18px;
  color: black;
  font-weight: 600;
  padding: 0 5px;
  vertical-align: middle;
  &:hover {
    color: $linkcolor !important;
  }
}
.white-bg {
  background-color: $white;
  box-shadow: 0px 10px 5px -7px rgba(0, 0, 0, 0.09);
  border-radius: 5px;
}
.uploadcsv {
  padding-top: 20px;
  .upload-data-devices{
    .table-responsive-xl{
      max-height: 610px;  
      overflow: auto;
      .table{
        position: relative;
        border-collapse: collapse; 
        td{
          border-top: 0;
        }
        thead{
            position: sticky;
            top: -1px;
            z-index: 1;
            th{
              padding: 0;
              border-right: 0;
              border-top: 0.2px solid #E5E9F2;
              p{
                padding: 18px 24px;
                border: 1px solid #E5E9F2;
                border-bottom: 0.2px solid #E5E9F2;
              }
              &:first-child{
                p{
                  border-left: 0;
                }
              }
              &:last-child{
                p{
                  border-right: 0;
                }
              }
            }
          
        }
        tbody{
          tr{
            border-bottom: 1px solid #E5E9F2;
          }
        }
       .upload-data-common-btn {
          width: 100%;
          align-items: center;
          justify-content: center;
          justify-content: left;
       }
      }
    }
  }
  .container {
    max-width: 900px;
  }
  ::placeholder {
    color: $fontText3;
  }
  @media (max-width: 1199.98px) {
    .table-responsive-xl {
      margin-bottom: 15px;
    }
  }
  .white-bg {
    padding: 25px;
    &.tablecontent {
      padding: 0px;
      &.linkTableTwo th {
        width: 50%;
        border-bottom: 1px solid #e5e9f2;
      }
      &.linkTableThree th {
        width: 33%;
        border-bottom: 1px solid #e5e9f2;
      }
      &.linkTableTwo td {
        border-bottom: 1px solid #e5e9f2;
      }
      &.linkTableThree td {
        border-bottom: 1px solid #e5e9f2;
      }
    }
    .w-50 {
      width: 50%;
      padding-right: 15px;
      @include mediaq(575px) {
        width: 100%;
        padding: 0;
      }
    }
    .pr-0 {
      padding-right: 0px;
    }
    .info-wrap {
      border-top: 1px solid $inpbg;
      border-bottom: 1px solid $inpbg;
      padding: 10px 0;
      margin: 20px 0px;
      @include mediaq(767px) {
        padding: 10px 0 20px;
      }
    }
    h3 {
      margin-bottom: 3px;
    }
    label {
      font-weight: 600;
    }
    .flex-align .btn {
      margin: 0 10px;
      width: 150px;
      padding: 10px 36px;
    }
  }
  table {
    width: 100%;
    th {
      padding: 18px 24px;
      text-align: left;
      background-color: $light_bg;
      border-right: 1px solid $inpbg;
      vertical-align: middle;
      white-space: nowrap;
      border-bottom: 0px;
      &:last-child {
        border-right: 0px;
      }
    }
    td {
      padding: 6px 12px;
      border-right: 1px solid $inpbg;
      vertical-align: middle;
      white-space: nowrap;
      &:last-child {
        border-right: 0px;
      }
    }
  }
  .selectfile {
    display: flex;
    align-items: center;
    border: 1px solid $inpbg;
    border-radius: 4px;
    padding: 6px;
    p {
      margin-bottom: 0px;
      font-size: 12px;
      line-height: 1.2;
      word-break: break-all;
    }
    .file_custom_upload {
      padding: 6px 40px;
      height: 30px;
      margin-right: 14px;
      background-color: $inpbg;
      border: 2px dashed $darkgray;
    }
  }
  &.uploadcsv-uploadcsvdata {
    @media (min-width: 1200px) {
      .container {
        max-width: 100%;
      }
    }
    .tableinfo {
      flex-wrap: wrap;
      .col-lg-12 {
        padding: 0;
      }
    }
  }
}
.baseballlogo {
  max-width: 70%;
  margin: 0 auto;
}

//  Uploadcsv Link data css
.uploadcsv-linkdata {
  .table {
    td {
      // min-width: 400px;
      @include mediaq(991px) {
        min-width: 100%;
        width: 100%;
      }
      @include mediaq(767px) {
        min-width: 240px;
        width: 100%;
      }
    }
    tr {
      &:last-child {
        td {
          &:last-child {
            .css-2b097c-container {
              .css-26l3qy-menu {
                // top: auto;
                bottom: 100%;
              }
            }
          }
        }
      }
    }
    tr {
      &:nth-last-child(-n + 2) {
        td {
          &:nth-last-child(-n + 2) {
            .css-2b097c-container {
              .css-26l3qy-menu {
                // top: auto;
                bottom: 100%;
              }
            }
          }
        }
      }
    }
  }
  .css-2b097c-container {
    width: 400px;
    @include mediaq(991px) {
      width: 330px;
    }
    @include mediaq(767px) {
      width: 250px;
    }
    .css-yk16xz-control,
    .css-1pahdxg-control {
      width: 100%;
      box-shadow: none;
      border: 1px solid $darkgray;
      .css-g1d714-ValueContainer {
        width: 100%;
        .css-b8ldur-Input {
          width: 100%;
          > div {
            width: 100%;
            input {
              width: 100%;
            }
          }
        }
      }
      &:hover {
        border-color: $font-black !important;
      }
    }
    .css-4ljt47-MenuList {
      background-color: $white;
    }
  }
}

.video_upload_progressbar {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.55);
  color: $blue_btn;
  text-shadow: 1px 1px $white;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1072;
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}

// custom radio form button

.form-multiradio {
  display: flex;
  align-items: center;
  height: 45px;
  border-radius: 4px;
  .check_inputradio {
    height: 100%;
    display: inline-block;
    margin-right: 15px;
  }
  .round-radio {
    display: inline-block;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      background-color: $linkcolor;
      border-radius: 50%;
      &:after {
        content: "";
        position: absolute;
        top: 5px;
        left: 5px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
      }
    }
    input:checked ~ .checkmark {
      background-color: $linkcolor;
    }
    input:checked ~ .checkmark:after {
      background: $white;
    }
  }
}

// payment form
.payment-form {
  .input-group {
    flex-wrap: nowrap;
  }
  .scrollbar_team {
    max-height: 290px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow-y: scroll;
    margin: 15px 10px;
    @include mediaq(480px) {
      max-height: 250px;
    }
    .select_team {
      min-height: 100%;
      width: 65%;
      margin-left: auto;
      margin-right: auto;
      @include mediaq(768px) {
        width: 98%;
      }
      .team-flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 15px;
        &:last-child {
          margin-bottom: 0;
        }
        .team-data {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .team-img {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 60px;
            height: 60px;
            margin-right: 20px;
            border-radius: 100%;
            @include mediaq(480px) {
              width: 50px;
              height: 50px;
              margin-right: 5px;
            }
            > img {
              border-radius: 100%;
              border: 1px solid #e1e2e7;
              height: 60px;
              max-width: 60px;
              object-fit: cover;
            }
          }

          .team-info {
            h4 {
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 1.4;
              color: #28263d;
              margin-bottom: 4px;
            }
            h6 {
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              color: #28263d;
              margin-bottom: 4px;
            }
           p {
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              color: #63636d;
              margin-bottom: 4px;
            }
          }
        }
        .radiobox {
          position: relative;
          display: flex;
          input {
            position: absolute;
            opacity: 0;
            left: 0;
            right: 0;
            top: 0;
            height: 100%;
            width: 100%;
            z-index: 1;
            cursor: pointer;
          }
          span {
            position: relative;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            transition: all 0.5s ease 0s;
            background-color: #e5e9f2;
            @include mediaq(480px) {
              width: 30px;
              height: 30px;
            }
            &:after {
              content: "";
              position: absolute;
              width: 20px;
              height: 10px;
              border: 3px solid #ffffff;
              border-top: 0;
              border-right: 0;
              transform: rotate(-40deg) scale(0.6);
              top: 31%;
              left: 11px;
              transition: all 0.5s ease 0s;
              opacity: 0;
              @include mediaq(480px) {
                width: 16px;
                height: 8px;
                left: 7px;
              }
            }
          }
          input:checked ~ span {
            background: #1b8eb7;
            &:after {
              transform: rotate(-40deg) scale(1);
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

// user permission table
.user-permission {
  .table {
    tr {
      td:first-child {
        width: 80%;
      }
    }
  }
}
// exrtranal admin
.extrnal-admin {
  .btn-info {
    color: #fff;
  }
}

// add user form
.add-userform {
  .form-multiradio {
    flex-direction: column;
    align-items: flex-start;
  }
  .social_btn {
    border: none;
    padding: 0;
    text-align: center;
    position: relative;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.2;
    color: #ffffff;
    display: inline-block;
    vertical-align: middle;
    border-radius: 5px;
    box-shadow: 0px 0 24px -6px rgba(0, 0, 0, 0.5);
    height: 60px;
    text-transform: capitalize;
    line-height: 60px;
    transition: all 0.5s ease 0s;
    width: 100%;
    text-decoration: none;
    @include mediaq(767px) {
      margin-bottom: 10px;
      height: 50px;
      line-height: 50px;
      font-size: 15px;
    }
    i {
      font-size: 26px;
      position: absolute;
      left: 15px;
      height: 100%;
      line-height: 60px;
      @include mediaq(767px) {
        height: 50px;
        font-size: 22px;
        line-height: 50px;
      }
    }
    &.instagram {
      background: linear-gradient(to right, #fab749, #f56129, #dd2a7b, #4134af);
      color: #fff;
      text-decoration: none;
    }
    &.facebook{
      background: #4267b2;
      color: #fff;
      text-decoration: none;
    }
    &.twitter {
      background: #00a7e7;
      color: #fff;
      text-decoration: none;
    }
  }
}
// square checkbox custom
.add-userform {
  .check_inputradio {
    .round-radio {
      font-family: $HvRegular;
    }
  }
  .custom-checkbox {
    label {
      display: block;
      position: relative;
      padding-left: 30px;
      margin-bottom: 0px;
      cursor: pointer;
      font-size: 15px;
      font-family: $HvRegular;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    label input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    .checkmark {
      position: absolute;
      top: 0px;
      left: 0;
      height: 20px;
      width: 20px;
      background-color: $linkcolor;
      border-radius: 4px;
    }
    label:hover input ~ .checkmark {
      background-color: $linkcolor;
    }
    label input:checked ~ .checkmark {
      background-color: $linkcolor;
      border-radius: 4px;
    }
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }
    label input:checked ~ .checkmark:after {
      display: block;
    }
    label .checkmark:after {
      left: 7px;
      top: 3px;
      width: 6px;
      height: 12px;
      border: solid $border_light;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }
  }
  p {
    font-family: $Hvbold;
  }
  .serch-btn {
    position: absolute;
    height: 45px;
    width: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #4d8aae;
    color: #fff;
    top: 30px;
    right: 0;
    border-radius: 0 4px 4px 0;
  }
}

// role popup
.role-popup {
  .inner_popup {
    h1 {
      margin-bottom: 25px !important;
    }
    li {
      font-family: $HvRegular;
      list-style: disc;
      list-style-position: inside;
    }
  }
}

// select img react select
.select-img {
  display: flex;
  align-items: center;
  img {
    margin-right: 10px;
  }
}

//*------anaylzr css

// View Questionnaire

.view-questionnaire {
  .qu-title {
    background-color: $green;
    text-align: center;
    margin-bottom: 0;
    padding: 20px;
    margin-bottom: 15px;
  }
  .qa-box {
    padding: 13px 0 8px;
    h2 {
    }
    p {
      font-size: 16px;
      font-weight: 400;
    }
  }
}

// an user

.an-user {
  .search-bar {
    display: flex;
    align-items: flex-end;
  }
  .ant-table-thead {
    tr {
      background: #f7f8fb;
      color: #8392a5;
    }
  }
  .ant-table-tbody {
    background-color: #fff;
    td {
      font-size: 14px;
      color: #28263d;
      font-weight: 500;
    }
  }
  .ant-table-column-sorter-inner {
    svg {
      width: 12px;
      vertical-align: baseline;
    }
  }
  .ant-empty-description {
    text-align: center;
  }
}

.upload-report {
  .file_custom_upload {
    height: auto;
    padding: 15px;
    margin-bottom: 10px;
  }
}

// custom_dropdown
.custom_dropdown {
  .dropdown-toggle {
    color: #23282c;
    background-color: transparent !important;
    border-color: $border_1;
  }
  .btn {
    background-color: transparent;
    width: 100%;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:active {
      background-color: transparent;
    }
    &:focus {
      background-color: transparent;
      box-shadow: none !important;
    }
  }
  .btn-secondary:not(:disabled):not(.disabled):active,
  .btn-secondary:not(:disabled):not(.disabled).active {
    color: #23282c;
    background-color: transparent;
    border-color: #a5aeb7;
  }
  .dropdown-menu {
    width: 100%;
    overflow: auto;
    .checkbox {
      display: grid;
      padding: 6px 6px 6px 30px;
      label {
        font-family: $HvRegular;
        font-weight: normal;
        padding-bottom: 8px;
      }
    }
    .select-text{
      span{
        cursor: pointer;
      }
    }
  }
}
.custome-height{
  .dropdown-menu {
    width: 100%;
    height: 250px;
  }
}

// analyzr package date table

.analyzrPackage{
  table{
    ul{
      margin-bottom: 0;
      li{
        list-style: disc !important;
        margin-left: 14px;
      }
    }
    ol{
      margin-bottom: 0;
      li{
        list-style: decimal !important;
        margin-left: 14px;
      }
    }
  }
}
.check_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  &.add_filed {
    flex-direction: column;
    align-items: inherit;
    .form-group {
      margin: 15px 0 0;
      // @include media575 {
      //     margin: 15px 0 20px;
      // }
      .form-control {
        padding-right: 65px;
      }
      .serch-btn {
        color: #fff;
        font-size: 18px;
        width: 65px;
      }
    }
  }
  .checkbox {
    width: 100%;
    cursor: pointer;
    position: relative;
    margin: 0 15px;
    margin-left: 0;
    label {
      width: 100%;
      border-radius: 4px;
      background-color: transparent;
      text-align: center;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      // @include defaultFontStyle(500, 16, 1.4, #02A8A8);
      border: 1px solid #eee;
      // @include media480 {
      //     @include defaultFontStyle(500, 14, 1.4, #02A8A8);
      // }
      // @include media480 {
      //     height: 42px;
      // }
    }
    input[type="radio"],
    input[type="checkbox"] {
      opacity: 0;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      width: 100%;
      height: 100%;
      // z-index: 1;
      cursor: pointer;

      &:checked ~ label {
        background-color: #02a8a8;
        color: #fff;
      }
    }
  }
}

.permission_popup {
  .popup_710 {
    width: 500px;
    // height: 50%;
    ul {
      overflow: auto;
      max-height: 310px;
    }
  }
}

.recap-form {
  .match-no {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  .form-multiradio {
    align-items: center;
    .check_inputradio {
      margin-right: 0px;
    }
  }
  .btn_bg {
    min-width: auto;
  }
  .create_event_row input[type="text"] {
    padding: 5px;
  }
  button {
    padding: 8px !important;
    width: 96px;
    font-size: 15px;
  }
}
.create_event_row{
  .no_text{
    font-family: $HvRegular;
    color: #8392A5;
    font-size: 15px;
    padding-right: 5px;
  }
  &.create_installment{
    background: #e4e7ea47;
    margin: 20px 0;
    padding: 15px 15px 20px;
    border: 1px solid #e4e7ea;
    .button_type{
      background: $linkcolor;
      color: $white;
    }
  }
  .button_type{
    &:first-child{
      margin-right: 10px;
    }
  }
  .create_event_prizeRow{
    input[type="text"] {
      width: inherit;
    }
  }
}

// ROSTER LIST
.rosterList{
  max-height: 290px;
  overflow: auto;
  table{
    tr{
      th{
        min-width: 100px;
        padding: 10px;
      }
    }
    td{
      padding: 10px;
    }
  }
  
}
.uploadtab_view{
  border: 2px dashed #e1e2e7;
  border-radius: 5px;
  margin: 15px 0;
  .gray_btn{
    width: 100%;
    box-shadow: none;
    background-color: #e5e9f2;
    color: #28263d;
    border: 2px solid #e5e9f2;
    display: inline-block;
    vertical-align: middle;
    padding: 13px 36px;
    border-radius: 5px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    transition: all .5s ease 0s;
  }
}
.roster-tab{
  .uploadtab{
    display: flex;
    list-style: none;
    align-items: center;
    border: 1px solid #e1e2e7;
    border-radius: 5px;
    margin: 20px 0;
    li{
      width: 100%;
      text-align: center;
      padding: 15px 0;
      cursor: pointer;
      background-color: transparent;
      transition: all .5s ease 0s;
      list-style-type: none;
      &:first-child{
        border-radius: 5px 0 0 5px;
      }
      &:nth-child(2){
        border-radius: 0 5px 5px 0;
      }
      &.active{
        background-color: #4d8aae;
        a{
          color: #fff;
        }
      }
      a{
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 1.2;
        color: #28263d;
        text-decoration: none;
      }
    }
  }
  .file_custom_upload {
    position: relative;
    border: 2px dashed #e1e2e7;
    padding: 45px 10px;
    overflow: hidden;
    background-color: #f9f6f7;
    border-radius: 5px;
    height: 170px;
    margin: 20px 0;
    > i {
        font-size: 35px;
        color: #02a8a8;
        opacity: 0.6;
        margin-bottom: 10px;
    }
    p {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 1.2;
      color: #6c7b95;
      margin-bottom: 0;
      margin-bottom: 0;
    }
    input[type="file"] {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
    }

    .preview_img {
        width: 200px;
        height: 100%;
        border-radius: 5px;
        border: 2px dashed #e1e2e7;
        position: absolute;
        // background-color: $gray-bg;
        background-color: $white;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        left: 0;
        right: 0;
        margin: 0 auto;
        padding: 0 15px;
        .preview_img_inner {
            // height: 100%;
            // object-fit: cover;
            // padding: 10px;
            // border-radius: 100%;
            // max-width: 100%;
            object-fit: cover;
            padding: 10px;
            border-radius: 100%;
            max-width: 100%;
            width: 160px;
            height: 160px;
        }
        i {
            content: "";
            position: absolute;
            right: 10px;
            top: 10px;
            width: 22px;
            height: 22px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: red;
            text-align: center;
            border-radius: 50%;
            color: $white;
            cursor: pointer;
            font-size: 14px;
            // z-index: 1;
            &:hover {
                opacity: 0.8;
            }
        }
    }
  }
}
/* edit roster name */
.role-popup{
  .inner_popup{ 
    .player_name{
      margin-bottom: 0 !important;
    }
  }
} 

.edit_roster_name{
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 18px;
  left: 0;
  right: 0;
  @include mediaq(768px) {
    top: 12px;
  }

  @include mediaq(575px) {
    position: static;
    margin-top: 10px;
  }

  .roster_name{
    margin-right: 15px;
    h2{
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 1.2;
      color: #28263D;
      margin: 0;
    }
    .form-group{
      input{
        background: $white !important;
        height: 37px;
      }
    }
  }
  .roster_btn{
    .create_event_row{
      .button_type{
        padding: 5px 9px;
      }
    } 
  }
}

//Ability to remove icon that appears over link on hover in mention tag
.rdw-link-decorator-icon {
  display: none;
}

.rc-time-picker-panel{
  z-index: 9999 !important;
}


// tooltip for copy text

.tooltips {
  transform: translateX(-50%);
  width: 67px;
  visibility: hidden;
  background-color: #373739;
  color: #fff;
  font-size: 12px;
  font-family: Helvetica,Arial,sans-serif;
  cursor: default;
  text-shadow: none;
  text-align: center;
  overflow: visible;
  padding: 6px 5px;
  box-shadow: 1px 1px 2px rgba(0,0,0,.15);
  -webkit-transition: opacity .3s ease-in .3s;
  transition: opacity .3s ease-in .3s;  
  position: absolute;
  opacity: 0;
  right: -110px;
  top: 0;
  &.active{
    visibility: visible;
    opacity: 1;
    z-index: 999;
  }
  &:after {
    content: ' ';
    position: absolute;
    top: 35%;
    left: -1px;
    display: block;
    height: 0;
    width: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #373739;
    margin-left: -10px;
    transform: rotate(-90deg);
  }
}

.scout_popup{
  h1{
    margin-bottom: 22px !important;
  }
  // .user_popup_content{
  //   background: #80808014;
  //   padding-top:13px ;
  // }
  .user_popup_content{
    .team_add_popup{
      padding: 15px;
    }
  } 
  .scout_section{
    margin: 0 0 20px;
    .scout_logo{
      width: 150px;
      height: 150px;
      margin: auto;
      margin: 10px auto;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
      }
    }
    p{
      font-family: "Helvetica Medium";
      font-style: normal;
      font-size: 16px;
      line-height: 1.2;
      color: #23282c;
      margin: 0;
      text-align: center;
      padding: 4px 0;
      &.scout_name{
        font-size: 26px;
        font-family: "Helvetica Bold";
      }
      &.scout_address{
        font-size: 20px;
        line-height: 22px;
        color: #23282cbd;
        i{
          padding-right: 6px;
        }
      }
      &.scout_date{
        font-size: 18px;
        line-height: 22px;
        color: #23282cbd;
        i{
          padding-right: 6px;
        }
      }
    }
  }
}
/* add venues */
.add_venue_section{
  .create_event_row {
    padding-bottom: 25px;
  }
  .btn_transparent{
    background: $white !important;
    color:$linkcolor;
  }
}

.college_Tab.applicant-table table td:first-child {
  min-width: 100px;
}


/*  hotel details */
.hotel_detail_stayrow {
  .switch {
    width: 40px;
  }
}
.hotel_detail_stayrow .switch .switch-input:checked ~ .switch-slider::before {
  -webkit-transform: translateX(14px);
  transform: translateX(14px);
}
.insight_tab{
  button{
    border: none;
    background: #e4e7ea9c;
    padding: 7px;
    margin: 2px 5px;
    cursor: pointer;
    &:focus{
      outline: none;
    }
    &.active{
      font-weight: bold;
      color: #000000;
      fill: #000000;
      background: #e6ebf5;
    }
  }
}


.checkbox {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 50px;
  // padding: 10px 0;
  margin-bottom: 15px;
  &:last-child {
      margin-right: 0;
  }
  input {
      position: absolute;
      opacity: 0;
      left: 0;
      right: 0;
      top: 0;
      height: 100%;
      width: 100%;
      z-index: 1;
      cursor: pointer;
  }
  .square {
      position: relative;
      width: 20px;
      height: 20px;
      border-radius: 4px;
      transition: all 0.5s ease 0s;
      background-color: $white;
      border: 1px solid rgba(53, 53, 65, 0.5);
      margin-right: 12px;
  }
  .square:after {
      content: "";
      display: block;
      position: absolute;
      top: 4px;
    left: 6px;
    width: 5px;
    height: 8px;
      border: solid $white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
  }
  .circle {
      position: relative;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      transition: all 0.5s ease 0s;
      background-color: $white;
      border: 1px solid rgba(53, 53, 65, 0.5);
      margin-right: 12px;
  }
  .circle:after {
      content: "";
      position: absolute;
      left: 3px;
      top: 3px;
      width: 12px;
      height: 12px;
      border-radius: 100%;
      background: $green;
      opacity: 0;
  }
  // label {
  //     @include defaultFontStyle(400, 14, 16, $font-black);
  // }
  input:checked ~ .square::after,
  input:checked ~ .circle::after {
      opacity: 1;
  }
  input:checked ~ .circle {
      border: 1px solid $green;
  }
  input:checked ~ .square {
      background-color: $green;
      border: 1px solid $green;
  }
}
.event_rule{
  width: 100%;
    max-width: 900px;
    min-width: 900px;
   
  .create_event_row{
      
   
    .form-group{
      .rdw-editor-wrapper{
        padding: 0;
      }
    }
    .button_type{
      min-width: 135px;
      display: inline-block;
      padding: 12px;
      line-height: 21px;
      margin-right: 0;
      color: $white;
    }
    &.event_company{
      max-width: 900px;
    }

     &.sec_name_first {
       max-width: 450px;
     }
    
    &.sec_text{
      max-width: 900px;
    }
  }
  
  @include mediaq(1370px){
    min-width: unset;
  }
}
.sec_submit{
  position: relative;
  .col{
    width: 100%;
    max-width: 900px;
    padding-left: 0;
    padding-right: 0;
    .form-group{
      text-align: right;
    }
  }

  .submit{
    position: absolute;
    right: 0;
    top: 0;
    @include mediaq(1370px){
      position: static;
      margin-left: 10px;
      margin-bottom: 1rem;
    }
  }

  @include mediaq(1024px){
    max-width: 900px;
  }
}

.ant-table-content .ant-table table{
  white-space: initial !important;
}

.college_Tab{
  .ant-table-content {
    .ant-table {
      table{
        white-space: initial !important;
      }
    }
  }
}

.college_Tab.applicant-table table td{
  white-space: initial !important;
}

.uploadcsv-linkdata .table-responsive-xl.table-responsive {
  display: block;
  width: 100%;
  overflow-x: inherit;
  -webkit-overflow-scrolling: touch;
  @include mediaq(400px){
    overflow-x: auto;
  }
}

.uploadcsv-linkdata .css-2b097c-container {
  @include mediaq(575px){
    width: 200px;
  }
  @include mediaq(480px){
    width: 150px;
  }
 
}
