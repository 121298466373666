.companies-tournament {
    &.edit-recap{
        .d-flex{
            // border-bottom: 1px solid #F0F3F4;
            margin-bottom: auto;
        }
    }

    .rdw-editor-wrapper {
        padding: 0;
    }

    .award-list-title {
        h1 {
            color: $fontText2;
            font-weight: 700;
            padding: 20px 0;
            margin-bottom: 28px;
            // border-top: 1px solid $border_light;
            border-bottom: 1px solid $border_light;
        }
    }
    .award-group {
        label {
            font-size: 14px;
        }
        .check_inputradio {
            height: auto;
            input{
                height: auto;
                &:checked {
                    ~ {
                        .checkmark {
                            background-color: transparent;
                            &::after{
                                top: 4px;
                                left: 4px;
                                background-color: $green;
                            }
                        }
                    }
                }
            }
            .checkmark {
                background-color: transparent;
                border: 1px solid $green;
            }
        }
    }
    .rank-list-info {
        border: 1px solid #e5e9f2;
        box-sizing: border-box;
        border-radius: 4px;
        .rank-title {
            background-color: $green;
            padding: 10px 15px;
            font-size: 15px;
            font-weight: 700;
            color: $white;
            border-radius: 4px 4px 0px 0px;
        }
        .rank-list {
            padding: 0 10px;
            .table-responsive-xl {
                width: 100%;
                overflow-x: auto;
            }
            table {
                padding: 0 10px;
                white-space: nowrap;
                td {
                    font-size: 15px;
                    color: $fontText3;
                    font-weight: 400;
                    padding: 4px;
                }
            }
        }
    }
    .search-auto{
        .css-2b097c-container{
            margin-bottom: 8px;
        }
        .css-yk16xz-control{
            border: 1px solid #e4e7ea;
            
            height: 41px;
        }
        .css-1wa3eu0-placeholder {
            max-width: 90%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
    .rdw-editor-wrapper {
        h1 {
            font-size: 2em !important;
        }
        h2 {
            font-size: 1.5em !important;
        }
        h3 {
            font-size: 1.17em !important;
        }
        h4 {
            font-size: 1em !important;
        }
        h5 {
            font-size: 0.83em !important;
        }
        h6 {
            font-size: 0.67em !important;
        }
        h1, h2, h3, h4, h5, h6 { 
            font-weight: bolder !important;
        }
    }
}
