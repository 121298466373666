.addapplicantform{
    .nav-tabs {
        margin-bottom: 15px;
        .nav-item {
          width: 50%;
          .nav-link {
            border: 1px solid $border2;
            padding: 12px;
            text-align: center;
            font-size: 16px;
            
            &.active {
              border: none;
              background-color: $blue_btn !important;
              border: 1px solid $blue_btn !important;
              min-width: 130px;
              color: $white;
              border-radius: 4px;
              cursor: pointer;
            }
            &:hover{
              background-color: #eeeeee;
            }
          }
        }
      }
      .tab-content {
        border: none;
      }

      .image_prev_name {
        position: relative;
        border: 1px solid #e5e9f2;
        padding: 13px;
        border-radius: 0 0 4px 4px;
        .image_name {
          width: calc(100% - 70px);
          font-family: $HvRegular;
          font-size: 16px;
          color: $blue_btn;
          margin-bottom: 0;
        }
      }
      .round_close {
        width: 30px;
        height: 30px;
        background: #e8e9ee;
        display: inline-block;
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        cursor: pointer;
      }
      .bg_blue_btn {
        background: #4d8aae;
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        width: 70px;
        border: 1px solid #4d8aae;
        &:hover{
            background: #fff;
            color: $fontText2;
        }
    }
    .light_red_btn {
        background: #ffeaea;
        color: $remove;
        width: 70px;
        font-size: 14px;
        font-weight: 600;
        border: 1px solid #ffeaea;
        &:hover{
            border-color: $remove;
            color: $fontText2;
        }
    }
    .table th {
        padding: 8px;
        vertical-align: middle;
        font-size: 14px;
        font-weight: 600;
        color: #8392A5;
        background-color: #F7F8FB;
        
    }
    .table td {
        padding: 8px;
        vertical-align: middle;
        font-size: 14px;
        font-weight: 600;
        color: #28263D;
    }
    .add_roster{
      width: 100%;
      border-radius: 0 0 4px 4px;
    }
    .create_event_row input:disabled {
        background: #ffffff;
    }
    
    
}
.country_code {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 5px;
    padding: 5px 8px;
    font-size: 14px;
    line-height: 20px;
    border: none;
    font-family: "Helvetica Regular";
    color: #887b7f;
    outline: none;
}