.edit_apps {
    .sortable-list{
        background: $white;
        padding: 15px 0;
    }
    .nav-link{
        border: none;
    }
    .tab-pane{
    }
    .bg_row{
        th{
            background: $light_bg;
            color:$graydark;
            font-size: 14px;
            font-family: $Hvmed;            
        }
        tr.active{
            td{
                color: $linkcolor;
            }
        }
        td{
            font-size: 14px;
            line-height: 20px;
            font-family: $Hvmed; 
            color: $fontText2;
        }
    }
    &>.nav-tabs {
        border: none;
        .nav-link {
            font-size: 16px;
            font-family: $Hvmed;
            color: $dropdowntext;
            transition: 0.2s ease;
            line-height: 1;
            padding: 15px;
            border: transparent;
            &.active {
                background: none;
                border: none;
                font-size: 20px;
                font-family: $Hvbold;
                color: $fontText2;
            }
            &:hover,&:focus {
                border: transparent;
            }
        }
    }
    &>.tab-content {
       border: none;
       background: none;
        &>.tab-pane {
            .nav-tabs{
                box-shadow: $boxshadow;
            }
            .nav-link {
                font-size: 15px;
                line-height: 20px;
                padding: 15px;
                font-family: $Hvmed;
                position: relative;
                &.active {
                    color: $linkcolor;
                    border: none;
                    &:before {
                        position: absolute;
                        bottom: 0;
                        content: "";
                        height: 3.5px;
                        width: 70%;
                        background: $linkcolor;
                        left: 0;
                        right: 0;
                        margin: auto;
                    }
                }
            }
        }
        .tab-content{
            border: none;
        }
    }
}