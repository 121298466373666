// dash board tab
.customTab {
    .nav-link {
        border: none;
    }

    .tab-pane {
        padding: 0 15px;
    }

    table {
        th {
            background: $light_bg;
            color: $graydark;
            font-size: 14px;
            font-family: $Hvmed;
            font-weight: normal;
            vertical-align: middle;

            @include mediaq(1199px) {
                min-width: 215px;
            }
        }

        tr.active {
            td {
                color: $linkcolor;
            }
        }

        .coupon_tag {
            border-radius: 4px;
            background: $blue_btn;
            padding: 5px 10px;
            display: inline-block;
            color: $white;
            text-transform: uppercase;
            font-size: 12px;
            font-family: $Hvmed;
            margin-right: 10px;
            margin-bottom: 5px;
            min-width: 70px;

            &:last-child {
                margin-right: 0;
            }
        }

        td {
            font-size: 14px;
            line-height: 20px;
            font-family: $Hvmed;
            color: $fontText2;
            vertical-align: middle;

            .width-table {
                max-width: 300px;
            }

            img {
                width: 35px;
                height: 35px;
                object-fit: cover;
                border-radius: 50%;
                max-width: 100%;
                margin-right: 15px;
                display: inline-block;

                @include mediaq(480px) {
                    width: 30px;
                    height: 30px;
                }
            }
        }
    }

    &>.nav-tabs {
        border: none;

        .nav-link {
            font-size: 16px;
            font-family: $Hvmed;
            color: $dropdowntext;
            transition: 0.2s ease;
            line-height: 1;
            padding: 15px;
            border: transparent;

            &.active {
                background: none;
                border: none;
                font-size: 20px;
                font-family: $Hvbold;
                color: $fontText2;
            }

            &:hover,
            &:focus {
                border: transparent;
            }
        }
    }

    &>.tab-content {
        border: none;
        background: no-repeat;

        &>.tab-pane {
            padding: 0;
            background: $white;
            margin-bottom: 20px;

            .nav-tabs {
                box-shadow: $boxshadow;
                background: $white;
            }

            .nav-link {
                font-size: 15px;
                line-height: 20px;
                padding: 15px;
                font-family: $Hvmed;
                position: relative;

                &.active {
                    color: $linkcolor;
                    border: none;

                    &:before {
                        position: absolute;
                        bottom: 0;
                        content: "";
                        height: 3.5px;
                        width: 70%;
                        background: $linkcolor;
                        left: 0;
                        right: 0;
                        margin: auto;
                    }
                }
            }

            div[data-highcharts-chart] {
                width: 100%;

                >.highcharts-container {
                    width: 100% !important;
                }
            }
        }

        .tab-content {
            border: none;
        }
    }
}

.dataTables_paginate {
    .pagination {
        float: right;
        margin: 15px 19px;

        .page-item {
            .page-link {
                font-family: $Hvmed;
            }

            &.active {
                .page-link {
                    background-color: $blue_btn;
                    border-color: $blue_btn;
                }
            }
        }
    }
}

// user page
div[data-test="datatable-search"] {

    // position: absolute;
    // top: -44px;
    // z-index: 2;
    // right: 0;
    // @include mediaq(768px) {
    //     position: static;
    //     margin: 15px;
    // }
    .dataTables_filter {
        label {
            @include display_flex;
            @include align_center;
            font-size: 16px;
            font-family: $HvRegular;
            color: $fontText3;

            input {
                width: calc(100% - 100px);
                padding: 8px;
                height: auto;
                margin-left: 10px;
                font-size: 14px;
                font-family: $HvRegular;

                @include mediaq(768px) {
                    width: 100%;
                    max-width: 250px;
                }
            }
        }
    }
}

.userDataTable {
    table {
        img {
            width: 35px;
            height: 35px;
            object-fit: cover;
            border-radius: 50%;
            max-width: 100%;
            margin-right: 15px;
            display: inline-block;
        }

        .img_table_row {
            max-width: 160px;
        }
    }

    .tab-content {
        .tab-pane {
            .nav-link {
                &.active {
                    color: $green;

                    &:before {
                        background: $green;
                    }
                }
            }
        }
    }

    .bg_row {
        tr {
            &.active {
                td {
                    color: $blue_btn;
                }
            }
        }
    }

    .img_row_tbl {
        img {
            width: 35px;
            height: 35px;
            object-fit: cover;
            border-radius: 50%;
            max-width: 100%;
            margin-right: 15px;
        }

        p {
            max-width: 160px;
        }
    }
}

.css-plu760-control {
    border-color: #e4e7ea !important;

    svg {
        fill: #887b7f;
    }

}

.select_checkbox_cms {
    .filter_colmn {
        .custom_dropdown {
            .form-check-label {
                >input[type="checkbox"] {
                    position: static;
                    opacity: 1;
                    height: 12px;
                    width: 12px;
                }
            }
        }
    }
}
