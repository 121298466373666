// Comman css
.container_1120 {
  max-width: 1120px;
  margin: 0 auto;
  padding: 0 10px;
}

.scheduling-breadcrumb {
  ul {
    padding: 5px 20px;
    list-style: none;
    background-color: $white;
    border-radius: 5px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    li {
      &::after {
        padding: 8px 30px;
        color: #C1C1C1;
        content: "\f105";
        font-family: "FontAwesome";
        background: none;
        background-image: none;
        font-size: 20px;
        font-weight: 600;
      }

      &:first-child {
        display: flex;

        a {
          display: flex;
          align-items: center;
          cursor: pointer;
        }

        &::after {
          color: #73788B;
        }
      }

      &:last-child {
        &::after {
          display: none;
        }
      }

      a {
        font-size: 16px;
        line-height: 24px;
        font-family: $Hvmed;
        text-decoration: none;
        color: #C1C1C1 !important;
        display: inline-block;
        cursor: default;

        // font-weight: 600;
        img {
          width: 20px;
          height: 18px;
        }
      }

      &.finish {
        a {
          color: #73788B !important;
          cursor: pointer;
        }

        &::after {
          color: #73788B;
        }
      }
      &.active{
        a{
          color:$green !important;
          cursor: default;
        }

        &::after {
          color: $green;
        }
      }
    }

  }
}

// Dashboard css
.scheduling-dashboard-filter {
  input {
    height: auto !important;
    padding: 8px !important;
  }
}

.scheduling_card {
  background: $white;
  border: none;
  box-shadow: 0 2px 2px rgba(131, 146, 165, 0.1);
  overflow: hidden;

  .scheduling_img {
    position: relative;

    img {
      max-width: 100%;
      min-height: 200px;
      max-height: 200px;
      width: 100%;
      object-fit: cover;
      margin: auto;
    }

    .scheduling_address {
      display: block;
      font-size: 14px;
      line-height: 23px;
      font-family: $HvRegular;
      padding: 4px 10px 4px 40px;
      position: absolute;
      right: 0;
      bottom: 0;
      color: $white;
      background: rgba(0, 0, 0, 0.7);
      border-radius: 10px 0 0 0;

      &::before {
        content: "";
        background: url("/img/map_marker.svg") no-repeat;
        width: 12px;
        height: 16px;
        background-size: 100% 100%;
        left: 7px;
        z-index: auto;
        position: absolute;
        top: 7px;
      }
    }
  }

  .scheduling_desc {
    padding: 10px;

    .scheduling_info {
      display: flex;

      .scheduling_logo {
        img {
          margin-right: 12px;
          width: 54px;
          height: 54px;
          border-radius: 50%;
        }
      }

      .scheduling_titles {
        width: calc(100% - 90px);

        h3 {
          margin-bottom: 10px;
          font-family: "Helvetica ExtraBold";
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        p {
          display: flex;
          font-family: "Helvetica Medium";
          margin-bottom: 5px;
          line-height: 24px;

          img {
            margin-right: 10px;
          }
        }
      }
    }

    .status_info {
      cursor: pointer;
      position: relative;

      // span {
      //     &:hover {
      //         .status_table {
      //             display: block;
      //         }
      //     }
      // }
      p span:hover .status_table_wrp {
        display: block;
        color: khaki;
      }

      p {
        display: flex;
        font-family: "Helvetica Medium";
        margin-bottom: 5px;
        line-height: 24px;
        margin-left: 66px;

        img {
          margin-right: 10px;
        }
      }

      .status_table_wrp {
        display: none;
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;

        .angle {
          position: absolute;
          left: 35%;
          width: 0;
          height: 0;
          bottom: 21px;
          border-left: 20px solid transparent;
          border-right: 20px solid transparent;
          border-top: 20px solid $white;
          margin: auto;

          @include mediaq(991px) {
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 10px solid $white;
          }
        }
      }

      .status_table {
        position: absolute;
        bottom: 40px;
        width: 100%;
        left: 0;
        max-height: 205px;
        overflow: auto;
        box-shadow: 0 3px 20px 2px #c1c1c1;

        @include mediaq(991px) {
          bottom: 35px;
        }

        table {
          box-shadow: 0px 3px 20px 2px #c1c1c1;

          thead {
            background-color: $green;
            color: $white;

            th {
              padding: 8px;
              font-size: 14px;
              font-family: $Hvbold;
              border-right: 1px solid #ededed;

              &:first-child {
                border-radius: 4px 0 0 0;
              }

              &:last-child {
                border-radius: 0 4px 0 0;
                border-right: none;
              }

              @include mediaq(991px) {
                padding: 3px;
                font-size: 12px;
                line-height: 14px;
              }
            }
          }

          tbody {
            background-color: $white;

            td {
              padding: 8px;
              font-size: 14px;
              font-family: $Hvmed;
              color: $fontText3;
              border: 1px solid #ededed;
              @include mediaq(991px) {
                padding: 3px;
                font-size: 12px;
                line-height: 14px;
              }
            }
          }
        }

        .angle {
          position: absolute;
          left: 35%;
          width: 0;
          height: 0;
          border-left: 20px solid transparent;
          border-right: 20px solid transparent;
          border-top: 20px solid $white;
          margin: auto;

          @include mediaq(991px) {
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 10px solid $white;
          }
        }
      }
    }

    .scheduling_btn {
      position: relative;
      margin-left: 60px;

      span {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        text-align: center;
        cursor: pointer;
        color: $white;
        font-size: 14px;
        line-height: 29px;
        color: $white;
        transition: 0.5s;
        display: inline-block;
        margin: 5px;
      }

      .on_off_chk {
        margin: 0;
        width: 40px;

        label {
          width: auto;
          height: auto;
          margin: 0 !important;
          vertical-align: top;
          margin-right: 10px;
          margin-top: 5px !important;
        }

        .switch-slider {
          margin: 0;
          width: 40px;
          height: 25px;

          &::before {
            top: 2px;
            left: 2px;
            width: 18px;
            height: 18px;
          }
        }

        .switch-input:checked~.switch-slider::before {
          transform: translateX(15px);
        }
      }

      .event_report_box {
        background: $blue_btn;
        font-size: 12px;
      }

      .event_applicant_list_box {
        background: $blue_btn;
        font-size: 12px;
        position: relative;

      }

      .event_delete_box {
        background: $error;

        &:hover {
          opacity: 0.8;
        }
      }

      .event_recap_info {
        background: $dark_green;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}

.scheduling_card_col {
  &.col-xl-3 {
    @include mediaq(1366px) {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }

    @include mediaq(767px) {
      flex: 0 0 50%;
      max-width: 50%;
    }

    @include mediaq(575px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  &.scheduling-event {
    max-width: 400px;
    width: 100%;

    @include mediaq(575px) {
      max-width: 100%;
    }

    .scheduling_desc {
      padding: 30px 15px;

      .scheduling_info {
        align-items: center;

        .scheduling_titles {
          p {
            margin-left: 0;
          }
        }
      }

      .status_info {
        .status_table {
          height: 80px;

          @include mediaq(991px) {
            height: auto;
          }
        }
      }
    }
  }
}

// vanue
.vanue-list {
  padding-bottom: 25px;
}

.vanue-select {
  background-color: $white;
  border-radius: 8px;
  font-size: 18px;
  font-family: $Hvmed;
  color: $fontText3;
  text-align: center;
  padding: 15px;
  cursor: pointer;

  span {
    font-size: 18px;
    font-family: $HvRegular;
  }

  &.vanue-manage {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 230px;
      text-align: left;

    }


  }

  &.active {
    border: 1.5px dashed $green;
    color: $green;
  }

  .manage_field_btn {
    margin-left: 15px;

    span {
      background: #4d8aae;
      padding: 6px 6px;
      display: inline-block;
      color: #fff;
      font-size: 12px;
      font-family: "Helvetica Medium";
      border-radius: 6px;
    }
  }
}

// [data-title] {
//   position: relative;
//   &:hover{
//     &::before{
//       content: attr(data-title);
//       position: absolute;
//       bottom: -34px;
//       display: inline-block;
//       padding: 1px 6px;
//       border-radius: 2px;
//       background: #000;
//       color: #fff;
//       font-size: 11px;
//       font-family: $Roboto;
//       white-space: nowrap;
//       z-index: 9999;
//     }
//   }
// }
.time-table-info {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
  }

  table {
    width: 100%;

    tr {
      th {
        min-width: 150px;
        // text-align: center;
        background-color: #f7f8fb;
        font-family: $HvRegular;
        font-weight: normal;
        border-top: 1px solid #e1e6f4;
        border-bottom: 1px solid #e1e6f4;
        padding: 15px;
      }

      td {
        // text-align: center;
        border-bottom: 1px solid #e1e6f4;
        padding: 15px;
        position: relative;
        background-color: $white;

        .tag {
          background-color: #eff2f9;
          font-family: $Hvbold;
          font-size: 14px;
          color: $fontText2;
          text-align: center;
          padding: 11px;
          max-width: 117px;
          border-radius: 8px;
          position: relative;
        }

        .stap {
          width: 40px;
          height: 40px;
          cursor: default;
          background: #eff2f9;
          border-radius: 50%;
          display: -webkit-flex;
          display: flex;
          -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
          -webkit-align-items: center;
          align-items: center;
          -webkit-justify-content: center;
          justify-content: center;
          font-size: 18px;
          font-family: $Hvmed;
          color: #28263d;
          position: relative;
          z-index: 2;
        }

        &::before {
          content: "";
          position: absolute;
          top: 50%;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
          width: 100%;
          height: 2px;
          background: #eff2f9;
          left: -90%;
          z-index: 1;
        }

        &:nth-child(1) {
          &::before {
            content: none;
          }
        }

        &:nth-child(2) {
          &::before {
            content: none;
          }
        }

        &:nth-child(10) {
          &::before {
            content: none;
          }
        }

        &.active {
          &::before {
            background: $green;
          }

          .stap {
            background: $green;
            color: $white;
            cursor: pointer;
          }
        }

        &.next-fill {
          &::before {
            background: $green;
          }

          .stap {
            background: $white;
            color: $green;
            border: 1px solid $green;
            cursor: pointer;
          }
        }
      }
    }
  }

  .team-mange-btn {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    padding: 10px;

    .scheduling-title {
      margin: 5px 0;
    }

    .mange-btn {
      margin: 5px 0;

      button {
        &:first-child {
          margin-right: 12px;
        }

        &:last-child {
          background-color: $white;
          color: #8392A5;
        }
      }
    }
  }
}

// all teams
.btn_graydark {
  background-color: $graydark;
  border: 1px solid $graydark;
  color: $white;
  display: inline-block;
  text-align: center;
  min-width: 130px;
  padding: 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  line-height: 21px;
  font-family: $Roboto;
  transition: 0.3s ease;

  &:hover {
    opacity: 0.7;
    color: $white;
    text-decoration: none;
    box-shadow: 0 10px 15px rgba(70, 181, 209, 0.2);
  }

  &:disabled {
    cursor: not-allowed;
    color: $graydark;
    background-color: white;
  }
}

.btn-red {
  background-color: #e64942 !important;
  color: $white  !important;
  margin-right: 0 !important;

  &:hover {
    opacity: 0.7;
    color: #000 !important;
  }
}

.btn_green_hover {
  &:hover {
    color: #96CF04 !important;
  }
}

.scheduling-title {
  font-family: $Hvbold;
  font-weight: bold;
  line-height: 29px;
  color: $fontText2;
  margin-bottom: 15px;
}

.all-teams-section {
  .teams-table {
    padding-top: 10px;

    table {
      thead {
        th {
          white-space: nowrap;
          vertical-align: middle;

          &:nth-child(2) {
            min-width: 190px;
          }

          &:nth-child(3) {
            min-width: 150px;
          }

          &:nth-child(4) {
            min-width: 200px;
          }

          &:nth-child(5) {
            min-width: 160px;
          }
        }
      }

      tbody {
        tr {
          &:hover {
            background: transparent;

            td {
              color: $blue_btn;

              select {
                color: $blue_btn;

                option {
                  color: $fontText2;
                }
              }
            }
          }
        }

        td {
          vertical-align: middle;

          &:nth-child(2) {
            white-space: normal;
          }
        }

        .select_custom {
          max-width: max-content;
          min-width: max-content;
          width: 100%;

          select {
            font-family: $Hvmed;
            font-size: 14px;
            line-height: 20px;
            color: #3c4858;
            border: 1px solid #e1e6f4;
            box-sizing: border-box;
            border-radius: 5px;
            padding: 3px 30px 3px 11px;
          }
        }
      }
    }
  }
}

.scheduling-table {
  background: $white;
  border-radius: 4px;

  table {
    thead {
      tr {
        background: $light_bg;
        border-top: 1px solid #e1e6f4;
        border-bottom: 1px solid #e1e6f4;

        th {
          font-family: $Hvmed;
          font-style: normal;
          font-weight: 500;
          color: $graydark;
          font-size: 14px;
          vertical-align: middle;
        }
      }
    }

    tbody {
      td {
        font-family: $Hvmed;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #3c4858;
      }

      .datePicker {
        .form-group {
          @extend .mb-0;
        }
      }
    }
  }

}

.scheduling-table-button {
  margin-top: 30px;

  button {
    &:first-child {
      margin-right: 15px;

      @include mediaq(320px) {
        margin-right: 0 !important;
        margin-bottom: 15px;
      }
    }

    &.add_to_library {
      margin-left: 15px;
      margin-top: 10px;

      @include mediaq(320px) {
        margin-left: 0;
      }
    }

    @include mediaq(320px) {
      width: 100%;
    }
  }
}

// addvenues css
.addvenues-section {
  .addvenues {
    .venue-filter {
      background: $white;
      padding: 21px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #8392a547;
      border-radius: 4px 4px 0 0;

      @include mediaq(575px) {
        display: block;
      }

      .venue-filter-input {
        width: 42.5%;

        @include mediaq(575px) {
          width: 100%;
        }

        &:first-child {
          // border-right: 1px solid #8392a55e;
          margin-right: 20px;

          // padding-right: 20px;
          @include mediaq(575px) {
            margin-right: 0;
            padding-right: 0;
            margin-bottom: 15px;
            border: 0;
          }
        }

        .search-box {
          input {
            color: #73788b;
            background: #f2f2f7;
            border-radius: 8px;
            height: 44px;
            max-width: 625px;
          }
        }

        .filter_colmn {
          .select_custom {
            select {
              font-family: $Hvmed;
              font-weight: normal;
              font-size: 16px;
              line-height: 24px;
              color: $fontText3;
              background: #f2f2f7;

              option {
                background: $white;
              }

              &:focus {
                outline: 0;
              }
            }
          }
        }

        .add_venue_custom_select {
          .css-yk16xz-control {
            color: #73788b;
            background: #f2f2f7;
            border-radius: 8px;
            height: 47px;
            max-width: 625px;
            border: 1px solid #e4e7ea;

          }
        }
      }
    }

    .venue-blog {
      border-radius: 0 0 4px 4px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #8392a540;

      @include mediaq(768px) {
        display: block;
        margin-bottom: 50px;
      }

      .title {
        background: #f7f8fb;
        padding: 20px;
        border-bottom: 1px solid #8392a547;

        h3 {
          font-family: $Hvmed;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 26px;
          color: $fontText2;
          margin-bottom: 0;
        }
      }

      .venue-found {
        background: $white;
        width: 42.5%;

        @include mediaq(768px) {
          width: 100%;
        }

        .venuefound-group {
          padding-top: 17px;
          // overflow-x: auto;
          width: 100%;
          height: 555px;

          @include mediaq(768px) {
            border: 1px solid #8392a547;
            border-top: 0;
          }

          .venuefound-item {
            display: table;
            align-items: center;
            white-space: nowrap;
            width: 100%;
            border: 1px solid transparent;
            margin-bottom: 4px;

            &.active {
              background: $bodyBg;
              border: 1px solid #8392a540;
              margin-bottom: 4px;

              @include mediaq(768px) {
                border-left: 0;
              }
            }

            .common_col {
              width: 100%;
              display: inline-block;
              text-decoration: none;
              padding: 8px 15px;
              font-family: $HvRegular;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 24px;
              color: $fontText2;
              -webkit-line-clamp: 10;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
              cursor: pointer;

              @include mediaq(768px) {
                width: 300px;
              }

              &:nth-child(2) {
                width: 30%;
                padding: 8px 5px;
                color: $graydark;
                font-family: $Hvmed;
              }

              &:nth-child(3) {
                width: 130px;
                padding: 8px 15px;
                color: $graydark;
                font-family: $Hvmed;
              }
            }
          }
        }
      }

      .venue-add-btn {
        background: $bodyBg;
        width: 19%;
        padding: 10px;
        border-right: 1px solid #8392a540;
        border-left: 1px solid #8392a540;
        display: flex;
        align-items: center;
        justify-content: center;

        @include mediaq(768px) {
          width: 100%;
          border: 0;
          border-bottom: 1px solid #8392a540;
          margin-top: 20px;
        }

        button {
          margin-bottom: 20px;

          &:last-child {
            background-color: $white;
            border: 1px solid rgba(131, 146, 165, 0.25);
            margin-bottom: 0;
            color: $graydark;
            // @include mediaq(768px) {
            //   margin-left: 20px;
            // }
          }
        }
      }

      .venue-added {
        background: $white;
        width: 42.5%;

        @include mediaq(768px) {
          width: 100%;
        }

        .venueadded-group {
          padding-top: 15px;
          // overflow-x: auto;
          width: 100%;

          // height: 345px;
          @include mediaq(768px) {
            border-left: 1px solid #8392a547;
            border-right: 1px solid #8392a547;
          }

          .venueadded-item {
            display: table;
            justify-content: space-between;
            align-items: center;
            white-space: nowrap;
            border: 1px solid transparent;
            margin-bottom: 4px;

            &.active {
              background: $bodyBg;
              border: 1px solid #8392a540;
              margin-bottom: 4px;

              @include mediaq(768px) {
                border-left: 0;
              }
            }

            @include mediaq(1240px) {
              flex-wrap: wrap;
            }

            .common_col {
              display: inline-block;
              text-decoration: none;
              font-family: $HvRegular;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 24px;
              color: $fontText2;
              overflow: hidden;
              cursor: pointer;

              span {
                border-radius: 4px;
                background: $blue_btn;
                padding: 2px 6px;
                display: inline-block;
                color: $white;
                font-size: 12px;
                font-family: $Hvmed;
                border-radius: 6px;
              }

              &:nth-child(1) {
                // width: 185px;
                padding: 10px 15px;
                -webkit-line-clamp: 10;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                @include mediaq(768px) {
                  min-width: 200px;
                }
              }

              &:nth-child(2) {
                width: 145px;
                padding: 10px 5px;
                color: $graydark;
                font-family: $Hvmed;

                @include mediaq(768px) {
                  width: 130px;
                }
              }

              &:nth-child(3) {
                width: 100px;
                padding: 10px 5px;
                color: $graydark;
                font-family: $Hvmed;

                @include mediaq(768px) {
                  width: 130px;
                }
              }

              &:nth-child(4) {
                width: 100px;
                padding: 10px 15px;
              }
            }

            .venue_name {
              width: 180px;
              @include mediaq(1240px) {
                width: 100% !important;
              }
            }

            .venue_select {
              width: calc(100% - 280px);
              padding-right: 15px;

              @include mediaq(1240px) {
                padding-left: 15px;
                width: calc(100% - 100px);
              }

              @include mediaq(375px) {
                width: calc(100% - 0px);
              }

              svg {
                // display: none !important;
              }

              .css-tlfecz-indicatorContainer,
              .css-1gtu0rj-indicatorContainer {
                display: none !important;
              }
            }
            .venue_manage_field_btn {
              width: 100px !important;
              @include mediaq(375px) {
                margin-left: 10px;
              }
            }
          }
        }
      }

      .srv-validation-message {
        margin-bottom: -32px;
      }
    }
  }

  /* popup css */
  .user_popup_content {
    button {
      color: $white;
      font-size: 16px;
      line-height: 21px;
      font-family: "Roboto";
      width: unset;
      text-align: center;

      @include mediaq(375px) {
        margin-right: 0 !important;
        margin-bottom: 15px;
      }

      &.btn_lightblue {
        color: #02a8a8;

        &:hover {
          color: #02a8a8;
        }
      }

      &:hover {
        opacity: 0.7;
        color: #fff;
        text-decoration: none;
        box-shadow: 0 10px 15px #46b5d133;
        background: #02a8a8;
        border: 1px solid #02a8a8;
      }
    }
  }

  // .btn_bg{
  //   color: $white !important;
  //   font-size: 16px !important;
  //   line-height: 21px !important;
  //   font-family: "Roboto" !important;
  //   width: unset !important;
  //   &:hover{
  //     opacity: 0.7 !important;
  //     color: #fff !important;
  //     text-decoration: none !important;
  //     box-shadow: 0 10px 15px #46b5d133 !important;
  //     background: #02a8a8!important;
  //     border: 1px solid #02a8a8!important;
  //   }
  // }
}

// .css-1hb7zxy-IndicatorsContainer * .css-6q0nyr-Svg {
//   display: none !important;
// }

.css-1rhbuit-multiValue>.css-xb97g8 {
  cursor: pointer !important;
}

.team-scheduling {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    padding-bottom: 10px;
  }

  // table {
  //   thead {
  //     background-color: $light_bg;
  //     th {
  //       min-width: 190px;
  //       vertical-align: middle;
  //       text-align: center;
  //       padding: 8px;
  //       border-left: 1px solid #e5e9f2;
  //       border-top: 1px solid #c8ced3;
  //       &:last-child {
  //         border-right: 1px solid #e5e9f2;
  //       }
  //       .icon {
  //         display: inline-flex;
  //         align-items: center;
  //         vertical-align: middle;
  //         width: 34px;
  //         height: 34px;
  //         border: 1px solid #e1e6f4;
  //         border-radius: 5px;
  //         background-color: $white;
  //         margin-left: 10px;
  //         cursor: pointer;
  //         img {
  //           margin: auto;
  //         }
  //       }
  //     }
  //   }
  //   tbody {
  //     background-color: $white;
  //     tr {
  //       td {
  //         border-left: 1px solid #e5e9f2;
  //         border-top: 1px solid #c8ced3;
  //         text-align: center;
  //         &:first-child {
  //           padding: 8px;
  //         }
  //         &:last-child {
  //           border-right: 1px solid #e5e9f2;
  //         }
  //         .round-scheduling {
  //           padding: 17px;
  //           position: relative;
  //           .round-number {
  //             h6 {
  //               font-size: 14px;
  //               font-family: $Hvmed;
  //               color: $fontText2;
  //               line-height: 19px;
  //               margin-bottom: 0;
  //               img {
  //                 display: initial;
  //                 margin-left: 5px;
  //               }
  //             }
  //           }
  //           .team-name {
  //             background: #f7f8fb;
  //             border: 1px solid #e1e6f4;
  //             box-sizing: border-box;
  //             border-radius: 5px;
  //             padding: 10px;
  //             text-align: center;
  //             margin: 10px 0;

  //             h5 {
  //               font-size: 14px;
  //               font-family: $Hvmed;
  //               color: $graydark;
  //               margin-bottom: 5px;
  //               &:last-child {
  //                 margin-bottom: 0;
  //               }
  //             }
  //           }
  //           .game-number {
  //             h6 {
  //             }
  //             font-size: 14px;
  //             font-family: $Hvmed;
  //             color: $graydark;
  //           }
  //           .edit {
  //             background-color: $green;
  //             padding: 10px;
  //             border-radius: 5px 0px 0px 0px;
  //             position: absolute;
  //             bottom: 0;
  //             right: 0;
  //             cursor: pointer;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
  .team-scheduling-list {
    display: flex;
    // justify-content: center;
    background-color: $white;
  }

  .team-scheduling-info {
    display: flex;
    overflow-y: auto;

    .round-info {
      min-width: 190px;
      border-right: 1px solid #e5e9f2;

      // margin-bottom: 10px;
      &:first-child {
        border-left: 1px solid #e5e9f2;

        .game-number {
          h6 {
            font-size: 14px;
            font-family: $Hvmed;
            color: #28263d;
          }
        }
      }
    }

    #round {
      .game-number {
        h6 {
          font-size: 14px;
          font-family: $Hvmed;
          color: $graydark;
          margin-bottom: 0;
        }
      }
    }

    .pool-title {
      text-align: center;
      padding: 12px;
      min-height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top: 1px solid #e5e9f2;
      border-bottom: 1px solid #e5e9f2;
      background-color: $white;

      h5 {
        font-size: 14px;
        line-height: 21px;
        font-family: $Hvbold;
        color: #28263d;
        margin: 0;
      }
    }

    .move-delate {
      font-size: 14px;
      line-height: 21px;
      font-family: $Hvbold;
      color: #28263d;
      text-align: center;
      padding: 8px;
      background-color: #f7f8fb;
      min-height: 65px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #e5e9f2;
      border-top: 1px solid #e5e9f2;

      .icon {
        display: -webkit-inline-flex;
        display: inline-flex;
        -webkit-align-items: center;
        align-items: center;
        justify-content: center;
        vertical-align: middle;
        width: 34px;
        height: 34px;
        border: 1px solid #e1e6f4;
        border-radius: 5px;
        background-color: $white;
        margin-left: 10px;
        cursor: pointer;
      }
    }

    .round-scheduling {
      padding: 16px;
      position: relative;
      background-color: $white;
      text-align: center;
      min-height: 160px;
      border-bottom: 1px solid #e5e9f2;
      display: flex;
      justify-content: center;
      flex-direction: column;

      &.yellow-scheduling {
        // &:hover{
        background-color: #FEFFCC;

        // }
        .team-name {
          background-color: #FEFFCC;
          border: 1px solid #28263D;

          h5 {
            color: #28263D;
          }
        }

        .game-number {
          h6 {
            color: #28263D !important;
          }
        }
      }

      &.green-scheduling {
        // &:hover{
        background-color: #97DF86;

        // }
        .team-name {
          background-color: #97DF86;
          border: 1px solid #28263D;

          h5 {
            color: #28263D;
          }
        }

        .game-number {
          h6 {
            color: #28263D !important;
          }
        }
      }

      &.red-scheduling {
        // &:hover{
        background-color: #EB7171;

        // }
        .team-name {
          background-color: #EB7171;
          border: 1px solid #28263D;

          h5 {
            color: #28263D;
          }
        }

        .game-number {
          h6 {
            color: #28263D !important;
          }
        }
      }

      .round-number {
        h6 {
          font-size: 14px;
          font-family: $Hvmed;
          color: $fontText2;
          line-height: 19px;
          margin-bottom: 0;

          img {
            display: initial;
            margin-left: 5px;
          }
        }
      }

      .team-name {
        background: #f7f8fb;
        border: 1px solid #e1e6f4;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 10px;
        text-align: center;
        margin: 10px 0;
        white-space: normal;

        h5 {
          font-size: 14px;
          font-family: $Hvmed;
          color: $graydark;
          margin-bottom: 5px;
          white-space: nowrap;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .game-number {
        h6 {
          font-size: 14px;
          font-family: $Hvmed;
          color: $graydark;
        }
      }

      .edit {
        background-color: $green;
        padding: 10px;
        border-radius: 5px 0px 0px 0px;
        position: absolute;
        bottom: 0;
        right: 0;
        cursor: pointer;
      }

      .delete {
        padding: 5px 10px;
        border-radius: 0px 0px 0px 5px;
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
      }
    }
  }
}

// venuesadded-section
.venuesadded-section {
  margin-bottom: 30px;

  .venuesadded {
    display: flex;

    @include mediaq(768px) {
      display: block;
    }

    .venuesadded-col {
      width: 100%;
      background: $white;
      // box-shadow: 0px 2px 4px #edecef;
      border-radius: 5px;

      // margin-bottom: 30px;
      @include mediaq(768px) {
        width: 100%;
      }

      &:first-child {
        // margin-right: 20px;
      }

      .venuesadded-title {

        // border-bottom: 1px solid #8392a547;
        h3 {
          font-family: $Hvmed;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 26px;
          color: $fontText2;
          margin: 0;
          padding: 20px;
        }
      }

      .hunting-filter {
        padding: 20px;
        border-bottom: 1px solid #8392a547;

        .select_custom {
          max-width: 260px;
          width: 100%;

          .css-ai1s3-control {
            border: 0;
          }

          .css-g1d714-ValueContainer {
            justify-content: center;
          }

          .css-1wa3eu0-placeholder {
            color: $white;
            font-size: 14px;
            font-family: $Hvmed;
            font-weight: 500;
          }

          select {
            background: #f2f2f7;
            color: $fontText3;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            font-family: $Hvmed;
          }

          .angle_down {
            color: $fontText3;
          }
        }
      }

      .seeall-filter {
        padding: 20px 20px 0;

        .create_event_row {
          border-bottom: 1px solid #8392a547;
          padding-bottom: 0;

          label {
            font-family: $Hvmed;
          }

          input {
            background: #f2f2f7;
            border-radius: 8px;
            font-size: 14px;
            line-height: 21px;
            color: $fontText3;
          }

          &:last-child {
            border-bottom: 0;
            margin-top: 20px;
          }

          .date_icon {
            i {
              color: $fontText3;
              font-size: 17px;
            }
          }

          &.select-time {
            .form-group {
              &:hover {
                input {
                  background: $white;
                  border: 1px solid $green;
                }

                .date_icon {
                  i {
                    color: $green;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .venuesadded-table {
    &.scheduling-table {
      .table-responsive {
        // overflow-x: inherit !important;
        overflow-x: unset;
      }

      @include mediaq(768px) {
        .table-responsive {
          // overflow-x: inherit !important;
          overflow-x: auto !important;
        }

      }

      table {
        thead {
          tr {
            th {
              white-space: nowrap;
              vertical-align: middle;

              &:nth-child(2) {
                min-width: 180px;
              }

              &:nth-child(3) {
                min-width: 160px;
              }

              &:nth-child(4) {
                min-width: 160px;
              }
            }
          }
        }

        tbody {
          tr {
            &:hover {
              background: transparent;
            }
          }

          td {
            font-family: $HvRegular;
            white-space: nowrap;
            vertical-align: middle;
            color: $fontText2;
            font-weight: 400;

            &:nth-child(2) {
              white-space: normal;
            }
          }
        }
      }

      .title-btn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        flex-wrap: wrap;

        &.schedule_btns {
          padding: 0 10px 10px 10px;
          flex-wrap: nowrap;
          justify-content: start;
          overflow-x: auto;
          width: calc(100% - 40px);
          margin: 0 auto;
          padding-left: 0;
          padding-right: 0;

          .btn_green_bordered {
            outline: none;
            border-radius: 8px;
            background: none;
            font-size: 13px;
            font-family: "Helvetica Regular";
            padding: 9px 5px;
            position: relative;
            color: $graydark;
            border: 1px solid $graydark;
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            margin: 5px;
            white-space: nowrap;

            &:hover,
            &.active {
              color: $white;
              background-color: #96cf04;
              border: 1px solid #96cf04;
            }

            &.in-active {
              color: #96cf04;
              border: 1px solid #96cf04;

              &:hover {
                color: $white;
                border: 1px solid #96cf04;
              }
            }

            &:first-child {
              margin-left: 0;
            }

            &:last-child {
              margin-right: 0;
            }
          }
        }

        @include mediaq(767px) {
          padding: 20px 10px;
        }

        h3 {
          font-family: $Hvmed;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 26px;
          color: $fontText2;
          margin: 0;
        }
        .btn_green{
          &.btn-steel-grey{
            background-color: $blue_btn !important;
            border: 1px solid $blue_btn !important;
            margin-right: 10px;

            @include mediaq(375px) {
              margin: 10px 0;
            }
          }

          @include mediaq(375px) {
            padding: 10px;
            width: 100%;
          }
        }
      }

    }

    .select-time-table {
      .form-group {
        input {
          background: #f9f6f7;
          border-radius: 8px;
          border: 1px solid #f9f6f7;
          color: $fontText2;
          font-size: 14px;
        }

        .date_icon {
          i {
            color: $green;
          }
        }

        &:hover {
          input {
            background: $white;
            border: 1px solid $green;
          }
        }
      }
    }
  }

  .scheduling-table-button {

    button {
      margin-bottom: 10px;

      @include mediaq(375px) {
        width: 100%;
      }

      &:last-child {
        margin-left: 15px;

        @include mediaq(375px) {
          margin-left: 0;
        }
      }
    }

    .btn_bg-transparent {
      background-color: transparent;
      color: $graydark;
      border: 1px solid $graydark;
    }
  }
}

// custome checkbox
// .checkbox {
//   position: relative;
//   align-items: center;
//   display: flex;
//   justify-content: space-between;
//   input {
//     position: absolute;
//     opacity: 0;
//     left: 0;
//     right: 0;
//     top: 0;
//     height: 100%;
//     width: 100%;
//     z-index: 1;
//     cursor: pointer;
//     width: 20px;
//     height: 24px;
//   }
//   .square {
//     position: relative;
//     width: 20px;
//     height: 20px;
//     border-radius: 4px;
//     transition: all 0.5s ease 0s;
//     background: rgba(239, 242, 249, 0.5);
//     border: 1.2px solid rgba(131, 146, 165, 0.5);
//     border-radius: 5px;
//   }
//   .square:after {
//     content: "";
//     display: block;
//     position: absolute;
//     top: 3px;
//     left: 7px;
//     width: 5px;
//     height: 10px;
//     border: solid $white;
//     border-width: 0 2px 2px 0;
//     transform: rotate(45deg);
//     opacity: 0.1;
//   }

//   label {
//     display: -webkit-box;
//     -webkit-line-clamp: 1;
//     -webkit-box-orient: vertical;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     width: calc(100% - 20px);
//   }
//   input:checked ~ .square::after {
//     opacity: 1;
//   }
//   input:checked ~ .square {
//     background-color: $green;
//     border: 1px solid $green;
//   }
// }

// huntingdonshire

.huntingdonshire-section {
  &.manual_division_section {
    max-height: 509px;
    overflow-y: auto;
  }

  .formate-section {
    display: flex;

    @include mediaq(768px) {
      display: block;
    }

    .formate-blog {
      width: 100%;
      background: $white;
      box-shadow: 0px 2px 4px #edecef;
      border-radius: 5px;
      margin-right: 0;

      &.division_yes {
        width: 100%;
        margin-right: 0;
      }

      @include mediaq(768px) {
        width: 100%;
      }

      .bracket-builder-btn {
        padding: 20px;
      }
    }

    .formate-row {
      padding: 20px 20px 10px;
      border-bottom: 1px solid #8392a547;

      &:last-child {
        border-bottom: 0;
      }

      .btn_green {
        padding: 5px;
        min-width: 120px;
        margin-top: 2px;
      }

      h3 {
        font-family: $Hvmed;
        font-style: normal;
        font-size: 18px;
        line-height: 26px;
        color: $fontText2;
        margin: 0;
      }

      .row {
        margin-right: -5px;
        margin-left: -5px;

        .col-md-4,
        .col-md-6,
        .col-md-12 {
          padding-right: 5px;
          padding-left: 5px;
        }
      }

      Label {
        font-family: $Hvmed;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: $fontText2;
        margin-bottom: 4px;
      }

      .select_custom {
        select {
          // background: #f2f2f7;
          border-radius: 8px;
          font-family: $Hvmed;
          font-size: 16px;
          line-height: 24px;
          color: $fontText3;
        }
      }

      .create_event_row {
        input {
          // background: #f2f2f7;
          border-radius: 8px;
          font-family: $Hvmed;
          font-size: 16px;
          line-height: 24px;
          color: $fontText3;
        }
      }

      .button_type {
        width: 100%;
        outline: none;
        color: $fontText3;
        border-radius: 8px;
        border: 1px solid $fontText3;
        background: none;
        font-size: 16px;
        font-family: $HvRegular;
        cursor: pointer;
        padding: 9px;
        position: relative;
        cursor: no-drop;

        &.active {
          color: $green;
          border: 1px solid $green;
          cursor: pointer;
        }
      }
    }

    .seeding-criteria {
      width: 30%;
      background: $white;
      box-shadow: 0px 2px 4px #edecef;
      border-radius: 5px;

      @include mediaq(768px) {
        width: 100%;
        margin-top: 30px;
      }

      .formate-checkbox {
        border: 1px solid #8392a540;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 12px;
        margin-bottom: 8px;
        cursor: move;

        &:last-child {
          margin-bottom: 10px;
        }

        .checkbox {
          display: flex;

          label {
            font-family: $Hvmed;
            font-size: 14px;
            line-height: 21px;
            color: $fontText3;
            margin-left: 10px;
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .auto-section {
    display: flex;
    // &:first-child{
    //   border-bottom: 1px solid #dad9dc;
    // }
    padding-bottom: 20px;

    &:last-child {
      padding-bottom: 0;
    }

    @include mediaq(768px) {
      display: block;
    }

    .auto-division {
      width: 76%;
      background: $white;
      box-shadow: 0px 2px 4px #edecef;
      border: 1px solid rgba(131, 146, 165, 0.25);
      box-sizing: border-box;
      border-radius: 4px;
      border-radius: 5px;
      margin-right: 20px;

      @include mediaq(768px) {
        width: 100%;
      }

      .divisions-blog {
        .divisions-cloumn {
          min-width: none;
          width: 100%;
          background: transparent;
          border: 0;
          box-sizing: border-box;
          border-radius: 0;
        }
      }
    }

    .seeding-criteria {
      width: 24%;
      background: $white;
      box-shadow: 0px 2px 4px #edecef;
      border-radius: 5px;

      @include mediaq(768px) {
        width: 100%;
        margin-top: 30px;
      }

      .formate-checkbox {
        border: 1px solid #8392a540;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 12px;
        margin-bottom: 8px;
        cursor: move;

        &:last-child {
          margin-bottom: 10px;
        }

        .checkbox {
          display: flex;

          label {
            font-family: $Hvmed;
            font-size: 14px;
            line-height: 21px;
            color: $fontText3;
            margin-left: 10px;
            margin-bottom: 0;
          }
        }
      }
    }
  }

}

// auto-section




// divisions-section
.divisions-section {
  .divisions-blog {
    display: flex;
    overflow-x: auto;
    padding-bottom: 10px;

    .divisions-cloumn {
      min-width: 778px;
      background: $white;
      border: 1px solid rgba(131, 146, 165, 0.25);
      box-sizing: border-box;
      border-radius: 4px;
      padding-top: 10px;
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }

      .team-member {
        background: $light_bg;
        border-radius: 0px;
        border-top: 1px solid #8392a547;
        border-bottom: 1px solid #8392a547;
        padding: 5px 15px 11px;

        label {
          font-family: $Hvmed;
          font-size: 14px;
          line-height: 24px;
          color: $fontText2;
          margin-bottom: 5px;
        }

        .sub-title {
          font-family: $Hvmed;
          font-size: 14px;
          line-height: 24px;
          color: $fontText3;
          margin-bottom: 5px;
          background-color: white;
          padding: 10px 15px;
          border: 1px solid rgba(131, 146, 165, 0.25);
        }

        .select_custom {
          select {
            border-radius: 8px;
            font-family: $Hvmed;
            font-size: 15px;
            line-height: 24px;
            color: $fontText3;
            background: $white;
          }
        }
      }

      .divisions-group {
        padding: 15px;
        height: 300px;
        overflow-y: auto;

        .divisions-list {
          .row {
            margin-right: -5px;
            margin-left: -5px;

            .col-md-4,
            .col-md-6,
            .col-4 {
              padding-right: 5px;
              padding-left: 5px;
            }

            .mb-10 {
              margin-bottom: 10px;
            }
          }

          .divisions-item {
            background: #f2f2f7;
            border-radius: 8px;
            padding: 10px 32px 10px 15px;
            font-family: $HvRegular;
            font-size: 15px;
            line-height: 24px;
            color: $fontText3;
            width: 242px;
            cursor: move;
            display: flex;
            align-items: center;

            &.disable {
              background: #ceced4fc;
              cursor: default;
            }

            img {
              margin-right: 10px;
            }

            p {
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }

            span {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .division-remove {
              position: absolute;
              right: 5px;
              top: 12px;
              cursor: pointer;
            }
          }

          &.divisions-list-small {
            .col-4 {
              flex: 0 0 25%;
              max-width: 25%;

              .divisions-item {
                width: 100%;
                display: flex;

                &.disable {
                  background: #ceced4fc;
                  cursor: default;
                }

                img {
                  margin-right: 8px;
                }
              }
            }
          }
        }
      }

      .divisions-item-add {
        background: transparent;
        border: 1px solid #c7c8ca;
        border-radius: 8px;
        border-radius: 8px;
        padding: 10px 15px;
        font-family: $HvRegular;
        font-size: 15px;
        line-height: 24px;
        color: $fontText3;
        width: 100%;
        text-align: center;
        cursor: pointer;

        &:hover {
          border-style: dashed;
        }

        p {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

      }
    }
  }

  .divisions-team-group {
    background-color: $white;
    border: 1px solid rgba(131, 146, 165, 0.25);
    box-sizing: border-box;
    border-radius: 4px;
    margin: 13px 0;
    padding: 10px;
    height: 238px;
    overflow-y: auto;

    .row {
      margin-right: -5px;
      margin-left: -5px;

      .col-sm-3,
      .col-md-4,
      .col-md-6,
      .col-4 {
        padding-right: 5px;
        padding-left: 5px;
      }

      .mb-10 {
        margin-bottom: 10px;
      }
    }

    .divisions-team-list {
      background: #f2f2f7;
      border-radius: 8px;
      padding: 10px 15px;
      font-family: $HvRegular;
      font-size: 15px;
      line-height: 24px;
      color: $fontText3;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      cursor: move;
      display: flex;
      align-items: center;
      transition: 3s;

      &.disable {
        background: #ceced4fc;
        cursor: default;
      }

      img {
        margin-right: 10px;
      }

      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &:hover {
        img {
          display: block;
          transition: 3s;
        }
      }
    }
  }
}

// special-request-section
.special-request-section {
  .specialrequest-blog {
    display: flex;

    @include mediaq(1530px) {
      display: block;
    }

    .specialrequest-col {
      width: 50%;
      background: $white;
      box-shadow: 0px 2px 4px #edecef;
      border-radius: 5px;
      margin-right: 20px;
      @include mediaq(1530px) {
        width: 100%;
      }

      &:last-child {
        margin-right: 0;

        @include mediaq(1530px) {
          margin-top: 30px;
        }
      }

      .specialrequest-title {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        padding: 11px 20px;

        // border-bottom: 1px solid #8392a547;
        h3 {
          font-family: $Hvmed;
          font-size: 18px;
          line-height: 26px;
          color: $fontText2;
          margin: 0;
        }

        button {
          padding: 10px;
          min-width: 85px;
        }
      }

      .specialrequest-info {

        // padding: 20px 20px 6px;
        // .row {
        //     margin-right: -7px;
        //     margin-left: -7px;
        //     .col-md-4,
        //     .col-md-6,
        //     .col-4,
        //     .col-md-5,
        //     .col-md-2,
        //     .col-md-3 {
        //         padding-right: 7px;
        //         padding-left: 7px;
        //     }
        // }
        .request-text {
          font-family: $Hvmed;
          font-size: 15px;
          line-height: 24px;
          color: $fontText3;
        }

        .create_event_row {
          input {
            background: #f2f2f7;
            border-radius: 8px;
            font-family: $HvRegular;
            font-size: 14px;
            line-height: 24px;
            color: $fontText3;
          }
        }

        .button_type {
          width: 100%;
          outline: none;
          color: $fontText3;
          border-radius: 8px;
          border: 1px solid $fontText3;
          background: none;
          font-size: 15px;
          font-family: $HvRegular;
          cursor: pointer;
          padding: 9px;
          position: relative;

          &.start {
            color: $green;
            border: 1px solid $green;
          }

          &.end {
            color: $error;
            border: 1px solid $error;
          }
        }

        table {
          thead {
            tr {
              background: $light_bg;
              border-top: 1px solid #e1e6f4;
              border-bottom: 1px solid #e1e6f4;

              th {
                font-family: $Hvmed;
                font-style: normal;
                font-weight: 500;
                color: $graydark;
                font-size: 14px;
              }
            }
          }

          tbody {
            td {
              font-family: $Hvmed;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              color: #3c4858;

              i {
                cursor: pointer;

                &:hover {
                  color: $remove;
                }
              }

              .no-data {
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
}

// pool-section
.pool-section {
  overflow-x: auto;

  .add-pool-blog {
    padding-top: 10px;
    background: $white;
    box-shadow: 0px 2px 4px #edecef;
    border-radius: 5px;

    .number-of-pool {
      padding: 10px 20px 20px;

      label {
        font-family: $Hvmed;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #28263d;
        margin-bottom: 4px;

        span {
          font-family: $HvRegular;
        }
      }

      .select_custom {
        max-width: 332px;

        select {
          background: #f2f2f7;
          border-radius: 8px;
          font-family: $Hvmed;
          font-size: 16px;
          line-height: 24px;
          color: #6c7b95;
        }
      }

      .div_pool_show {
        max-width: 332px;
        background: #f2f2f7;
        border-radius: 8px;
        font-family: $Hvmed;
        font-size: 16px;
        line-height: 50px;
        color: #6c7b95;
        padding-left: 10px;
      }
    }

    .pool-group {
      padding: 0px 15px 15px;
      margin-top: 15px;
      max-height: 300px;
      overflow-y: auto;

      .pool-list {
        display: flex;

        &.division-pool-group {
          flex-wrap: wrap;
        }

        .pool-col {
          border: 1px solid rgba(131, 146, 165, 0.25);
          box-sizing: border-box;
          border-radius: 8px;
          margin-right: 15px;

          &:last-child {
            margin-right: 0;
          }

          .pool-type {
            background: #e0e4e8;
            border: 1px solid #e0e4e8;
            box-sizing: border-box;
            border-radius: 8px 8px 0px 0px;
            padding: 10px;
            font-family: $Hvmed;
            font-size: 16px;
            line-height: 24px;
            color: $fontText3;
            text-align: center;
            margin-bottom: 15px;
            position: sticky;
            top: 1px;
            z-index: 99;
          }

          .pool-item {
            margin: 8px 15px;

            span {
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }

            &.disable {
              background: #ceced4fc;
              cursor: default;
            }

            &:last-child {
              margin-bottom: 15px;
            }

            @include mediaq(768px) {
              width: 300px;
            }
          }
        }

        .pool-item {
          background: #f2f2f7;
          border-radius: 8px;
          padding: 10px 28px 10px 15px;
          font-family: $HvRegular;
          font-size: 15px;
          line-height: 24px;
          color: $fontText3;
          width: 300px;
          margin: 5px 5px;
          position: relative;
          cursor: move;
          display: flex;
          align-items: center;
          transition: 3s;

          &.disable {
            background: #ceced4fc;
            cursor: default;
          }

          img {
            margin-right: 8px;
          }

          span {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }

          .pool-remove {
            position: absolute;
            top: 12px;
            right: 5px;
            cursor: pointer;
          }

          @include mediaq(768px) {
            width: 48%;
          }

          @include mediaq(575px) {
            width: 100%;
          }

          .noofteam {
            position: absolute;
            right: 8px;
            bottom: -2px;
            font-size: 12px;
            color: $graydark;
            font-family: $Hvbold;
          }

          &.full-team {
            background: #8392a5;
            color: $white;

            .noofteam {
              color: $white;
            }
          }

          &.over-team {
            background: #EB7171;
            color: $white;

            .noofteam {
              color: $white;
            }
          }

          &.pool-add {
            background: $white;
            border: 1px solid #c7c8ca;
            border-radius: 8px;
            padding: 10px 15px;
            font-family: "Helvetica Regular";
            font-size: 15px;
            line-height: 24px;
            color: #6c7b95;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            text-align: center;
            cursor: pointer;
            display: block;

            &:hover {
              border-style: dashed;
            }
          }
        }

      }
    }
  }
}

// matchUpAuto-section

.matchUpAuto-section {
  .matchupauto-blog {
    background: $white;
    box-shadow: 0px 2px 4px #edecef;
    border-radius: 5px;

    .matchupmanual-team-blog {
      .matchupmanual-team-group {
        display: flex;
        flex-wrap: wrap;
        border-bottom: 1px solid #8392a547;
        padding: 7px 0;

        .matchupmanual-team-list {
          width: 25%;
          border-right: 1px solid #8392a547;
          padding: 7px 15px;

          &:nth-child(4n) {
            border-right: 0;
          }

          @include mediaq(1199px) {
            width: 33.3%;

            &:nth-child(3n) {
              border-right: 0;
            }

            &:nth-child(4n) {
              border-right: 1px solid #8392a547;
            }
          }

          @include mediaq(768px) {
            width: 50%;

            &:nth-child(3n) {
              border-right: 1px solid #8392a547;
            }

            &:nth-child(even) {
              border-right: 0;
            }
          }

          @include mediaq(575px) {
            width: 100%;

            &:nth-child(3n) {
              border-right: 0;
            }

            border-right: 0;
          }

          .matchupmanual-team-item {
            background: #f2f2f7;
            border-radius: 8px;
            padding: 10px 15px;
            font-family: $HvRegular;
            font-size: 15px;
            line-height: 24px;
            color: $fontText3;
            text-align: center;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            cursor: move;
            position: relative;
            display: flex;

            &.disable {
              background: #ceced4fc;
              cursor: default;
            }

            span {
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }

            .noofteam {
              position: absolute;
              right: 8px;
              bottom: -2px;
              font-size: 12px;
              color: $graydark;
              font-family: $Hvbold;
            }

            &.full-team {
              background: #8392a5;
              color: $white;

              .noofteam {
                color: $white;
              }
            }

            &.over-team {
              background: #EB7171;
              color: $white;

              .noofteam {
                color: $white;
              }
            }
          }
        }
      }

      .bg-grey {
        background: #f7f8fb;
        padding: 30px;
      }
    }

    .match-group {
      display: flex;
      flex-wrap: wrap;
      max-height: 600px;
      overflow-y: auto;

      .match-list {
        padding: 15px;
        width: 25%;
        border-right: 1px solid #8392a547;
        border-bottom: 1px solid #8392a547;

        &:nth-child(4n) {
          border-right: 0;
        }

        @include mediaq(1199px) {
          width: 33.3%;

          &:nth-child(3n) {
            border-right: 0;
          }

          &:nth-child(4n) {
            border-right: 1px solid #8392a547;
          }
        }

        @include mediaq(768px) {
          width: 50%;

          &:nth-child(3n) {
            border-right: 1px solid #8392a547;
          }

          &:nth-child(even) {
            border-right: 0;
          }
        }

        @include mediaq(575px) {
          width: 100%;

          &:nth-child(3n) {
            border-right: 0;
          }

          border-right: 0;

          &:last-child {
            border-bottom: 0;
          }
        }

        .match-item {
          background: #f2f2f7;
          border: 1px solid #f2f2f7;
          border-radius: 8px;
          padding: 10px 32px 10px 15px;
          font-family: $HvRegular;
          font-size: 15px;
          line-height: 24px;
          color: $fontText3;
          text-align: center;
          // width: 300px;
          // margin:5px 5px;
          display: flex;
          justify-content: center;
          position: relative;

          &.disable {
            background: #ceced4fc;
            cursor: default;
          }

          span {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }

          img {
            cursor: pointer;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translate(-40%, -50%);
            background: #f2f2f7;
          }
        }

        .match-item-add {
          background: transparent;
          border: 1px solid #c7c8ca;
          border-radius: 8px;
          padding: 10px 15px;
          font-family: $HvRegular;
          font-size: 15px;
          line-height: 24px;
          color: $fontText3;
          text-align: center;
          // width: 300px;
          // margin:5px 5px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;

          &:hover {
            border-style: dashed;
          }
        }

        p {
          font-family: $Hvbold;
          font-size: 14px;
          line-height: 21px;
          text-align: center;
          color: #28263d;
          padding: 5px 0;
        }
      }
    }
  }
}

.scheduling-tab-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background: #f7f8fb;
  border-radius: 0px;
  border-bottom: 1px solid #8392a547;
  padding: 20px 20px 10px;
  margin-bottom: 15px;

  .select-type {
    display: flex;
  }

  button {
    padding: 9px;
    margin-right: 10px;
    margin-bottom: 10px;

    &:last-child {
      margin-right: 0;
    }

    @include mediaq(480px) {
      margin-right: 0;
      width: 100%;
    }

    &.btn-right {
      margin-left: auto;

      @include mediaq(1199px) {
        margin-left: 0;
      }
    }
  }

  .btn_white {
    background-color: $white  !important;
    color: $green  !important;
  }

  .select_custom {
    @include mediaq(480px) {
      min-width: 100%;
    }

    select {
      border: 1px solid $green;
      color: $green;
      height: 42px;
      font-size: 16px;
      line-height: 18px;

      option {
        color: $graydark;
      }
    }

    i {
      color: $green;
    }
  }
}

// Grid css
.filed-grid-bg {
  background: $white;
}

.filed-grid {
  display: flex;
  // flex-wrap: wrap;
  overflow-x: auto;
  background-color: $white;
  margin-bottom: 30px;
  min-height: 156px;
  border-bottom: 1px solid #e0e4e8;
  border-top: 1px solid #e0e4e8;

  .filed-grid-col {
    // width: 20%;
    padding: 10px;
    border-right: 1px solid #e0e4e8;

    // border-bottom: 1px solid #e0e4e8;
    // @include mediaq(1366px) {
    //     width: 25%;
    // }
    // @include mediaq(991px) {
    //     width: 33.3%;
    // }
    // @include mediaq(767px) {
    //     width: 50%;
    // }
    // @include mediaq(575px) {
    //     width: 100%;
    // }
    &:nth-child(5n) {
      border-right: 0;
    }

    &:last-child {
      border-right: 0;
    }

    @include mediaq(1366px) {

      // width: 33.3%;
      &:nth-child(3n) {
        border-right: 0;
      }

      &:nth-child(4n) {
        border-right: 1px solid #e0e4e8;
      }

      &:nth-child(5n) {
        border-right: 1px solid #e0e4e8;
      }
    }

    @include mediaq(768px) {

      //width: 50%;
      &:nth-child(3n) {
        border-right: 1px solid #e0e4e8;
      }

      &:nth-child(even) {
        border-right: 0;
      }
    }

    @include mediaq(575px) {

      // width: 100%;
      &:nth-child(3n) {
        border-right: 0;
      }

      border-right: 0;
    }
  }

  .filed-grid-box {
    background: #fdfdfe;
    border: 1px solid #dadee4;
    box-sizing: border-box;
    border-radius: 8px;
    width: 250px;
    cursor: move;

    .team-name {
      padding: 14px 16px;
      text-align: center;
      border-bottom: 1px solid #dadee4;
      cursor: move;

      h5 {
        font-size: 16px;
        font-weight: 400;
        color: $fontText2;
        margin-bottom: 6px;
        display: flex;
        justify-content: center;

        // white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;
        &:last-child {
          margin-bottom: 0;
        }

        span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      p {
        font-weight: 500;
      }
    }

    ul {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-bottom: 0;
      width: 100%;

      li {
        width: 25%;
        display: inline-block;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        color: $fontText2;
        padding: 7px;
        border-right: 1px solid #dadee4;

        &:first-child {
          width: 50%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &:last-child {
          border-right: none;
        }

        img {
          display: inline-block;
          margin-right: 6px;
        }
      }
    }

    .game-type {
      font-size: 14px;
      font-weight: 400;
      color: #28263D;
      text-align: center;
      padding: 10px;
      border-top: 1px solid #dadee4;
    }
  }
}

.venues-date {
  .see-all-title {
    background-color: $light_bg;
    font-size: 14px;
    color: $fontText2;
    font-weight: 700;
    text-transform: uppercase;
    padding: 15px;
    text-align: center;
    border-top: 1px solid #dadee5;
    border-bottom: 1px solid #dadee5;
    margin-bottom: 24px;
  }

  .venues {
    background-color: $white;

    .row {
      padding: 0 10px 10px;
      margin-right: -5px;
      margin-left: -5px;

      [class*="col"] {
        padding-right: 5px;
        padding-left: 5px;
        margin: 5px 0;
      }
    }
  }

  .select-box {
    background: #f7f8fb;
    border: 1px solid #eceef1;
    cursor: pointer;
    font-size: 16px;
    color: $fontText2;
    text-align: center;
    padding: 10px;
    border-radius: 8px;
    font-weight: 400;
    font-family: $HvRegular;
    cursor: pointer;

    &.active {
      background-color: $green;
      color: $white;
    }
  }
}

.time-table {
  margin-top: 40px;

  .filed-grid-box {
    img {
      position: absolute;
      top: 5px;
      right: 5px;
    }
  }

  .table-responsive-xl {
    overflow-x: auto;
    width: 100%;
    padding-bottom: 10px;
  }

  table {
    border-spacing: 15px;
    text-align: center;

    thead {
      background-color: $light_bg;

      th {
        border: 10px solid #f7f8fb;
        border-bottom: 0;
        min-width: 200px;

        &:first-child {
          position: sticky;
          left: -5px;
          z-index: 1;
          background-color: #f7f8fb;
        }

        .table_bg {
          padding: 15px 10px;
          font: 14px;
          color: $fontText2;
          border: 1px solid #dadee5;
          border-left: 0;
          border-right: 0;
        }
      }
    }

    tbody {
      background-color: $light_bg;

      td {
        border: 10px solid #f7f8fb;
        border-top: 0;
        border-bottom: 0;
        background: $white;
        border-bottom: 1px solid #dadee5;
        padding: 10px;
        color: $graydark;
        font-size: 16px;
        position: relative;
        min-width: 240px;

        p {
          color: $black;
        }

        &:first-child {
          position: sticky;
          left: -5px;
          z-index: 1;
          background-color: white;

          div {
            color: $fontText2;
            font-family: $Hvbold;
          }
        }

        // &:nth-child(2){
        //   position:sticky;
        //   left:40px;
        //   z-index:1;
        //   background-color:white;
        // }
        .drop-item-box {
          border: 1px solid #c1c3c9;
          border-radius: 8px;
          position: relative;

          &.active {
            background-color: $green;

            .table_bg {
              color: $white;
            }

            ul {
              li {
                color: $white;
              }
            }

            .game-type {
              color: $white;
            }

          }

          .table_bg {
            // background-color: $white;
            font-size: 16px;
            color: $graydark;
            padding: 28px 10px 15px;
            border-radius: 8px 8px 0 0;
            border-bottom: 1px solid #c1c3c9;
            // border: 1px solid #dadee5;
            border-top: 0;
            // &:first-child {
            //     color: $fontText2;
            // }

          }

          ul {
            display: flex;
            justify-content: space-around;
            align-items: center;
            margin-bottom: 0;
            width: 100%;

            li {
              width: 25%;
              display: inline-block;
              text-align: center;
              font-size: 16px;
              font-weight: 400;
              color: $fontText2;
              padding: 7px;
              border-right: 1px solid #c1c3c9;

              &:first-child {
                width: 50%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              &:last-child {
                border-right: none;
              }

              img {
                display: inline-block;
                margin-right: 6px;
              }
            }
          }

          .game-type {
            font-size: 14px;
            font-weight: 400;
            color: #28263D;
            text-align: center;
            padding: 5px;
            border-top: 1px solid #c1c3c9;
          }

          &.bg-grey {
            background-color: #F0F3F4;

            ul {
              li {
                color: #6C7B95;
              }
            }

            .game-type {
              color: #6C7B95;
            }
          }
        }

        .exchange-team {
          transform: rotate(90deg);
          position: absolute;
          top: 8px;
          left: 5px;
          font-size: 18px;
          cursor: pointer;
        }
      }

      .match-item-add {
        background: transparent;
        border: 1px solid #c7c8ca;
        border-radius: 8px;
        padding: 40px 15px;
        font-family: $HvRegular;
        font-size: 15px;
        line-height: 24px;
        color: $fontText3;
        text-align: center;
        // width: 300px;
        // margin:5px 5px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        &:hover {
          border-style: dashed;
        }
      }

      .match-item-add-disable {
        background-color: $border_light;
        border: 1px solid $border_light;
        border-radius: 8px;
        padding: 40px 15px;
        font-family: $HvRegular;
        font-size: 15px;
        line-height: 24px;
        color: $fontText3;
        text-align: center;
        // width: 300px;
        // margin:5px 5px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        &.occupied {
          padding: 0;
        }
      }
    }
  }
}

// manage-section
.manage-section {
  .scheduling-tab-group {
    border-bottom: 0;
  }

  .manage-table {
    &.scheduling-table {
      table {
        thead {
          th {
            min-width: 100px;

            &:nth-child(1) {
              min-width: 80px;
            }

            &:nth-child(2) {
              min-width: 200px;
            }

            &:nth-child(5) {
              min-width: 200px;
            }

            // &:last-child{
            //     min-width:275px
            // }
          }
        }

        tbody {
          td {
            font-family: $Hvmed;
            white-space: nowrap;
            vertical-align: middle;
            color: $fontText2;

            &:nth-child(2) {
              white-space: normal;
            }

            &:nth-child(5) {
              white-space: normal;
            }

            i {
              cursor: pointer;
            }
          }

          .action-btn {
            button {
              border: 1px solid #0000001a;
              box-sizing: border-box;
              border-radius: 6px;
              background: $white;
              font-family: $Hvmed;
              font-size: 14px;
              line-height: 24px;
              color: $graydark;
              padding: 3px 6px;
              min-width: 130px;
              width: 100%;
              margin-right: 15px;

              &.delayed-btn {
                &:hover {
                  background: $graydark;
                  color: $white;
                }

                &.active {
                  background: $graydark;
                  color: $white;
                }
              }

              &.reschedule-btn {
                color: $green;
                border: 1px solid $green;

                &:hover {
                  background: $green;
                  color: $white;
                }

                &.active {
                  background: $green;
                  color: $white;
                }
              }
            }
          }
        }
      }
    }
  }
}

// publish-section
.publish-section {
  .publish-table {
    padding-top: 10px;

    &.scheduling-table {
      table {
        thead {
          th {
            &:nth-child(2) {
              min-width: 110px;
            }
          }
        }

        tbody {
          td {
            font-family: $Hvmed;
            white-space: nowrap;
            vertical-align: middle;
            color: $fontText2;
            // &:nth-child(2) {
            //     white-space: normal;
            // }
            // &:nth-child(5) {
            //     white-space: normal;
            // }
          }

          .red-text {
            color: $remove;
          }

          .action-btn {
            button {
              box-sizing: border-box;
              border: none;
              border-radius: 6px;
              background: #8392a51f;
              font-family: $Hvmed;
              font-size: 14px;
              line-height: 24px;
              color: $graydark;
              padding: 3px 10px;
              // max-width: 115px;
              //width: 100%;
              margin-right: 30px;

              &.bg-steel-blue {
                background: transparent;
                color: $blue_btn;
                border: 1px solid $blue_btn;

                &:hover {
                  background: $blue_btn;
                  color: $white;
                }

                &.active {
                  background: $blue_btn;
                  color: $white;
                }
              }

              &.bg-light-green {
                background: transparent;
                color: $linkcolor;
                border: 1px solid $linkcolor;

                &:hover {
                  background: $linkcolor;
                  color: $white;
                }

                &.active {
                  background: $linkcolor;
                  color: $white;
                }
              }

              &.bg-green {
                background: transparent !important;
                color: $green;
                border: 1px solid $green;

                &:hover {
                  background: $green  !important;
                  color: $white;
                }

                &.active {
                  background: $green  !important;
                  color: $white;
                }
              }
            }
          }
        }
      }
    }
  }

  .report-section {
    margin-top: 30px;

    .report-group {
      //  background: $white;
      border-radius: 4px;
      //padding: 15px;
      display: flex;
      flex-wrap: wrap;
      cursor: pointer;

      .report-list {

        // width: 33.3%;
        // @include mediaq(768px) {
        //   width: 50%;
        // }
        // @include mediaq(575px) {
        //   width: 100%;
        // }
        .report-item {
          background: $blue_btn;
          color: $white;
          border: 1px solid transparent;
          border-radius: 8px;
          padding: 8px 15px;
          font-family: $HvRegular;
          font-size: 15px;
          line-height: 24px;
          cursor: pointer;
          text-align: left;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;

          // margin: 5px 5px;
          &:hover {
            background: transparent;
            color: $blue_btn;
            border: 1px solid $blue_btn;
          }
        }
      }
    }
  }
}

// manage_team_add_popup
.manage_team {
  height: 60%;
  overflow-x: auto;
}

.score {
  .user_popup_header {
    border-bottom: 0;
  }
}

.manage_team_add_popup {
  padding: 0 15px 15px;

  .manage_content {
    border-top: 1px solid #8392a540;
    padding: 30px 0;
    border-bottom: 1px solid #8392a540;

    .row {
      margin-right: -7px;
      margin-left: -7px;

      .col-md-6 {
        padding-right: 7px;
        padding-left: 7px;
      }
    }

    .create_event_row {
      input {
        background: $lightgray4;
        border-radius: 8px;
        font-family: $HvRegular;
        font-size: 16px;
        line-height: 24px;
        color: $fontText3;
      }

      .date_icon {
        i {
          color: $fontText3;
          font-size: 18px;
        }
      }
    }
  }

  .scheduling-table-button {
    text-align: center;

    button {
      max-width: 160px;
      width: 100%;
      font-size: 16px;
      font-family: $Hvmed;
      text-align: center;
      margin-right: 0;
    }


  }
}

input,
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.input-custome {
  background: #f2f2f7;
  height: 42px;
}


.bracket-tab-btn {
  margin: 30px 0 0;

  .select-type {
    background: #fff;
    padding: 15px;
  }

  button {
    margin-right: 10px;
    margin-bottom: 10px;
    margin-top: 10px;

    @include mediaq(575px) {
      width: 100%;
    }

  }

  .btn_white {
    background-color: #fff !important;
    color: #96CF04 !important;
  }
}

.bracket-chart {
  margin-top: 20px;
  background-color: $white;

  .sc-dlfnuX {
    background: #f7f8fb;
    font-size: 14px;
    line-height: 21px;
    font-family: $Hvmed;
    color: #28263d !important;
    padding: 14px;
  }

  .sc-dlfnuX {
    width: 366px;
    border: 1px solid #e1e6f4;
  }

  .sc-fubCzh:nth-child(odd):not(:last-child)::after {
    border-right: 3px solid $green  !important;
  }

  .fxqodV:nth-child(odd):not(:last-child)::after {
    border-top: 3px solid $green  !important;
  }

  [dir="ltr"] .sc-fubCzh:nth-child(even)::after {
    border-right: 3px solid $green  !important;
  }

  .fxqodV:nth-child(even)::after {
    border-bottom: 3px solid $green  !important;
  }

  .hILaqb:nth-child(even)::after {
    border-bottom: 3px solid $green  !important;
  }

  .fxqodV:nth-child(even)::before {
    border-top: 3px solid $green  !important;
  }

  .hILaqb:nth-child(odd):not(:last-child)::after {
    border-top: 3px solid $green  !important;
  }

  .hILaqb:nth-child(even)::before {
    border-top: 3px solid $green  !important;
  }

  .backet-inner {
    margin: 8px 0;

    &.yellow-bracket {
      background-color: #FEFFCC;
    }

    .team-no-info {
      border-top: 3px solid rgb(150, 207, 4);
      border-right: 3px solid rgb(150, 207, 4);
      border-bottom: 3px solid rgb(150, 207, 4);
      border-left: none;
      border-image: initial;
      border-radius: 0 5px 5px 0;
      text-align: right;

      span {
        padding-right: 10px;
        color: #28263d !important;
        font-weight: 500;
      }
    }
  }

  .team-name {
    font-size: 14px;
    font-family: $HvRegular;
    color: #8392a5 !important;
  }

  .cigoYO {
    font-size: 14px;
    line-height: 21px;
    font-family: $HvRegular;
    color: #8392a5 !important;
    font-weight: 400;
    // text-align: right !important;
    background-color: $white  !important;
  }

  .hEKzS {
    justify-content: center;
  }

  .tornment {
    background-color: $white;
  }

  .no-data {
    font-family: $Hvmed;
    line-height: 29px;
    color: #28263D;
    margin-bottom: 15px;
    padding: 20px 20px 0;
    text-align: center;
    font-size: 18px !important;
  }

  &.new-bracket-chart {
    // padding: 20px 20px 10px;
    box-shadow: 0px 2px 4px #edecef;
    border-radius: 5px;

    .bracket-chart-table {
      display: flex;
      align-items: center;
      text-align: center;

      ul {
        margin-right: 15px;
      }
    }

    h2 {
      font-family: $Hvextrabold;
      font-weight: bold;
      line-height: 29px;
      color: #28263D;
      margin-bottom: 15px;
      padding: 20px 20px 0;
    }

    h3 {
      font-family: $Hvmed;
      font-style: normal;
      font-size: 14px !important;
      line-height: 26px;
      color: #28263D;
      margin: 0;
      background: #F7F8FB;
      border-top: 1px solid #E1E6F4;
      border-bottom: 1px solid #E1E6F4;
      border-right: 0;
      padding: 5px;
      margin-bottom: 20px;

      &:last-child {
        border-right: 1px solid #E1E6F4;
      }
    }
  }

  table {
    th {
      font-family: $Hvmed;
      font-style: normal;
      font-size: 14px !important;
      line-height: 26px;
      color: #28263D;
      margin: 0;
      background: #F7F8FB;
      border-top: 1px solid #E1E6F4;
      border-bottom: 1px solid #E1E6F4;
      border-right: 0;
      padding: 6px 15px;
      text-align: center;
      font-weight: 200;
    }

    td {
      padding: 15px;
      text-align: center;
    }
  }
}

.matchUp {
  .swal-footer {
    text-align: center !important;
  }

  .swal-text {
    text-align: center;
  }
}

.badge-notification {
  position: absolute;
  top: -5px;
  right: -5px;
  border-radius: 50%;
  background: red;
  color: white;
  width: 15px;
  height: 15px;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}




.edit_team_score_popup {

  // padding: 0 15px 15px;
  .user_popup_header {
    padding: 25px 10px;
  }

  .user_popup_content {
    padding: 0 10px;
  }

  .edit_score_tab {
    margin-bottom: 20px;

    .btn_green {
      min-width: 120px;
      padding: 5px;
      margin-right: 10px;
      max-width: 31.7%;
      width: 100%;

      @include mediaq(575px) {
        max-width: 100%;
        margin-bottom: 15px;
      }

      &.btn_white {
        background-color: #fff !important;
        color: #96CF04 !important;
      }
    }

    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .edit_team_score_table {

    // max-height: 290px;
    // overflow: auto;
    h2 {
      font-family: "Helvetica ExtraBold";
      font-size: 16px !important;
      padding-bottom: 10px;
    }

    .table-responsive-xl {
      margin-bottom: 20px;
      max-height: 550px;
      overflow: auto;
    }

    table {
      thead {
        tr {
          border-top: 1px solid #e1e6f4;
          border-bottom: 1px solid #e1e6f4;

          th {
            // min-width: 120px;
            padding: 10px;
            background: #f7f8fb;
          }
        }
      }

      tbody {
        td {
          padding: 10px;
          font-family: $Hvmed;
          line-height: 29px;
          color: #28263D;
        }

        tr {
          &:last-child {
            border-top: 0.5px solid #b3b3b378;
          }
        }
      }

      .btn {
        padding: 4px 9px;
      }

      .btn-success {
        color: $white;
        background-color: $green;
        border-color: $green;

      }

      .btn-danger {
        color: $white;
        background-color: #f86c6b;
        border-color: #f86c6b;
      }

      &:disabled {
        pointer-events: none;
      }
    }
  }
}

.standing_section {
  padding: 20px;

  .list {
    overflow-x: auto;
    overflow-y: hidden;
  }

}

.standing_team {

  // padding: 20px;
  .formate-checkbox {
    border: 1px solid #8392a540;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 12px;
    margin-bottom: 15px;
    cursor: move;
    display: flex;
    align-items: center;
    background: #f0f3f5;

    // &:last-child {
    //   margin-bottom: 10px;
    // }
    .checkbox {
      display: flex;
      width: 330px;

      label {
        font-family: $Hvmed;
        font-size: 16px;
        line-height: 21px;
        color: $fontText3;
        margin-left: 20px;
        margin-right: 10px;
        margin-bottom: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 330px;
      }
    }
  }

  .create_event_row {
    padding-bottom: 0;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }

    @include mediaq(1024px) {
      width: 110px
    }

    .form-group {
      input {
        height: 35px;
      }
    }
  }
}

.standing_tab {
  justify-content: unset;

  .select-type {
    display: block;
  }

  label {
    font-family: $Hvmed;
    font-size: 15px;
    line-height: 21px;
    color: #28263D;
  }
}

.standing_select_tab {
  margin-bottom: 20px;
  padding: 20px 20px;

  button {
    width: 250px;

    &.btn_white {
      background-color: #fff !important;
      color: #96CF04 !important;
    }

    @include mediaq(575px) {
      width: 100%;
      margin-bottom: 15px;
    }

  }
}

.eos_section {
  .standing_team {
    .formate-checkbox {
      cursor: default !important;
    }
  }
}

.not_found {
  font-family: $Hvmed;
  font-size: 15px;
  line-height: 21px;
  color: #28263D;
  text-align: center;
}

// ------ scheduling grid
.scheduling_table_plug {
  margin-top: 40px;

  &>table {

    // width: 100% !important;
    // margin-bottom: 0;
    thead {
      .ant-row-middle {
        display: none;
      }
    }

    // .scheduler-view {
    //     width: 100% !important;
    //     & > div:last-child{
    //       &::-webkit-scrollbar-track{
    //         box-shadow: none;
    //       }
    //       &::-webkit-scrollbar-thumb{
    //         background: transparent;
    //       }
    //     }
    // }
  }
}

.dc_scheduling_event.event-item {
  padding: 0 !important;
  display: flex;
  flex-wrap: wrap;
  .dc_scheduling_teamName{
    margin: 0px 30px;
    display: block;
    text-align: center;
    min-height: 40px;
    width: 100%;
    line-height: 1.2;
    color: #8392a5;
    padding: 30px 0px 5px 0;
    font-size: 13px;

    span {
      display: block;
      font-weight: bold;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  button{
    cursor: pointer;
    outline: none;
    background: transparent;
    border: 0;
    font-size: 0;
    position: absolute;
    top: 3px;
    right: 5px;
    width: 22px;
    height: 22px;
    &.remove_btn{
      background: url("/img/minus.png") no-repeat;      
    }
    &.lock_btn{
      background: url("/img/matchup-lock.svg") no-repeat 5px 0;  
    }
    &.swap_btn {
      left: 5px;
      right: auto;
      &::before{
        content: "\f0ec";
        font-family: 'FontAwesome';
        transform: rotate(90deg);
        position: absolute;
        top: 0;
        left: 0;
        font-size: 18px;
        color: #8392a5;
      }
    }
    &.dc_drag_event{
      position: absolute;
      right: 30px;
      top: 7px;
      &::before{
        content: "";
        position: absolute;
        right: 0px;
        top: 0px;
        width: 15px;
        height: 15px;
        background: url("/img/drag_icon.svg") no-repeat;
      }
    }
  }  
  .dc_scheduling_event_division{
    display: flex;
    justify-content: space-around;
    margin-bottom: 0;
    width: 100%;
    border-bottom: 1px solid #c1c3c9;
    border-top: 1px solid #c1c3c9;
    align-self: flex-end;
    span{
      width: 25%;
      display: inline-block;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      color: #28263d;
      padding: 5px;
      border-right: 1px solid #c1c3c9;
      vertical-align: top;
      &:first-child{
        width: 50%;
        // white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &:last-child {
        border-right: none;
      }
    }
  }
}

// ------ scheduling grid

// ------------- edit game state
.Toastify__toast-container {
  z-index: 99999 !important;
}

.editgamestate_sec {
  .table {
    td {
      font-size: 14px;
      font-family: $Hvmed;
      color: $fontText2;
      line-height: 20px;
      border: 0;
      padding: 15px;

      &:first-child {
        min-width: 240px;
      }

      &:not(:first-child) {
        text-align: center;
        min-width: 100px;
      }
    }

    .green_active {
      background: $green;
      color: $white;
    }
  }

  .bg-white {
    border-radius: 10px;
    box-shadow: 0 10px 10px 0px rgba(0, 0, 0, 0.05);
  }

  .editgame_table1 {
    .table {
      th {
        border-bottom: 1px solid #ededed;
        border-top: 0;
        color: #8392A5;

        &:not(:first-child) {
          text-align: center;
        }
      }
    }
  }
  .editgame_table2{
    .table{
      th{
        border-bottom: 1px solid #ededed;
        border-top: 0;
        color: #8392A5;

        &:not(:first-child) {
          text-align: center;
        }
      }

      td {
        border-bottom: 1px solid #ededed;
        position: relative;

        &:not(:first-child) {
          cursor: pointer;
        }

        >i {
          display: none;
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translate(0, -50%);
        }
      }

      .tr_active,
      .td-active {
        background: rgba(131, 146, 165, 0.2);
      }

      .green_active {
        background: $green;
        color: $white;

        >i {
          display: block;

        }
      }
    }
  }

  .editgame_label_input {
      display: flex;
      justify-content: space-between;
      padding: 15px 20px;
      align-items: center;
      .left_label {
        p{
          font-family: $Hvmed;
          font-size: 15px;
          color: $fontText2;
        }
        label{
          color: #8392A5;
          font-family: $Hvmed;
          font-size: 14px;
          margin: 0;
        }
      }
      .right_input {
          width: 130px;
          height: 100%;
          input {
            width: 100%;
            background: #6C9AB9;
            border: 0;
            color: $white;
            text-align: center;
            display: block;
            padding: 7px 0;
            border-radius: 30px;
            font-size: 15px;
            font-family: $Hvmed;
        }
      }
  }
  .scheduling-tab-group{
    display: block;
    text-align: center;
    @include mediaq(991px) {
      display: flex;
      flex-direction: column-reverse;
    }
    .sche_gamestate_tab{
      display: inline-block;
      vertical-align: top;
      margin: 0;

      li {
        display: inline-block;
        vertical-align: top;
        margin: 0 7px;

        button {
          border: 0;
          background: #fff;
          font-family: $Hvbold;
          font-size: 15px;
          padding: 10px 15px;
          border: 2px solid $green;
          border-radius: 7px;
          cursor: pointer;

          @include mediaq(420px) {
            font-size: 12px;
            padding: 10px 10px;
          }
        }

        &.active button {
          background: #96CF04;
          color: #fff;
        }
      }
    }

    .sche_game_btns {
      // float: right;
    }
  }

  button {
    outline: none !important;
  }
}

.swap_popup{
  max-height: 80%;
  overflow-y: auto;

  .gamestate_tab {
    display: flex;
    justify-content: center;
    margin: 20px 0 0;

    li {
      margin: 0 7px;

      button {
        border: 0;
        background: #fff;
        font-family: $Hvbold;
        font-size: 15px;
        padding: 10px 15px;
        border: 2px solid $green;
        border-radius: 7px;
        cursor: pointer;

        @include mediaq(420px) {
          font-size: 12px;
          padding: 10px 10px;
        }
      }

      &.active button {
        background: #96CF04;
        color: #fff;
      }
    }
  }

  .swap_btn_wrap {
    border-top: 1px solid #ededed;
    padding-top: 20px;

    @include mediaq(420px) {
      display: flex;
      flex-direction: column;
    }

    .btn_green {
      @include mediaq(420px) {
        margin: 0 0 15px !important;
      }
    }
    // .cancel_btn {
    //     border: 0;
    //     background: transparent;
    //     font-size: 15px;
    //     font-family: $Hvmed;
    //     color: $fontText3;
    // }
  }

  .swap_table_list {
      border: 1px solid #ededed;
      border-radius: 5px;
      max-height: 290px;
      overflow-y: auto;
      table {
        margin-bottom: 0;
        thead {
          th {
            border: 0;
            background: rgba(229,233, 242, 0.5);
            font-family: $Hvmed;
            font-size: 15px;
            color: $fontText3;
            font-weight: normal;
            &:nth-child(2){
              min-width: 160px;
            }
            &:nth-child(4){
              min-width: 120px;
            }
          }
        }
        td{
          font-family: $Hvmed;
          font-size: 15px;
          color: $fontText2;
          font-weight: normal;
          &.green{
            color: $green;
          }
        }
      }
  }

  .gamebtn_wrap {
    display: flex;
    justify-content: center;
    margin: 30px 0 10px;

    button {
      margin: 0 10px;
    }
  }

  .create_event_row {
    .col-md-6 {
      @include mediaq(767px) {
        margin: 0 0 25px;
      }

      &:last-child {
        @include mediaq(767px) {
          margin: 0 0 0px;
        }
      }
    }
  }

  button {
    outline: none !important;
  }

  .inner_popup{
    .create_event_row{
      .select_custom{
        .venue_select{
          > div{
            &:last-child{
              > div{
                max-height: 115px;
              }
            }
          }
        }
      }
    }
  }
}


// scheduling grid popup
.schdule_grid_common_table {
  .schdule_grid_tableTitle {
    margin: 20px 0 10px;
    color: #000;
    font-size: 16px;
  }

  .schdule_grid_dateTitle {
    margin: 0 0 5px;
  }

  .schdule_grid_ageTitle {
    color: #000;
    font-size: 16px !important;
    line-height: 1;
  }

  .table-responsive {
    max-height: 250px;
    border: 1px solid #ededed;
    border-radius: 5px;
    overflow-y: auto;

    table {
      margin: 0;
    }
  }
}

.schdule_grid_table {
  th {
    border: 0;
    background: rgba(229, 233, 242, .5);
    font-family: "Helvetica Medium";
    font-size: 15px;
    color: #6c7b95;
    font-weight: 400;
    min-width: 130px;
  }

  td {
    font-family: "Helvetica Medium";
    font-size: 13px;
    color: #28263d;
    font-weight: 400;
    padding: 0.9rem;
  }
}

.swal2-close:focus {
  box-shadow: none !important;
}

// Scheduling autogenerate popup
.scheduling_autogenerate_pop {
  select {
    cursor: pointer;
    outline: none;

    &[disabled] {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  .btn_wrap {
    border-top: 1px solid #ededed;
    padding-top: 20px;

    @include mediaq(420px) {
      display: flex;
      flex-direction: column;
    }

    .btn_gray {
      @include mediaq(420px) {
        margin: 0 0 15px !important;
      }
    }
  }
}

.sche_blank_data {
  width: 100%;
  text-align: center;
  margin: 0 0 10px;
  font-family: $Hvmed;
  line-height: 18px;
}


.scheduling_game_tooltip {
  >div {
    flex-direction: column !important;

    .header1-text {
      font-size: 20px !important;
    }

    .header2-text {
      // display: -webkit-box;
      -webkit-line-clamp: 1;
      // -webkit-box-orient: vertical;  
      // overflow: hidden;
    }

    >div {
      padding-right: 0 !important;
    }
  }

  >.game_details {
    display: flex;
    flex-direction: unset !important;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 10px;

    >.overflow-text {
      border-right: 1px solid #00000045;
      flex: unset;
      padding: 3px !important;
      display: flex;
      justify-content: center;
      width: 50%;
      margin-bottom: 5px;

      &:last-child,
      &:nth-child(2n) {
        border-right: unset;
      }

      >span {
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

input.form-control.dashboard_search {
  padding-right: 30px !important;
}

.scheduling_card_col.scheduling-event .scheduling_desc .status_info .status_table{
    height: auto;
    top: -120px;
    left: 30px;
    overflow: hidden;
    max-height: max-content;
    z-index: 999;
} 

.scheduling_card{
  overflow: initial;
}

.refreshed {
  background: rgb(173, 216, 230) !important;
}
