// / report page variable

$thbg: #eaeef5;
$tdbg: #f8f9fc;
$tblborder: #d1d5da;
$columnhover: #eff0f1;
$thcolor: #6b6b6f;
$reporttext: #182b49;
$btncolor: #4b4c4e;

.report_container {
    max-width: 930px;
    width: 100%;
    padding: 0 15px;
    margin: auto;
}
.diamond_logo {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.partnerlogo {
    max-width: 190px;
}
.header_outer {
    padding: 11px 0;
    background: $white;
    box-shadow: 0 8px 6px -6px rgba(223, 217, 219, 0.7);
}
.report_page {
    padding-bottom: 80px;
    section {
        margin-top: 30px;
        border-radius: 10px 10px 0 0;
        overflow: hidden;
    }
    .simple_table_heading {
        padding: 10px 30px;
        background: $tdbg;
        border: 1px solid $tblborder;
        border-radius: 5px 5px 0 0;
        border-bottom: 0;
        h3 {
            font-size: 15px;
            font-family: $Hvbold;
        }
        @include mediaq(768px) {
            padding: 10px 18px;
        }
    }
}
.report_user_wrapper {
    padding: 20px 30px;
    background: $white;
    .report_user {
        img {
            border-radius: 50%;
            object-fit: cover;
        }
    }
    .report_userdata {
        h4 {
            font-family: $Hvbold;
            color: $font-black;
            margin-bottom: 5px;
        }
        p {
            font-size: 15px;
            font-family: $HvRegular;
            color: $reporttext;
        }
    }
}
.report_tbl {
    .tbl_heading {
        background: $white;
        padding: 23px 30px;
        @include mediaq(768px) {
            padding: 15px 18px;
        }
        h2 {
            font-size: 24px;
            font-family: $Hvbold;
            @include mediaq(768px) {
                font-size: 20px;
            }
            @include mediaq(480px) {
                font-size: 16px;
            }
        }
    }
    table {
        border-collapse: collapse;
        border: 1px solid $tblborder;
        th,
        td {
            padding: 16px;
            border: 1px solid $tblborder;
            @include mediaq(768px) {
                padding: 12px 10px;
            }
        }
        th {
            background: $thbg;
            font-family: $Hvbold;
            font-size: 12px;
            line-height: 16px;
            color: $thcolor;
        }
        td {
            background: $tdbg;
            font-family: $HvRegular;
            font-size: 12px;
            line-height: 16px;
            color: $btncolor;
        }
        .th_large {
            th {
                font-size: 15px;
                line-height: 20px;
            }
        }
        .hover_column {
            td {
                position: relative;
                &:hover {
                    background-color: $columnhover;
                }
            }
        }
    }
    .table_verticle {
        table {
            th {
                background: $thbg;
            }
            td {
                background: $tdbg;
            }
        }
    }
    .table_horiz {
        table {
            th {
                background: $tdbg;
            }
            td {
                background: $tdbg;
            }
        }
        .th_report {
            th {
                background: $thbg;
            }
        }
    }
}
.twochart_wrapper {
    background: $white;
    padding: 30px 0;
    .dot_chart {
        max-width: 100%;
        padding: 10px 70px 10px 27px;
        position: relative;
        width: 50%;
        @include mediaq(768px) {
            width: 100%;
            padding: 10px 20px 10px 20px;
        }
        &::after {
            border-right: 1px solid $border_1;
            content: "";
            position: absolute;
            right: 0;
            height: 80%;
            top: 50%;
            transform: translateY(-50%);
            @include mediaq(768px) {
                border-right: 0px solid $border_1;
                border-bottom: 1px solid $border_1;
                transform: none;
                top: auto;
                bottom: 0;
                left: 0;
                width: 60%;
                height: 1px;
                margin: auto;
            }
        }
        &:last-child {
            &::after {
                height: 0;
                display: none;
            }
        }
    }
}

//  circle chart
.circle_chart {
    margin: auto;
}
.baseball_chart_img {
    // padding: 80px 0 0;
    img {
        width: 100%;
        max-width: 768px;
        margin: auto;
    }
}
