.uploadcsv-create {
    .white-bg {
        .multi-radio {
            display: flex;
            align-items: center;
            height: 45px;
            border-radius: 4px;
            margin-bottom: 15px;
            background-color: $inpbg;
            @include mediaq(767px) {
                display: block;
                margin-bottom: 0;
                height: 100%;
                padding: 8px 0;
            }
            @include mediaq(480px) {
                height: 100%;
            }
            .check_inputradio {
                width: 50%;
                padding: 12px 15px;
                height: 100%;
                display: inline-block;
                @include mediaq(767px) {
                    width: 50%;
                    padding: 8px 15px;
                    display: inline-block;
                }
                @include mediaq(575px) {
                    width: 100%;
                    padding: 8px 15px;
                    display: block;
                }
                label {
                    margin-bottom: 0;
                    @include mediaq(767px) {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

//  Uploadcsv datasource css
.uploadcsv-datasource {
    .white-bg .info-wrap {
        padding: 10px 0 20px;
    }
    .icon-input {
        display: flex;
        align-items: center;
        input {
            margin-right: 8px;
        }
        img {
            height: 35px;
            cursor: pointer;
        }
    }
}
//  Uploadcsv Link data css
.uploadcsv-linkdata {
    .tableinfo {
        padding: 25px;
    }
    .select_team{
        padding: 0 25px 25px;
        display: flex;
        align-items: center;
        @include mediaq(480px) {
            display: block;
        }
        .team_title{
            width: 30%;
            margin-bottom: 10px;
            @include mediaq(480px) {
                width: 100%;
            }
        }
        .team-selectbox{
            width: 70%;
            margin-bottom: 10px;
            @include mediaq(480px) {
                width: 100%;
            }
        }
    }
    .btn {
        margin-top: 30px;
        padding: 10px 36px;
    }
}

//  Uploadcsv eventmodal css
.uploadcsv-eventmodal {
    .tableinfo {
        padding: 25px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include mediaq(350px) {
            display: block;
        }
        .select-timestamp {
            width: 35%;
            @include mediaq(480px) {
                width: 50%;
            }
            @include mediaq(575px) {
                width: 50%;
            }
            @include mediaq(350px) {
                width: 100%;
                margin-top: 10px;
            }
        }
    }
    .btn {
        margin-top: 30px;
        padding: 10px 36px;
    }
    .file_custom_upload {
        height: auto;
        padding: 0px;
        border: 0px;
        background-color: transparent;
        color: $blue_btn;
        text-decoration: underline;
        text-align: left !important;
        .preview_img {
            width: 73px;
        }
    }
    .fileview {
        display: flex;
        align-items: center;
        span {
            display: grid;
            color: $blue_btn;
        }
        .fileaudio {
            float: left;
            margin-right: 5px;
            margin-top: 5px;
            color: $fontText3;
        }
        .filedelate{
            i{
                color: $blue_btn;
                margin-left: 5px;
            }
        }
    }
    .new_add_file {
        padding: 0 25px;
        width: 35%;
        @include mediaq(1599px) {
            width: 50%;
        }
        @include mediaq(1429px) {
            width: 70%;
        }
        @include mediaq(991px) {
            width: 100%;
        }
        .selectfile {
            margin-right: 10px;
            .file_custom_upload {
                height: 100%;
                text-decoration: none;
            }
            p {
                margin-top: 0;
            }
        }
    }
    .table-responsive-xl {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        margin-bottom: 15px;
        .ant-table {
            table {
                table-layout: unset !important;
            }
        }
    }
    .uploadradio-button {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 15px;
        flex-wrap: wrap;
        .round-radio {
            margin-right: 20px;
            margin-bottom: 15px;
            @include mediaq(991px) {
                margin-right: 15px;
            }
        }
        .form-multiradio {
            height: 100%;
            flex-wrap: wrap;
        }
    }
    .select_width {
        min-width: 160px;
        white-space: unset !important;
    }
    th {
        white-space: normal !important;
    }
}
//  Uploadcsv uploadvideo css
.uploadcsv-uploadvideo {
    .white-bg {
        .info-wrap {
            padding: 10px 0 40px;
            .selectfile {
                padding: 7px;
                .file_custom_upload {
                    padding: 6px 25px;
                }
            }
        }
    }
}
//  Uploadcsv uploadcsv data-1  css
.uploadcsv-uploadcsvdata {
    .table-responsive-xl {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    &.table_scroll {
        .table-responsive-xl {
            display: block;
            width: 100%;
            overflow: auto;
            -webkit-overflow-scrolling: touch;
            height: 580px;
        }
    }
    .tableinfo {
        padding: 25px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include mediaq(350px) {
            display: block;
        }
        .filter-deta{
            span{
                background: $linkcolor;
                color: $white;
                font-size: 16px;
                font-weight: normal;
                margin-right: 5px;
                cursor: pointer;
                white-space: normal;
                i{
                    margin-left: 4px;
                }
            }
        }
    }
    .btn-addnewevent {
        margin-top: 0px;
        padding: 10px 12px;
        background-color: $linkcolor;
        color: $white;
        @include mediaq(350px) {
            margin-top: 10px;
        }
    }
    table {
        th {
            width: 22%;
        }
        td {
            // &:nth-child(3) {
            //     border-right: 0px;
            // }
            &.common_btn {
                display: inline-flex;
                width: 100%;
                align-items: center;
                justify-content: center;
                @include mediaq(767px) {
                    min-width: 230px;
                }
            }
            &.btnleft {
                justify-content: left;
            }
        }
    }
    .action_btn {
        padding: 8px 25px;
        margin-bottom: 0;
        line-height: 1.5;
        &.btn_bg {
            padding: 6px 15px;
            @include mediaq(767px) {
                font-size: 12px;
                padding: 12px 12px;
            }
        }
        &.btn_lightgray {
            padding: 6px 15px;
            color: $blue_btn;
            margin: 0 14px;
            @include mediaq(767px) {
                font-size: 12px;
                padding: 12px 12px;
                margin: 0 12px;
            }
        }
        &.red_btn {
            padding: 6px 15px;
            color: $remove;
            background-color: #ffe0e0;
            text-decoration: none;
            @include mediaq(767px) {
                font-size: 12px;
                padding: 12px 12px;
            }
            &:hover {
                color: $white;
                background-color: $remove;
            }
        }
    }
}
// chart-not-found
.chart-not-found {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 400px;
    p {
        color: $black;
        font-weight: 600;
        font-size: 20px;
    }
}


.filterinfo{
    .container{
        max-width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    }
    .badge {
        background: #02A8A8;
        color: #fff;
        font-size: 16px;
        font-weight: normal;
        margin-right: 5px;
        cursor: pointer;
        white-space: normal;
    }
    .date_filter{
        .r-label{
            color: $dropdowntext;
            font-family: $HvRegular;
        }
    }
    .react-datepicker-popper,.css-26l3qy-menu{
        z-index: 9;
      }
}