@charset "utf-8";
/* CSS Document */
article,aside,details,figcaption,figure,footer,header,hgroup,main,nav,section,summary{display:block;}
body, ol, ul, li, div, p, span, form, h1, h2, h3, h4, h5, h6, table, thead, th, tbody, tr, td, img, input, textarea, dd, dt, dl{word-wrap:break-word;outline:none;padding:0;margin:0;-webkit-tap-highlight-color:rgba(0, 0, 0, 0);}
textarea{overflow:auto;-webkit-font-smoothing:antialiased; -moz-osx-font-smoothing:grayscale;}
h1, h2, h3, h4, h5, h6, p, ul, ol, dd, dt, dl{-webkit-margin-before:0px;-webkit-margin-after:0px;-webkit-margin-start:0px;-webkit-margin-end:0px;}
a img, img{border:none;outline:none;}
// input[type=submit], input[type=reset], input[type=button], button{ -webkit-appearance:none; -moz-appearance:none;-ms-appearance:none; appearance:none; }
// form input[type='radio'], form input[type='checkbox']{width: 0;height: 0;}
a{cursor:pointer;text-decoration:none;outline:0;}
.no-script-msg{font:12px Arial, verdana, Helvetica, sans-serif;background:#FFFFCC;width:auto;padding:10px 10px 10px 25px;margin:8px;border:1px solid #CD3D18;font-weight:bold;height:auto;font-size:11px;color:#000000;line-height:150%;clear:both;}
// input{-webkit-font-smoothing:antialiased; -moz-osx-font-smoothing:grayscale; font-family: 'arial', sans-serif; -webkit-appearance:none; }
body{ min-width:300px; -webkit-font-smoothing:antialiased; -moz-osx-font-smoothing:grayscale; -ms-font-smoothing:antialiased; background-color: #fff;}
::selection {text-shadow: none;background: rgba(65,131,196,0.4);}
.clear{clear:both; height:0px; overflow:hidden; width:auto; display:block; float:none !important;}
ul, menu, dir {margin:0px;padding:0px;display: block;list-style-type: disc;-webkit-margin-before: 0;-webkit-margin-after: 0;-webkit-margin-start: 0;-webkit-margin-end: 0;-webkit-padding-start: 0;}
*, *:after , *:before { -moz-box-sizing:border-box; -webkit-box-sizing:border-box; box-sizing:border-box;}
li{list-style: none;}
// 
body p { margin-bottom: 0; }


//font-family: 'Roboto', sans-serif;

@font-face {
    font-family: 'Helvetica Regular';
    src:url('/fonts/HelveticaNowText-Regular.woff2') format('woff2'),
        url('/fonts/HelveticaNowText-Regular.woff') format('woff');
        font-weight: normal;
        font-style: normal;
}
@font-face {
    font-family: 'Helvetica Medium';
    src:url('/fonts/HelveticaNowText-Medium.woff2') format('woff2'),
        url('/fonts/HelveticaNowText-Medium.woff') format('woff');
}
@font-face {
    font-family: 'Helvetica Bold';
    src:url('/fonts/HelveticaNowText-Bold.woff2') format('woff2'),
        url('/fonts/HelveticaNowText-Bold.woff') format('woff');
        font-weight: normal;
        font-style: normal;
}
@font-face {
    font-family: 'Helvetica ExtraBold';
    src:url('/fonts/HelveticaNowText-ExtraBold.woff2') format('woff2'),
        url('/fonts/HelveticaNowText-ExtraBold.woff') format('woff');
        font-weight: normal;
        font-style: normal;
}

*,
body {
    margin: 0;
    padding: 0;
}
body{
    background-color: $bodyBg !important;
}
h1 {
    font-size: 22px !important;
    @include mediaq(1200px) {
        font-size: 20px !important;
    }
    @include mediaq(480px) {
        font-size: 16px !important;
    }
}
h2 {
    font-size: 20px  !important;
    @include mediaq(1200px) {
        font-size: 16px !important;
    }
    @include mediaq(991px) {
        font-size: 16px  !important;
    }

}
h3 {
    font-size: 18px  !important;
    @include mediaq(1200px) {
        font-size: 16px !important;
    }
}
h4 {
    font-size: 15px  !important;
}
img {
    max-width: 100%;
    display: block;
}
table{width: 100%;}
// p,span{
//     font-size: 14px;
// }
.mb_50{
    margin-bottom: 50px;
}
.mb_25{
    margin-bottom: 25px;
}
.mb_15{
    margin-bottom: 15px;
}
.mb_10{
    margin-bottom: 10px;
}
.mb_5{
    margin-bottom: 5px;
}
.bg_white{
    background: $white;
}
.bg_green{
    background: $green;
    color: $white;
    &:hover{
        opacity: 0.7;
    } 
}
.bg_link{
    background: $linkcolor !important;
    color: $white;
    &:hover{
        opacity: 0.7;
    }
}
.bg_red{
    background: $error !important;
    color: $white !important;
    &:hover{
        opacity: 0.7;
    }
}
.btn_bg{
    background: $linkcolor !important;
    border:1px solid $linkcolor !important;
    min-width: 130px;
    display: inline-block;
    text-align: center;
    color: $white;
    padding: 12px;
    border-radius: 4px;
        cursor: pointer;
        font-size: 16px;
        line-height: 21px;
        font-family: $Roboto;
        // box-shadow:  0 10px 15px rgba(70,181,209,0.1);
        transition: 0.3s ease;
        &:hover {
            opacity: 0.7;
            color: $white;
            text-decoration: none;
            box-shadow: 0 10px 15px rgba(70, 181, 209, 0.2);
        }
        &.disabled {
            background-color: #e4e7ea !important;
            cursor: not-allowed;
            color: #6c7b95;
            border: 1px solid #e4e7ea !important;
        }
    }
    
    .btn_gray {
        background: $lightgray6 !important;
    border:1px solid $lightgray6 !important;
    min-width: 130px;
    display: inline-block;
    text-align: center;
    color: $white;
    padding: 12px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    line-height:21px;
    font-family: $Roboto;
    // box-shadow:  0 10px 15px rgba(70,181,209,0.1);
    transition: 0.3s ease;
    &:hover{
        opacity: 0.7;
        color: $white;
        text-decoration: none;
        box-shadow:  0 10px 15px rgba(70,181,209,0.2);
    }
}
.btn_blue{
    background:$blue_btn !important;
    border:1px solid$blue_btn !important;
    min-width: 130px;
    display: inline-block;
    text-align: center;
    color: $white;
    padding: 12px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    line-height:21px;
    font-family: $Roboto;
    // box-shadow:  0 10px 15px rgba(70,181,209,0.1);
    transition: 0.3s ease;
    &:hover{
        opacity: 0.7;
        color: $white;
        text-decoration: none;
        box-shadow:  0 10px 15px rgba(70,181,209,0.2);
    }
}
.btn_bg2{
    background-color: $blue_btn !important;
    border:1px solid $blue_btn !important;
    min-width: 130px;
    display: inline-block;
    padding: 12px;
    color: $white;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
    font-size: 16px;
    line-height:21px;
    font-family: $Roboto;
    box-shadow:  0 10px 15px rgba(70,181,209,0.2);
    transition: 0.3s ease;
    &:hover{
        opacity: 0.7;
        color: $white;
        text-decoration: none;
        box-shadow:  0 10px 15px rgba(70,181,209,0.2);
    }
}
.btn_green{
    background-color: $green !important;
    border:1px solid $green !important;
    color: $white !important;
    display: inline-block;
    text-align: center;
    min-width: 130px;
    padding: 12px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    line-height:21px;
    font-family: $Roboto;
    transition: 0.3s ease;
    &:hover{
        opacity: 0.7;
        color: $white;
        text-decoration: none;
        box-shadow:  0 10px 15px rgba(70,181,209,0.2);
    }
}
.light_danger_btn{
    background-color: $light_errorBg !important;
    border:1px solid $light_errorBg !important;
    color: $white !important;
    display: inline-block;
    text-align: center;
    padding: 12px;
    border-radius: 4px;
    min-width: 130px;
    cursor: pointer;
    font-size: 16px;
    line-height:21px;
    font-family: $Roboto;
}

.overflow_auto{
    overflow: auto;
    // box-shadow:  0 10px 15px rgba(70,181,209,0.2);
    
}
.error{
    color: $error !important;
}
.drag_icon{
    width: 12px;
    height: 20px;
    @include display_flex;
    @include justify_sp;
    @include flex_colum;
    cursor: all-scroll;

    p{
        @include display_flex;
        @include justify_sp;
        @include flex_colum;
        height: 100%;
    }
    span{
        height: 4px;
        width: 4px;
        display: block;
        border-radius: 50%;
        background: $graydark;
    }
}
.add_new_btn{
    p{
        padding: 11px 15px;
        color: $blue_btn;
        border-width:2px;  
        border-style:dashed;
        font-size: 16px;
        font-family: $HvRegular;
        margin-bottom: 10px;
        background: $white;
        cursor: pointer;
        i{
            margin-right: 10px;
        }
    }
}
.select_custom {
    position: relative;
    select {
        -webkit-appearance: none;
        width: 100%;
        padding: 9px 35px 9px 11px;
        border-radius: 4px;
        border: 1px solid $border_1;
        font-size: 18px;
        line-height: 26px;
        font-family: $HvRegular;
        option {
            &:disabled{
                color: $placeHolder;
            }
        }
    }
    .angle_down {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 18px;
        color: $dropdowntext;
        pointer-events: none;
    }
    .css-1wa3eu0-placeholder{
        font-size: 16px;
        color: $fontText3;
        line-height: 26px;
        font-family: $HvRegular;
    }
}
.common_time_icon{
    position: relative;
    &:before{
        background: url("/img/clock_icon.svg") no-repeat;
        background-size: 100% 100%;
        display: inline-block;
        width: 17px;height: 17px;
        content: "";
        margin-right: 10px;
    }
}
.common_date_icon{
    position: relative;
    &:before{
        background: url("/img/date_icon.svg") no-repeat;
        background-size: 100% 100%;
        display: inline-block;
        width: 17px;height: 17px;
        content: "";
        margin-right: 10px;
    }
}
.common_link_icon{
    position: relative;
    &:before{
        background: url("/img/link_icon.svg") no-repeat;
        background-size: 100% 100%;
        display: inline-block;
        width: 17px;height: 17px;
        content: "";
        margin-right: 10px;
    }
}
.common_click_icon{
    position: relative;
    &:before{
        background: url("/img/click_icon.svg") no-repeat;
        background-size: 100% 100%;
        display: inline-block;
        width: 14px;height: 20px;
        content: "";
        margin-right: 10px;
    }
}

// scroll Bar css

::-webkit-scrollbar {
    width: 5px;
    height: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px $darkgray; 
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background:$fontText3; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $fontText4; 
  }


  .btn_lightblue{
    background: transparent !important;
    border:1px solid $linkcolor !important;
    min-width: 130px;
    display: inline-block;
    text-align: center;
    color: $linkcolor;
    padding: 12px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    line-height:21px;
    font-family: $Roboto;
    // box-shadow:  0 10px 15px rgba(70,181,209,0.1);
    transition: 0.3s ease;
    &:hover{
        opacity: 0.7;
        color: $linkcolor;
        text-decoration: none;
        box-shadow:  0 10px 15px rgba(70,181,209,0.2);
    }
}

.btn_lightgray{
    background: $inpbg !important;
    border:1px solid $inpbg !important;
    min-width: 130px;
    display: inline-block;
    text-align: center;
    color: $fontText2;
    padding: 12px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    line-height:21px;
    font-family: $Roboto;
    // box-shadow:  0 10px 15px rgba(70,181,209,0.1);
    transition: 0.3s ease;
    &:hover{
        opacity: 0.7;
        color: $fontText2;
        text-decoration: none;
    }
}

.btn_bg2_transparent{
    background-color: transparent !important;
    border:1px solid $blue_btn !important;
    min-width: 130px;
    display: inline-block;
    padding: 12px;
    color: $blue_btn;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
    font-size: 16px;
    line-height:21px;
    font-family: $Roboto;
    box-shadow:  0 10px 15px rgba(70,181,209,0.2);
    transition: 0.3s ease;
    &:hover{
        opacity: 0.7;
        color: $blue_btn;
        text-decoration: none;
        box-shadow:  0 10px 15px rgba(70,181,209,0.2);
    }
}

.btn-delate{
    padding: 12px;
    border: 1px;
    border-radius: 4px;
    &:focus{
        outline: none;
    }
}

input[type="checkbox"]{
    -webkit-appearance: auto;
    appearance: auto;
}