.drag_list {
  .input_contents_wrap {
    width: calc(100% - 37px);
  }

  .input_contents {
    width: 100%;
    margin-bottom: 25px;

    &:last-child {
      margin-bottom: 0;
    }

    .input_contents_title_row {
      margin-bottom: 7px;

      h4 {
        margin-bottom: 0;
        font-family: $Hvbold;

        i {
          cursor: pointer;
          color: $graydark;
        }
      }

      span {
        color: $remove;
        cursor: pointer;
      }
    }

    .form-group {
      .form-control {
        padding: 11px;
        height: auto;
      }
    }
  }

  .drag_icon {
    margin-right: 13px;
  }

  .drag_item_list {
    background: $white;
    border-radius: 4px;
    padding: 20px 30px 14px 13px;
    margin-bottom: 10px;
    box-shadow: 0 2px 2px rgba(112, 112, 112, 0.1);
  }
}

.title_editable {
  input {
    margin-right: 15px;
    font-size: 15px;
    font-family: $Hvbold;
    transition: 0.2s ease;
    border-radius: 4px;
    border: 1px solid $border_1;
    padding: 5px;

    &:read-only {
      border: none;
    }
  }

  i {
    cursor: pointer;
    font-size: 16px;
    color: $graydark;
  }
}

.file_input {
  position: relative;
  border: dashed 1px $border_1;
  background: $file_bg;

  .image_upload_wrap {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;

    &.active {
      background: $white;
    }

    &.banner_upoad_img {
      img {
        // width: 100%;
        // object-fit: cover;
      }
    }
  }

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: auto;
    width: auto;
    max-width: 100%;
    max-height: 100%;
  }

  padding: 100px;
  text-align: center;

  &.edit_profile_image {
    padding: 40px 20px;
  }

  i {
    font-size: 40px;
    color: $linkcolor;
  }

  p {
    color: $fontText3;
    font-size: 16px;
    font-family: $HvRegular;
    opacity: 0.5;
  }

  .img_file_input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}

._react_fileupload_form_content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  input[type="submit"] {
    display: none;
  }
}

.tag_cat {
  position: relative;
  padding: 10px 25px 10px 10px;
  text-align: center;
  border-radius: 4px;
  margin-bottom: 10px;
  border: 1px solid $border_light;

  p {
    font-size: 16px;
    color: $fontText3;
    font-family: $HvRegular;

    i {
      margin-right: 5px;
      font-size: 16px;
    }
  }

  .close_tag {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    cursor: pointer;

    i {
      color: $fontText3;
    }
  }

  &.add_new_tag {
    border: dashed 1px $border_light;
    padding: 10px 5px;
    cursor: pointer;

    p {
      @include display_flex;
      @include align_center;
      @include just_center;
    }
  }
}

.number_step {
  @include mediaq(420px) {
    margin-bottom: 15px;
  }

  ul {
    @include display_flex;

    li {
      position: relative;
      width: 65px;

      span {
        width: 40px;
        height: 40px;
        cursor: pointer;
        background: $lightgray;
        border-radius: 50%;
        @include display_flex;
        @include align_center;
        @include just_center;
        font-size: 18px;
        font-family: $Hvmed;
        color: $darkgray;
        box-shadow: 0 -1px 0px rgba(0, 0, 0, 0.1);
        position: relative;
        // z-index: 3;
      }

      &.active {
        &:before {
          background: $green;
        }

        span {
          color: $white;
          background: $green;
        }
      }

      &:before {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 50%;
        height: 6px;
        background: $lightgray;
        left: -30px;
        z-index: -1;
      }

      &:after {
        // content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        height: 6px;
        background: $lightgray;
        right: 0;
      }

      &:first-child {

        &:after,
        &:before {
          content: "";
          display: none;
        }
      }
    }
  }
}

// Add new Field Question List Popup
// .check_row {
//     display: block;
//     position: relative;
//     padding: 12px 0 12px 45px;
//     border-bottom: 1px solid $lightgray;
//     cursor: pointer;
//     font-size: 22px;
//     -webkit-user-select: none;
//     -moz-user-select: none;
//     -ms-user-select: none;
//     user-select: none;
//     .drag_list {
//         pointer-events: none;
//         user-select: none;
//         .drag_icon {
//             display: none;
//         }
//         .file_input {
//             padding: 50px;
//         }
//         .form-group {
//             input {
//                 background: $lightgray2;
//             }
//         }
//     }
//     &:last-child {
//         border: none;
//     }
//     input[type="checkbox"] {
//         position: absolute;
//         opacity: 0;
//         cursor: pointer;
//         top: 0;
//         bottom: 0;
//         height: 100%;
//         width: 100%;
//         left: 0;
//         z-index: 3;
//         &:checked ~ .checkmark {
//             background-color: $green;
//         }
//         &:checked ~ .checkmark:after {
//             display: block;
//         }
//     }
//     .checkmark {
//         position: absolute;
//         top: 50%;
//         left: 0;
//         height: 25px;
//         width: 25px;
//         transform: translateY(-50%);
//         background-color: $lightgray2;
//         &:after {
//             font-size: 15px;
//             left: 50%;
//             top: 50%;
//             transform: translate(-50%, -50%);
//             content: "\f00c";
//             position: absolute;
//             font-family: $FontAwesome;
//             color: $white;
//         }
//         &:hover {
//             &input ~ .checkmark {
//                 background-color: #ccc;
//             }
//         }
//     }
// }

.form-group {
  position: relative;

  .Edit_input {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    z-index: 2;
    cursor: pointer;

    i {
      margin-left: 15px;
    }
  }
}

.colorbox_wrapper {
  height: 33px;
  /* padding: 12px 40px 12px 12px; */
  border: 1px solid $border2;
  width: calc(100% - 50px);
}

.input_field {
  position: relative;
  border: 1px solid $border_1;
  padding: 5px;
  border-radius: 4px;

  input {
    width: calc(100% - 50px);
    padding: 6px 30px 6px 10px;
    font-size: 16px;
    border: 1px solid $border_1;
    border-radius: 4px;

    &:read-only {
      border: none;
    }
  }

  .Edit_input {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    z-index: 2;
    cursor: pointer;

    i {
      margin-left: 10px;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}

.record_wrapper {
  border: 1px solid $border_1;

  .record_input {
    width: 50%;
    position: relative;
    margin: 5px;

    &:first-child {
      input {
        // border-right: none;
      }
    }

    &:last-child {
      input {
        // border-left: none;
        text-align: right;
        padding-right: 35px;
      }
    }

    input {
      border: none;
      width: 100%;
      padding: 6px 30px 6px 10px;
      border: 1px solid $border_1;

      &:read-only {
        border: none;
      }
    }
  }

  .edit_record {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
}

.custom_dropdown {
  p {
    font-size: 16px;
    font-family: $HvRegular;
    color: $fontText3;
  }

  .first_option {
    border: 1px solid $border_1;
  }

  .dropdown_inner {
    position: relative;

    input {
      padding: 5px 50px 5px 5px;
      width: calc(100% - 70px);
      font-size: 16px;
      margin: 5px;
      line-height: 26px;
      font-family: $HvRegular;
      color: $fontText3;
      border: 1px solid $border_1;
    }

    input:read-only {
      border: none;
    }

    .dropdown_action {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);

      i {
        margin-left: 10px;
        font-size: 16px;
        color: $fontText3;
        cursor: pointer;
      }
    }
  }

  ul {
    // display: none;
    border: 1px solid $border_1;
    margin-top: -1px;
    height: 0;
    opacity: 0;
    visibility: hidden;
    transition: 0.2s ease;

    li {}

    &.show {
      height: auto;
      max-height: 200px;
      overflow: auto;
      visibility: visible;
      opacity: 1;
    }
  }

  .add_new_btn {
    padding: 0 10px;
  }
}

.college_wrapper {
  .college_box {
    margin-right: 7px;
    margin-bottom: 7px;
    width: 107px;
    height: 107px;
    border: 1.5px solid $border2;
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;

    img {
      width: auto;
      max-width: 100%;
      margin: 0 auto;
      display: block;
    }

    i {
      color: $graydark;
      font-size: 18px;
    }

    &.college_box_add {
      border: 1.5px dashed $border2;

      .add_college_btn {
        text-align: center;

        p {
          font-size: 14px;
          color: $graydark;
          font-family: $HvRegular;
        }
      }
    }
  }
}

// Select position css
.selectPosition_wrapper {
  .selectpositionpic {
    position: relative;
    width: 100%;
    max-width: 410px;
    border: 1px solid $border_light;
    border-radius: 4px;

    img {
      max-width: 295px;
      width: 100%;
      height: 275px;
      object-fit: cover;
    }

    i {
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
      color: $graydark;
      font-size: 16px;
      cursor: pointer;
    }
  }

  .editPosition {
    margin-left: 15px;

    i {
      color: $graydark;
      font-size: 16px;
      cursor: pointer;
    }
  }
}

// Create New event page
.container_900 {
  width: 100%;
  max-width: 920px;
  padding: 0 10px;
  margin: auto;

  .inner_container900 {
    padding: 30px;
    border-radius: 4px;

    @include mediaq(768px) {
      padding: 10px 15px;
    }
  }
}

.create_event_title {
  padding-bottom: 30px;
  // margin-bottom: 30px;
  // border-bottom: 1px solid $border_light;
  margin: auto;

  p {
    font-size: 16px;
    line-height: 26px;
    font-family: $HvRegular;
    color: $graydark;
  }
}

.ab_loader {
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  z-index: 2;
  @include display_flex;
  @include align_center;
  @include just_center;
  height: 100%;
  z-index: 99999;

  svg {
    width: 40px;
    height: 40px;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
  }
}

.ab_loader_abs {
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  z-index: 2;
  @include display_flex;
  @include align_center;
  @include just_center;
  height: 100%;
  z-index: 99999;

  svg {
    width: 40px;
    height: 40px;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
  }
}

.create_event_row {
  .rc-time-picker {
    display: block;
  }

  .select_custom {
    select {
      font-size: 16px;
      color: $fontText3;

      &.bg_inp {
        background: $inpbg;
      }
    }

    #search_input {
      background: url("/img/caret_dropdown.svg") no-repeat;
      background-position: 97%;
    }
  }

  .add_new_btn {
    p {
      margin-bottom: 0;
    }
  }

  padding-bottom: 25px;

  &:last-child {
    margin-bottom: 0;
  }

  .form-group {
    margin-bottom: 0;
  }

  .date_icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }

  .react-datepicker-wrapper {
    display: block;
  }

  .button_type {
    width: 100%;
    outline: none;
    color: $fontText3;
    border-radius: 4px;
    border: 1px solid $border_1;
    background: none;
    font-size: 16px;
    font-family: $HvRegular;
    cursor: pointer;
    padding: 9px;
    position: relative;

    &.active {
      background-color: $blue_btn;
      color: $white;
      border: 1px solid $blue_btn;
    }

    &.disabled {
      background-color: #e4e7ea;
      cursor: not-allowed;
    }
  }

  &:last-child {
    padding-bottom: 0;
  }

  .file_input {
    padding: 92px 15px;
  }

  .image_side_preview {
    .image_upload_wrap {
      border: 1px solid $border_1;
      position: relative;
      height: 250px;

      .remove_img {
        font-size: 12px;
        cursor: pointer;
        position: absolute;
        right: -5px;
        top: -10px;
        width: 20px;
        height: 20px;
        background: $error;
        border-radius: 50%;
        color: $white;
        z-index: 2;
      }

      img {
        max-width: 100%;
        margin: auto;
        max-height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      img {
        max-width: 100%;
        margin: auto;
        max-height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  label {
    font-size: 15px;
    line-height: 20px;
    color: $fontText2;
    font-family: $Hvbold;

    @include mediaq(480px) {
      font-size: 14px;
    }
  }

  input {
    font-size: 18px;
    font-family: $HvRegular;
    color: $dropdowntext;

    @include mediaq(480px) {
      font-size: 14px;
    }

    &::-webkit-input-placeholder {
      color: $placeHolder;
      font-size: 16px;
      font-family: $HvRegular;

      @include mediaq(480px) {
        font-size: 14px;
      }
    }

    &.bg_inp {
      background: $inpbg;
    }

    &:disabled {
      background: $border_1;
    }
  }

  input[type="number"] {
    padding: 8px;
    font-family: $HvRegular;
    height: auto;
    border: 1px solid $border_1;
    border-radius: 4px;
    width: 100%;
    color: $fontText3;

    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  input[type="text"],
  input[type="password"] {
    padding: 8px;
    height: auto;
    border: 1px solid $border_1;
    border-radius: 4px;
    width: 100%;
  }

  input[type="file"] {
    font-size: 14px;
  }

  .chk_box {
    width: 100%;

    input[type="checkbox"] {
      display: none;

      &:checked~label {
        background: $blue_btn;
        color: $white;
      }
    }

    label {
      padding: 11px;
      border: 1px solid $border_1;
      display: block;
      border-radius: 4px;
      position: relative;
      cursor: pointer;
      font-family: $HvRegular;
      color: $fontText3;

      &.disabled {
        background-color: #ededed;
        color: #6C7B95;
        cursor: not-allowed;
      }
    }
  }

  .custom_radio {
    .radio_container {
      display: inline-block;
      position: relative;
      padding-left: 20px;
      margin-bottom: 12px;
      margin-top: 10px;
      margin-left: 20px;
      cursor: pointer;
      font-size: 17px;

      &:first-child {
        margin-left: 0;
      }

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;

        &:checked~.radiobtn {
          background: $green;

          &::after {
            display: block;
            transition: 0.2s ease;
            background: $white;
          }
        }
      }

      .radiobtn {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        height: 12px;
        width: 12px;
        background-color: $fontText4;
        border-radius: 50%;

        &:after {
          top: 50%;
          left: 50%;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          transition: 0.2s ease;
          content: "";
          position: absolute;
          transform: translate(-50%, -50%);
        }
      }
    }

    &.custom_radio2 {
      .radio_container {
        padding-left: 25px;
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 0;
        margin-right: 20px;

        &:last-child {
          margin-right: 0;
        }

        input {
          &:checked~.radiobtn {
            background: none;
          }

          &:checked~.radiobtn {
            &::after {
              background: $green;
              width: 10px;
              height: 10px;
            }
          }
        }

        .radiobtn {
          background: none;
          border: 1px solid $fontText2;
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  textarea {
    padding: 11px;
    height: auto;
    border: 1px solid $border_1;
    border-radius: 4px;
    width: 100%;
    font-size: 18px;
    font-family: $HvRegular;
    color: $fontText3;
    resize: none;

    &::-webkit-input-placeholder {
      color: $placeHolder;
      font-size: 16px;
      font-family: $HvRegular;
    }

    &.bg_inp {
      background: $inpbg;
    }
  }

  .custom_rangpicker {
    .input-range {}

    .input-range__track--active {
      background: $green;
      height: 6px;
    }

    .input-range__slider {
      background: $green;
      border: 4px solid $white;
      box-shadow: 0 2px 10px rgba(229, 233, 242, 1);
    }

    .input-range__label--value {
      bottom: -35px;
      top: auto;
      z-index: 0;

      .input-range__label-container {
        background: $green;
        color: $white;
        padding: 5px 8px;
        border-radius: 3px;

        &:before {
          position: absolute;
          content: "";
          width: 0;
          height: 0;
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;
          border-bottom: 14px solid $green;
          top: -7px;
          left: 0;
          right: 0;
          margin: auto;
        }
      }
    }

    .input-range__slider-container{
      z-index: 1;
      top: 5px;
    }
  }

  div[data-radium="true"] {
    color: $white; // background: $green !important;
    flex-direction: row-reverse;
    border: 1px solid $border_1 !important;

    div[data-radium="true"] {
      background: $green !important;
      font-size: 16px;
      font-family: $HvRegular;
    }
  }

  div[role="combobox"] {
    li {
      color: $black;
    }
  }

  .rating_row {
    span {
      line-height: 1;
      display: block;
      margin-top: 5px;
    }
  }

  .filter_colmn {
    padding: 0px 15px 15px 15px;
    width: 250px;

    &.filter_colmn_btn {
      width: unset;

      // margin-top: 28px;
      .show_live_upcoming_events {
        position: relative;

        .show_live_up_events {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translate(0, -50%);
          @include font-bold;

          .checkbox {
            .square {
              width: 40px;
              height: 40px;

              &::after {
                top: 2px;
                left: 13px;
                width: 13px;
                height: 28px;
                border-width: 0 5px 5px 0;
                border-radius: 1px;
              }
            }
          }
        }
      }

      @include mediaq(1320px) {
        .show_live_upcoming_events {
          .show_live_up_events {
            .show_live_up_only {
              padding-right: 0.5rem !important;
            }
          }
        }
      }

      @include mediaq(1200px) {
        .show_live_upcoming_events {
          display: flex;
          flex-wrap: wrap;
        }
      }

      @include mediaq(768px) {
        .show_live_upcoming_events {
          justify-content: center;

          .show_live_up_events {
            position: unset;
            transform: unset;
            width: 100%;
            justify-content: center;
          }

          >div {
            width: 100%;
            display: flex;
            justify-content: center;
          }
        }
      }

      @include mediaq(576px) {
        label {
          display: none;
        }
      }
    }

    .select_custom {
      width: 100%;

      .dropdown {
        // padding: 9px 25px 9px 11px;
        border-radius: 4px;
        font-size: 16px;
        width: 100%;
        border: 1px solid $border_1;

        button {
          width: 100%;
          display: block;
          background: none;
          border: none;
          padding: 11px 10px;
          text-align: left;
        }

        .drodown_list {
          padding: 7px 25px 7px 15px;
          position: relative;

          &:hover {
            background: $linkcolor;
            color: $white;

            &.active {
              &:after {
                color: $white;
              }
            }
          }

          &.active {
            &:after {
              content: "\f00c";
              position: absolute;
              right: 5px;
              top: 50%;
              transform: translateY(-50%);
              font-family: $FontAwesome;
              color: $linkcolor;
            }
          }
        }

        .dropdown-menu {
          margin-top: 20px;

          &:before {
            position: absolute;
            width: 0px;
            height: 0px;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid $linkcolor;
            content: "";
            top: -10px;
            left: 20px;
            transition: 0.2s ease;
          }
        }
      }

      .custome__control {
        height: 46px;
        border: 1px solid #e4e7ea;
      }
    }
  }

  .date_filter {
    padding: 0px 15px 15px 15px;

    input[type="text"] {
      max-width: 200px;
    }
  }

  &.marketing_coupontag {
    div[data-radium="true"] {
      div[data-radium="true"] {
        background: $blue_btn !important;
      }
    }
  }

  .switch {
    width: 70px;

    .switch-input {
      &:checked {
        &~.switch-slider {
          &::before {
            transform: translateX(44px);
          }
        }
      }
    }
  }
}

.Add_New_Report {
  input[type="file"] {
    border: 1px solid $border_1;
    border-radius: 4px;
    padding: 8px 10px;
  }

  select {
    font-size: 16px;
    padding: 10px 25px 10px 11px;
    height: auto;
  }
}

.create_event_prizeRow {
  input[type="text"] {
    padding: 8px;
    height: auto;
    font-size: 18px;
    font-family: $HvRegular;
    width: auto;

    &::-webkit-input-placeholder {
      color: $placeHolder;
      font-size: 18px;
      font-family: $HvRegular;
    }
  }
}

.create_event_budgetprize {
  input[type="text"] {
    width: calc(100% - 45px);
  }
}

.add_hotel_pic {
  width: 150px;
  height: 150px;
  border: dashed 1px $border_1;
  cursor: pointer;

  span {
    width: 38px;
    height: 38px;
    background: $lightgray3;
    border-radius: 50%;
    margin: 0 auto 10px;

    i {
      font-size: 18px;
      color: $fontText3;
    }
  }

  p {
    text-align: center;
    font-size: 16px;
    color: $fontText3;
    font-family: $HvRegular;
  }
}

.hotel_list_view {
  padding-top: 50px;
  margin-top: 25px;
  border-top: 1px solid $border_1;

  label {
    font-size: 20px;
  }

  .inner_hotel_list_view {
    padding: 15px;
    border: 1px solid $border_1;
    border-radius: 4px;
    margin-bottom: 15px;
  }

  .hotel_list_img {
    width: 85px;
    height: 85px;

    img {
      object-fit: cover;
      border-radius: 4px;
      width: 100%;
      height: 100%;
    }
  }

  .right_side_hotel_list {
    width: calc(100% - 85px);

    @include mediaq(767px) {
      width: 100%;
      padding-top: 15px;
    }

    .hotel_list_detail {
      width: 60%;
      padding: 0 25px;

      @include mediaq(767px) {
        padding-left: 0;
      }

      @include mediaq(420px) {
        width: 50%;
      }

      h4 {
        font-size: 18px;
        line-height: 26px;
        font-family: $HvRegular;
        color: $fontText3;
      }

      p {
        font-size: 14px;
        line-height: 20px;
        font-family: $HvRegular;
        color: $fontText4;
      }
    }

    .hotel_list_action {
      width: 40%;
    }
  }
}

// cloub house page
.inner_club_popup {
  .close_popup {
    position: absolute;
    right: 25px;
    top: 20px;
    color: #8392a5;
    cursor: pointer;
    font-size: 18px;
    z-index: 2;

    @include mediaq(768px) {
      top: 10px;
    }
  }
}

.cloub_house_wrap {
  background: $darkpurple;

  &:before {
    background: $darkpurple;
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    bottom: 0;
  }

  .grid {
    margin: 0 -15px;
    position: relative;
    z-index: 2;

    .grid-item {
      padding: 15px;
      width: 16.66%;

      @include mediaq(1600px) {
        width: 20%;
      }

      @include mediaq(1400px) {
        width: 25%;
      }

      @include mediaq(1100px) {
        width: 33.33%;
      }

      @include mediaq(768px) {
        width: 50%;
      }

      @include mediaq(480px) {
        width: 100%;
      }
    }
  }

  .club_box {
    border-radius: 4px;
    background: $white;
    overflow: hidden;
    position: relative;

    .club_box_banner {
      position: relative;
      max-height: 405px;
      min-height: 200px;

      .club_box_banner_img {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
        margin: auto;
        width: 100%;
        min-height: 200px;
        max-height: 405px;
      }

      .club_has_vid {
        position: absolute;
        top: 50%;
        left: 50%;
        cursor: pointer;
        transition: 0.3s ease;
        transform: translate(-50%, -50%);
        font-size: 50px;
        background: $white;
        border-radius: 50%;
        width: 50px;
        height: 50px;

        i {
          color: $linkcolor;
        }

        &:hover {
          background: $blue_btn;

          i {
            color: $white;
          }
        }
      }
    }

    .club_box_detail {
      padding: 11px;

      .club_box_user {
        width: 44px;
        height: 44px;
        border: 4px solid $white;
        border-radius: 50%;
        box-shadow: 0 2px 2px rgba(131, 146, 165, 0.1);
        overflow: hidden;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      .club_house_desc {
        width: calc(100% - 60px);
        margin-left: 10px;

        h4 {
          margin-bottom: 0;
          font-size: 18px;
          line-height: 20px;
          font-family: $Hvextrabold;
          color: $fontText2;
        }

        p {
          font-size: 13px;
          color: $fontText3;
          font-family: $Hvmed;
        }
      }
    }
  }
}

.club_popup_header {
  padding: 20px 30px;

  @include mediaq(480px) {
    padding: 20px 30px 0px;
  }

  .club_user {
    width: 54px;
    height: 54px;
    border-radius: 50%;
    overflow: hidden;
    padding: 4px;
    margin-right: 20px;
    box-shadow: 0 6px 8px rgba(227, 224, 224, 0.7);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  .club_user_name {
    width: calc(100% - 80px);

    h2 {
      color: $fontText2;
      font-family: $Hvextrabold;
      margin-bottom: 5px;
    }

    p {
      color: $fontText3;
      font-size: 16px;
    }
  }

  .club_header_desc {
    p {
      font-size: 14px;
      line-height: 20px;
      font-family: $HvRegular;
      color: $fontText2;
    }
  }
}

.club_popup_media {
  img {
    width: 100%;
    object-fit: contain;
    max-height: 470px;

    @include mediaq(768px) {
      max-height: 350px;
    }
  }

  .carousel {
    .thumb {
      img {
        max-height: 35px;
        max-width: 100%;
      }
    }
  }
}

.club_remove_btn {
  padding: 20px 15px 22px;

  .remove_club_popup {
    padding: 9px 20px;
    cursor: pointer;
    background: $light_remove;
    border-radius: 4px;
    font-size: 14px;
    line-height: 22px;
    font-family: $Hvmed;
    color: $white;
    min-width: 158px;
    display: inline-block;
    border: none;
  }
}

.vid_wrapper {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;

  iframe,
  video {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border: none;
  }
}

// user Date POpup
.user_popup_header {
  padding: 25px 30px;

  @include mediaq(480px) {
    padding: 15px;
  }

  .club_user {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    padding: 4px;
    margin-right: 20px;
    border: 1px solid $green;
    box-shadow: 0 6px 8px rgba(227, 224, 224, 0.7);

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  .club_user_name {
    width: calc(100% - 110px);

    h2 {
      color: $fontText2;
      font-family: $Hvextrabold;
      margin-bottom: 5px;
    }

    p {
      color: $fontText3;
      font-size: 13px;
      line-height: 23px;
      line-height: 23px;
      font-family: $Hvmed;
    }
  }

  .club_header_desc {
    p {
      font-size: 14px;
      line-height: 20px;
      font-family: $HvRegular;
      color: $fontText2;
    }
  }
}

.user_popup_content {
  .collaps:not(.react-datepicker__navigation) {
    text-align: left;
    position: relative;
    color: $black;
    background: $accordian;
    width: 100%;
    border: none;
    font-size: 18px;
    line-height: 20px;
    font-family: $Hvbold;
    padding: 12px 40px;

    &:hover,
    &:focus {
      background: $accordian;
      background-color: $accordian !important;
      box-shadow: none !important;
      color: $black;
    }

    .collaps_dropdown_icon {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 24px;
      background: $white;
      width: 25px;
      height: 25px;
      border-radius: 50%;

      i {
        font-size: 16px;
        transition: 0.3s ease;
      }
    }

    &.active_row {
      .collaps_dropdown_icon {
        i {
          transform: rotate(180deg);
        }
      }
    }
  }

  table {
    button {
      text-align: center;
      background: $blue_btn;
      color: $white;

      @include mediaq(640px) {
        padding: 10px 5px;
        font-size: 16px;
      }
    }
  }

  .collaps_row {
    margin-bottom: 2px;

    &:last-child {
      .user_popup_content {
        .collaps_content {
          .collaps_content_row {
            .user_info_data {
              &:before {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  .collaps_content {
    padding: 0 40px;
    position: relative;

    @include mediaq(991px) {
      padding: 0 20px;
    }

    .collaps_content_row {
      .user_info_label {
        width: 100%;
        max-width: 155px;
      }

      .user_info_data {
        position: relative;
        width: calc(100% - 160px);
        padding-left: 30px;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 2px;
          background: $accordian;
          height: 100%;
        }

        &.payment_info {
          padding-left: 0;

          .table-responsive {
            max-height: 250px;
          }

          &::before {
            content: unset;
          }

          button {
            font-size: 16px;
            line-height: 21px;
            font-family: "Roboto";
            text-align: center;
            color: #fff;
            padding: 8px 12px;
            width: auto;
            cursor: pointer;
            float: right;
            background: #02A8A8 !important;
            border: 1px solid #02A8A8 !important;

            &:hover {
              opacity: 0.7;
              color: #fff;
              text-decoration: none;
              box-shadow: #46b5d133;
            }
          }
        }

        table {
          width: 100%;

          th,
          td {
            border: 2px solid #cdcdcd;
            padding: 2px 5px;
            text-align: center;
          }

          td {
            max-width: 62px;
            min-width: 58px;
          }
        }
      }
    }

    h5 {
      margin-bottom: 0;
      padding: 7px;
      font-size: 16px;
      line-height: 20px;
      font-family: $Hvbold;
    }

    p {
      padding: 7px;
      font-size: 16px;
      font-family: $Hvmed;
      color: $dropdowntext;
      line-height: normal;
    }
  }

  .team_add_popup {
    padding: 0 15px 15px;

    .create_event_row {
      @include mediaq(767px) {
        padding-bottom: 10px;
      }
    }

    .add_team {
      display: flex;
      align-items: center;
      padding-top: 15px;
      padding-bottom: 15px;
      border-bottom: 1px solid #e1e2e7;
      border-top: 1px solid #e1e2e7;
      justify-content: space-between;
      flex-wrap: wrap;

      .svg-btn {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        background-color: transparent;
        color: #1b8eb7;
        border: 1px solid #1b8eb7;
        padding: 0px 10px 0px 10px;
        border-radius: 4px;
        height: 45px;
        width: auto;
        font-size: 15px;
        transition: all 0.5s ease 0s;

        >img {
          margin-right: 12px;
        }

        &:hover {
          color: #4d8aae !important;
          border: 1px solid #4d8aae !important;
          box-shadow: 0px 0px 17px -5px rgba(77, 138, 174, 0.9) !important;
          background-color: transparent !important;
        }
      }

      .serch-box {
        width: calc(100% - 185px);

        @include mediaq(767px) {
          width: calc(100% - 200px);
        }

        @include mediaq(575px) {
          width: 100%;
          margin-top: 15px;
        }

        input {
          height: 45px;
          box-shadow: none;
        }
      }
    }

    .scrollbar_team {
      max-height: 290px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      overflow-y: scroll;
      margin: 15px 10px;

      @include mediaq(480px) {
        max-height: 250px;
      }

      .select_team {
        min-height: 100%;
        width: 65%;
        margin-left: auto;
        margin-right: 50px;

        @include mediaq(768px) {
          width: 98%;
        }

        .team-flex {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 15px;

          &:last-child {
            margin-bottom: 0;
          }

          .team-data {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .team-img {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 60px;
              height: 60px;
              margin-right: 20px;
              border-radius: 100%;

              @include mediaq(480px) {
                width: 50px;
                height: 50px;
                margin-right: 5px;
              }

              >img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 100%;
                border: 1px solid #e1e2e7;
              }
            }

            h4 {
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 1.4;
              color: #28263d;
            }
          }

          .radiobox {
            position: relative;
            display: flex;

            input {
              position: absolute;
              opacity: 0;
              left: 0;
              right: 0;
              top: 0;
              height: 100%;
              width: 100%;
              z-index: 1;
              cursor: pointer;
            }

            span {
              position: relative;
              width: 40px;
              height: 40px;
              border-radius: 50%;
              transition: all 0.5s ease 0s;
              background-color: #e5e9f2;

              @include mediaq(480px) {
                width: 30px;
                height: 30px;
              }

              &:after {
                content: "";
                position: absolute;
                width: 20px;
                height: 10px;
                border: 3px solid #ffffff;
                border-top: 0;
                border-right: 0;
                transform: rotate(-40deg) scale(0.6);
                top: 31%;
                left: 11px;
                transition: all 0.5s ease 0s;
                opacity: 0;

                @include mediaq(480px) {
                  width: 16px;
                  height: 8px;
                  left: 7px;
                }
              }
            }

            input:checked~span {
              background: #1b8eb7;

              &:after {
                transform: rotate(-40deg) scale(1);
                opacity: 1;
              }
            }
          }
        }
      }
    }

    .submit_btn {
      display: block;
      margin: 0 auto;
      max-width: 130px;
      text-align: center;
      border-radius: 5px;
      background: #02a8a8;
      border: 2px solid #02a8a8;
      transition: all 0.5s ease 0s;
      color: $white;
      padding: 12px 20px;
      line-height: 1.2;

      @include mediaq(480px) {
        max-width: 100px;
        font-size: 14px;
        padding: 10px 0;
      }

      &:hover,
      &:focus {
        color: #000 !important;
        transition: all 0.5s ease 0s;
        border: 2px solid #02a8a8 !important;
        background-color: #fff !important;
        outline: none;
      }
    }

    .btn-danger {
      display: block;
      margin: 0 auto;
      max-width: 130px;
      text-align: center;
      border-radius: 5px;
      background: #f64846;
      border: 2px solid #f64846;
      transition: all 0.5s ease 0s;
      color: $white;
      padding: 12px 20px;
      line-height: 1.2;

      @include mediaq(480px) {
        max-width: 100px;
        font-size: 14px;
        padding: 10px 0;
      }

      &:hover,
      &:focus {
        color: #000 !important;
        transition: all 0.5s ease 0s;
        border: 2px solid #f64846 !important;
        background-color: #fff !important;
        outline: none;
      }
    }

    .social_btn {
      border: none;
      padding: 0;
      text-align: center;
      position: relative;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 1.2;
      color: #ffffff;
      display: inline-block;
      vertical-align: middle;
      border-radius: 5px;
      box-shadow: 0px 0 24px -6px rgba(0, 0, 0, 0.5);
      height: 60px;
      text-transform: capitalize;
      line-height: 60px;
      transition: all 0.5s ease 0s;
      width: 100%;
      text-decoration: none;

      @include mediaq(767px) {
        margin-bottom: 10px;
        height: 50px;
        line-height: 50px;
        font-size: 15px;
      }

      i {
        font-size: 26px;
        position: absolute;
        left: 15px;
        height: 100%;
        line-height: 60px;

        @include mediaq(767px) {
          height: 50px;
          font-size: 22px;
          line-height: 50px;
        }
      }

      &.facebook {
        background: #4267b2;
      }

      &.instagram {
        background: linear-gradient(to right, #fab749, #f56129, #dd2a7b, #4134af);
      }

      &.twitter {
        background: #00a7e7;
      }
    }

    p.thanks {
      margin-bottom: 20px;
      text-align: center;
      font-weight: 600;
      font-size: 18px;
      color: #28263d;
    }

    .check_input {
      display: flex;

      .check_box {
        // display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-right: 30px;

        &:last-child {
          margin-right: 0;
        }

        &.add_filed {
          flex-direction: column;
          align-items: inherit;

          .form-group {
            margin: 15px 0 0;

            .form-control {
              padding-right: 65px;
            }

            .serch-btn {
              color: #fff;
              font-size: 18px;
              width: 65px;
            }
          }
        }

        .checkbox {
          width: 100%;
          cursor: pointer;
          position: relative;
          margin: 0 15px;
          margin-left: 0;

          label {
            width: 100%;
            border-radius: 4px;
            background-color: transparent;
            text-align: center;
            height: 45px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid $lightgray2;
          }

          input[type="radio"],
          input[type="checkbox"] {
            opacity: 0;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            cursor: pointer;

            &:checked~label {
              background-color: $blue_btn;
              color: #fff;
            }
          }
        }
      }
    }
  }
}

.popup_310 {
  .collaps_content_row {
    position: relative;
    padding: 2px 0;
  }

  .user_popup_header {
    border-bottom: 2px solid $accordian;
  }

  .user_popup_content {
    .collaps_content {
      padding: 25px 10px;
      position: relative;

      &:after {
        position: absolute;
        left: 145px;
        top: 0;
        width: 2px;
        background: $accordian;
        height: 100%;
        content: "";
      }
    }

    .single_user_label {
      width: 100%;
      max-width: 136px;
    }

    .single_user_data {
      width: calc(100% - 137px);
      position: relative;
    }

    h5 {
      padding-right: 15px;
      border: none;
    }

    p {
      padding-left: 25px;
    }
  }
}

.popup_540 {
  .user_popup_header {
    border-bottom: 2px solid $accordian;
  }

  .collaps_content {
    padding: 20px 40px;

    &:before {
      position: absolute;
      left: 245px;
      top: 0;
      width: 2px;
      background: $accordian;
      height: 100%;
      content: "";
    }

    .collaps_content_row {
      .user_info_data {
        &:before {
          display: none;
        }
      }

      .user_info_label {
        max-width: 200px;
      }

      .user_info_data {
        width: calc(100% - 250px);
      }
    }
  }
}

.admin_pic_prev {

  // border: 1px solid $border2;
  img {
    width: 210px;
    height: 120px;
    object-fit: cover;
  }
}

// Marketing page
.customTab {
  &.list_marketing {
    &>.tab-content {
      &>.tab-pane {
        background: none;
      }
    }

    table {
      background: $white;
    }
  }

  table {
    select {
      border: 1px solid $border2;
      padding: 5px 20px 5px 10px;
      border-radius: 2px;
      color: $fontText2;
      -webkit-appearance: none;
      position: relative;
      min-width: 100px;
      background: url("/img/drop-arrow.svg") no-repeat;
      background-position: right 6px center;
      background-size: 11px;
    }
  }
}

.swal-button {
  min-width: 100px;

  &:focus {
    box-shadow: none;
  }
}

.marketing_list_inner {
  box-shadow: 0 2px 2px rgba(131, 146, 165, 0.1);
  background: $white;
  margin-bottom: 10px;
  position: relative;

  .marketing_list_banner_img {
    width: 428px;

    img {
      width: 100%;
      max-width: 100%;
      // object-fit: cover;
      display: block;
      height: 150px;
    }

    @include mediaq(1620px) {
      width: 350px;
    }

    @include mediaq(1360px) {
      width: 300px;
    }

    @include mediaq(768px) {
      margin: auto;
    }
  }

  .marketing_list_right {
    width: calc(100% - 428px);
    padding: 10px 40px 10px 10px;
    position: relative;

    @include mediaq(1620px) {
      width: calc(100% - 350px);
    }

    @include mediaq(1360px) {
      width: calc(100% - 300px);
    }

    @include mediaq(768px) {
      width: 100%;
    }

    @include mediaq(576px) {
      padding: 10px;
    }

    .marketing_list_title {
      width: 25%;
      padding: 15px 15px 15px 40px;

      @include mediaq(1620px) {
        padding: 15px;
      }

      @include mediaq(1450px) {
        width: 85%;
      }

      @include mediaq(576px) {
        width: 100%;
        margin-top: 25px;
      }
    }

    .marketing_list_tags {
      width: 100%;
      padding: 0 15px;

      @include mediaq(1450px) {
        width: 100%;
      }

      @include mediaq(768px) {
        padding: 15px;
      }

      span {
        padding: 10px 30px;
        border-radius: 20px;
        background: $bodyBg;
        min-width: 162px;
        font-family: $Roboto;
        font-weight: 500;
        color: $fontText3;
        margin-right: 30px;
        margin-bottom: 10px;

        @include mediaq(991px) {
          margin-right: 15px;
          padding: 10px 15px;
        }

        &:last-child {
          margin-right: 0;
        }

        img {
          margin-right: 10px;
          width: 100%;
          max-width: 15px;
        }
      }
    }

    .marketing_list_actions {
      padding: 0 10px;
      position: absolute;
      right: 10px;

      @include mediaq(768px) {
        right: 0px;
        top: 10px;
      }

      @include mediaq(576px) {}

      .on_off_chk {
        margin-right: 15px;
      }

      .switch {
        width: 60px;
        height: 35px;
      }

      .switch-input {
        &:checked {
          &~.switch-slider {
            background: $green;

            &::before {
              transform: translateX(24px);
              background: $white;
            }
          }
        }
      }

      .switch-slider {
        background: $lightgray;
        border: none;

        &:before {
          width: 25px;
          height: 25px;
          top: 5px;
          left: 5px;
          border: none;
          background: $green;
        }
      }

      .dropdown {
        button.btn {
          background: none;
          border: none;
          color: $graydark;
          font-size: 20px;
          outline: none;

          &:focus {
            outline: none;
            box-shadow: none;
          }
        }
      }
    }
  }
}

// Lesson Library page
.common_box {
  border-radius: 4px;
  box-shadow: 0 2px 2px rgba(131, 146, 165, 0.1);
  margin-bottom: 20px;
  background: $white;

  .box_banner_img {
    position: relative;

    img {
      height: 220px;
      width: 100%;
      object-fit: cover;
      border-radius: 4px 4px 0 0;
    }

    .play_vid {
      width: 65px;
      height: 65px;
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.7);
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;

      i {
        color: $white;
        font-size: 24px;
        margin-left: 2px;
      }
    }
  }

  .common_box_content {
    padding: 15px;

    .common_box_leftside {
      width: calc(100% - 30px);
    }

    .common_box_logo {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 15px;
      background: $black;
      padding: 12px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .common_box_title {
      width: calc(100% - 70px);

      h3 {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .action_dots {
      .dropdown {
        button {
          background: none;
          border: none;
          font-size: 18px;
          color: $graydark;

          &:focus {
            outline: none;
            box-shadow: none;
          }
        }
      }

      .dropdown {
        .dropdown-item {
          padding: 5px 15px;
          font-size: 14px;
          font-family: $HvRegular;

          &:hover {
            color: $linkcolor;
            background: $lightgray3;
          }
        }
      }
    }
  }
}

.file_custom_upload {
  position: relative;
  height: 120px;
  border: dashed 1px $border_1;
  padding: 35px 10px;
  overflow: hidden;

  i {
    font-size: 30px;
    color: $linkcolor;
    opacity: 0.6;
  }

  p {
    font-size: 14px;
    font-family: $HvRegular;
    color: $fontText4;
  }

  input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  .image_upload_preview {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    &.active {
      background: $white;
    }

    img {
      max-width: 100%;
      max-height: 100%;
      margin: auto;
      height: 100%;
      object-fit: cover;
    }
  }
}

.image_side_preview {
  border: 1px dashed $border2;
  position: relative;

  .remove_preview_image {
    position: absolute;
    right: -10px;
    top: -10px;
    width: 20px;
    height: 20px;
    background: $error;
    text-align: center;
    border-radius: 50%;
    color: $white;
    line-height: 17px;
    cursor: pointer;
  }

  img {
    max-height: 120px;
    margin: auto;
    height: 120px;
  }

  label {
    margin: auto;
    height: 40px;
    padding: 9px 0 0 8px;
  }
}

// map geocoding
.geosuggest__suggests.geosuggest__suggests--hidden {
  max-height: 0;
  overflow: hidden;
  border-width: 0;
}

.geosuggest__suggests {
  border: 1px solid $border_1;

  li {
    padding: 2px 10px;
    cursor: pointer;

    &:hover {
      background: $blue_btn;
      color: $white;
    }
  }
}

.geosuggest__item--active {
  background: $blue_btn;
  color: $white;
}

// login form
.auth_form {
  label {
    color: $fontText3;
    font-family: $HvRegular;
  }

  input {
    font-family: $HvRegular;
  }

  .forgot_link {
    outline: none;
    font-family: $HvRegular;
    color: $black;

    &:focus {
      box-shadow: none;
    }
  }
}

// loader css
.loader_custom {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 99999;
  display: block;

  img {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

// port page

.action_btn {
  padding: 5px 10px;
  font-size: 12px;
  min-width: 70px;
  display: inline-block;
  border-radius: 4px;
  margin-bottom: 5px;
}

.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px;
  border-radius: 4px;

  >li {
    display: inline;

    >a,
    >span {
      position: relative;
      float: left;
      padding: 6px 12px;
      margin-left: -1px;
      line-height: 1.42857143;
      color: $blue_btn;
      text-decoration: none;
      background-color: $white;
      border: 1px solid $border2;
    }

    &:first-child {

      >a,
      >span {
        margin-left: 0;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
    }

    &:last-child {

      >a,
      >span {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }

  >li>a,
  >li>span {

    &:hover,
    &:focus {
      color: $blue_btn;
      background-color: $lightgray;
      border-color: $border2;
    }
  }

  >.active>a,
  >.active>span {

    &,
    &:hover,
    &:focus {
      z-index: 2;
      color: $white;
      cursor: default;
      background-color: $blue_btn;
      border-color: $blue_btn;
    }
  }

  >.disabled {

    >span,
    >span:hover,
    >span:focus,
    >a,
    >a:hover,
    >a:focus {
      color: $lightgray;
      cursor: not-allowed;
      background-color: $white;
      border-color: $border2;
    }
  }
}

//   Data list css
input.inputdropdown {
  border: 1px solid $border2;
  padding: 4px 10px;
  border-radius: 5px;
  background: url("/img/drop-arrow.svg") no-repeat;
  background-position: right 6px center;
  background-size: 11px;
}

input.inputdropdown::-webkit-calendar-picker-indicator {
  display: none;
}

.inputdropdown .css-yk16xz-control {
  width: 190px;
}

//  404 page
.page_404_bg {
  background-image: radial-gradient(closest-side, #02a8a83d, #f9f9f9, #02a8a838);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.page_404 {
  h1 {
    font-size: 15vw !important;
    font-family: $Hvbold;
    position: relative;
    z-index: 2;
    mix-blend-mode: color-burn;

    @include mediaq(520px) {
      mix-blend-mode: unset;
    }
  }

  h2 {
    position: relative;
    z-index: 2;
    font-size: 24px !important;
  }

  p {
    position: relative;
    z-index: 2;
    font-size: 18px !important;
  }

  a {
    position: relative;
    z-index: 2;
    font-size: 18px !important;
    text-decoration: none;
    color: $black;
    font-family: $Hvextrabold;
  }

  .bubble_animation {
    @include mediaq(520px) {
      display: none;
    }
  }

  .circle {
    border-radius: 50%;
    background-color: #02a8a8;
    width: 310px;
    height: 310px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    opacity: 0.5;
    animation: scaleIn 6s infinite linear;
    z-index: 0;
  }

  @keyframes scaleIn {
    0% {
      transform: scale(0.5, 0.5);
      opacity: 0.5;
    }

    100% {
      transform: scale(1.8, 1.8);
      opacity: 0;
    }
  }
}

// datastatistics page
.datastatistics {
  table {
    thead {
      th {
        background: $light_bg;
        color: $graydark;
        font-size: 14px;
        font-family: $Hvmed;
        font-weight: normal;
        // border: 0;
      }
    }

    tbody {
      td {
        font-size: 14px;
        line-height: 20px;
        font-family: "Helvetica Medium";
        color: #28263d;
        vertical-align: middle;
        // border: 0;
      }
    }
  }
}

.player_coach {
  .search-pc {
    padding: 0 30px 30px;

    .svg-btn {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      background-color: transparent;
      color: #1b8eb7;
      border: 1px solid #1b8eb7;
      padding: 0 20px;
      border-radius: 4px;
      height: 45px;
      width: auto;
      font-size: 15px;
      transition: all 0.5s ease 0s;
      white-space: nowrap;
      margin-right: 10px;

      img {
        margin-right: 10px;
      }
    }

    input {
      height: auto;
    }

    button {
      width: auto;
      padding: 12px;
      text-align: center;
    }
  }

  .search-box {
    padding: 30px;
  }

  .scrollbar_team {
    max-height: 290px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow-y: scroll;
    margin: 15px 10px;

    @include mediaq(480px) {
      max-height: 250px;
    }

    .select_team {
      min-height: 100%;
      width: 65%;
      margin-left: auto;
      margin-right: auto;

      @include mediaq(768px) {
        width: 98%;
      }

      .team-flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }

        .team-data {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .team-img {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 60px;
            height: 60px;
            margin-right: 20px;
            border-radius: 100%;

            @include mediaq(480px) {
              width: 50px;
              height: 50px;
              margin-right: 5px;
            }

            >img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 100%;
              border: 1px solid #e1e2e7;
            }
          }

          h4 {
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 1.4;
            color: #28263d;
          }
        }

        .radiobox {
          position: relative;
          display: flex;

          input {
            position: absolute;
            opacity: 0;
            left: 0;
            right: 0;
            top: 0;
            height: 100%;
            width: 100%;
            z-index: 1;
            cursor: pointer;
          }

          span {
            position: relative;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            transition: all 0.5s ease 0s;
            background-color: #e5e9f2;

            @include mediaq(480px) {
              width: 30px;
              height: 30px;
            }

            &:after {
              content: "";
              position: absolute;
              width: 20px;
              height: 10px;
              border: 3px solid #ffffff;
              border-top: 0;
              border-right: 0;
              transform: rotate(-40deg) scale(0.6);
              top: 31%;
              left: 11px;
              transition: all 0.5s ease 0s;
              opacity: 0;

              @include mediaq(480px) {
                width: 16px;
                height: 8px;
                left: 7px;
              }
            }
          }

          input:checked~span {
            background: #1b8eb7;

            &:after {
              transform: rotate(-40deg) scale(1);
              opacity: 1;
            }
          }
          .checked {
            background: #1b8eb7;

            &:after {
              transform: rotate(-40deg) scale(1);
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

.width_full {
  width: 100%;
}

.main-event-privew {
  .container_900 {
    max-width: 100%;
    .row{
      .col-lg-9{
        .bg_white{
          .create_event_title {
            >div{
              position: relative;
              >button{
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                min-width: 215px;
                line-height: 21px;
                height: 47px;
              }
              @include mediaq(1575px){
                display: flex;
                justify-content: space-between;
                >button{
                  position: unset;
                  transform: unset;
                }
              }
              @include mediaq(768px){
                flex-wrap: wrap;
                justify-content: center;
                >button{
                  margin-top: 15px;
                }
              }
            }
          }
        }
      }
    }
  }

  .event_card_desc {
    @include mediaq(1366px) {
      width: 100%;
      display: block;
    }

    .event_card_titles {
      @include mediaq(1366px) {
        width: 100%;
      }
    }
  }
}

.showcase-product {
  img {
    width: 100%;
    height: 120px;
    object-fit: cover;
  }
}

.event-registration {
  li {
    list-style: none !important;
  }

  .team-data {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ebebeb;
    margin-bottom: 8px;
    padding-bottom: 8px;

    .team-img {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;
      height: 60px;
      margin-right: 20px;
      border-radius: 100%;

      @include mediaq(480px) {
        width: 50px;
        height: 50px;
        margin-right: 5px;
      }

      >img {
        border-radius: 100%;
        border: 1px solid #e1e2e7;
        height: 60px;
        max-width: 60px;
        object-fit: cover;
      }
    }

    .team-info {
      h4 {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 1.4;
        color: #28263d;
        margin-bottom: 4px;
      }

      h6 {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #28263d;
        margin-bottom: 4px;
      }

      p {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #63636d;
        margin-bottom: 4px;
      }
    }
  }
}

.userdatatable-ptcf {
  thead {
    th {
      white-space: nowrap;
    }
  }
}

.sub-table {
  background-color: transparent;

  thead {
    tr {
      &:hover {
        background-color: transparent;
      }

      th {
        font-weight: 600;
        color: $fontText2;
        background-color: transparent;
        padding: 4px 10px;
      }
    }
  }

  tbody {
    tr {
      &:hover {
        background-color: transparent;
      }

      td {
        padding: 4px 10px;
      }
    }
  }
}

.custome-wrap {
  table {
    white-space: nowrap;
  }
}

.create_event_row .filter_colmn_btn.export_btn{
  width: 100%;
}

.create_event_row .organization_filter{
  display: block !important;
  width: 100%;
}

.create_event_row{
  .organization_filter{
      .filter_colmn{
        float: left;
        &.organization_clear_filter{
          float: right;
        }
      }
  }
}

.create_event_row{
  &.selected_event{
    .filter_colmn{
      width: 100%;
      padding: 0;
      
      .select_custom{
        .css-plu760-control{
          background: #E5E9F2;
        }
      }
    }
  }
  .organization_filter{
    .date_filter{
      float: left;
    }
  }
}