.custom_bracker_chart_sec {
  width: 100%;

  .scheduling-tab-group {
    justify-content: unset;
  }

  .scheduling-tab-group {
    justify-content: unset;
  }

  .custom_bracker_chart_btn_sec {
    width: 100%;
    justify-content: start;
    padding-top: 10px;

    .mode_btn {

      padding-bottom: 10px;

      span {
        color: $blue_text;
        cursor: pointer;
      }
    }
  }


  .custom_chart_area {
    background: rgb(40, 40, 40);
    width: 100%;
    height: 800px;
    border: solid 1px black;
    position: relative;
    overflow: scroll;
    max-width: 1420px;
    margin: 0 auto;
    scroll-behavior: smooth;

    .custom_chart_area_sec {
      border-radius: 5px;
      width: 32000px;
      height: 32000px;
      position: relative;
    }    
  }
  .chart_top_btn{
    width: 100%;
    max-width: 1420px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    
    .btn_top{
      padding: 4px;
      color: white;
      background: #96CF04;
      border: 0;
      margin-top: -80px;
      margin-right: 30px;
      z-index: 9;
      border-radius: 50%;
      font-size: 12px;
      display: none;
    }
    &.up_arrow{
      .btn_top{
        transform: rotate(180deg);
        margin-top: 0;
        margin-bottom: -80px;
        display: block;
      }
    }
  }
}

.custom_drag_sec {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  z-index: 999;
  &.react-draggable{
    &:hover{
      z-index: 999999;
    }
  }

  .handle {
    background-color: transparent;
    border-right: 2px solid #96cf04;
    border-top: 2px solid #96cf04;
    border-bottom: 2px solid #96cf04;
    border-radius: 1px;
    position: relative;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 3px 0 3px 5px;

    .handle_sec {
      background: transparent;
      padding-right: 5px;

      .handle_part {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        position: relative;
        .game_number{
          padding-right: 10px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 5px;
          p{
            color: #fff;
          }
        }
        .source_section {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
          width: 100%;

          .source_part {
            .custom_source {
              width: 80px;
              font-size: 8pt;
              border: 1px solid #96cf04;
              color: #96cf04;
            }
          }

          .abs_source_part {
            position: absolute;
            top: -25px;
            left: 0;
            display: flex;

            .source_part:nth-child(n+2) {
              padding-left: 5px;
            }
          }

          .down_source_part {
            display: flex;
            position: absolute;
            bottom: -25px;

            .source_part {
              .check_box_sec {
                display: flex;
                align-items: center;
                padding-right: 5px;

                .checkbox {
                  margin-bottom: unset;
                  padding-left: 5px;

                  .square {
                    margin-right: unset;
                  }
                }
              }
            }
          }

          .game_source_list {
            display: flex;
            // margin: 3px 0;
          }

          .game_para_sec {
            display: flex;
            padding: 0 10px 0 3px;
            justify-content: space-between;
            align-items: flex-start;

            .game_para_number_sec {
              margin-top: 5px;
            }

            .game_para {
              padding-right: 5px;
            }

            &:last-child {
              align-items: flex-end;

              .game_para_number_sec {
                margin-bottom: 5px;
                margin-top: unset;
              }
            }
          }

          select {
            color: #000;
          }

        }

        .game_no {
          top: 50%;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
          position: absolute;
          right: 5px;

          select {
            color: #000;
          }

          &.co_champion {
            display: flex;
            align-items: center;

            .co_champ {
              color: #fff;
              padding: 0 5px;
              font-size: 11px;
            }
          }
        }
      }
    }

    .drag_opt {
      padding-left: 5px;
      padding-right: 5px;

      &.green_opt {
        color: rgb(150, 207, 4);
      }

      &.red_opt {
        color: red;
      }
    }

    .game_para {
      color: #fff;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      padding: 0 3px;
    }

    .game_para_number_sec {
      min-width: 30px;
      color: #fff;
      height: auto;
      display: flex;
      align-items: flex-start;
      background: #96cf04;
      border-radius: 5px;
      padding: 1px 3px;
      justify-content: center;
    }

    .final_para {
      color: #fff;
      text-align: center;
      margin-top: 0;
    }

    .final_line {
      border-bottom: 1px solid #96CF04;
    }

    .final_section {
      justify-content: center !important;
    }

    .final_section_line {
      .source_list {


        .final_line {
          position: relative;
          border: unset;
            p{
              padding-left: 12px;
              color: #fff;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 250px;
            }

          &::before {
            content: '';
            position: absolute;
            width: 100%;
            background: #96CF04;
            height: 2px;
            top: 0;
          }
        }

        .final_para {
          padding-left: 10px;
          padding-bottom: 5px;
          text-align: left;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 250px;
        }
      }

      position: relative;
    }

    &.final_handle {
      border: none !important;
      padding-left: 0;
    }

    select {
      width: 80px;
      font-size: 8pt;
      border: 1px solid rgb(150, 207, 4);
      color: rgb(150, 207, 4);
      font-size: 11px;
      font-weight: 600;
    }

    .game_tooltip {
      position: relative;
      cursor: pointer;

      .game_tooltip_hidden {
        position: absolute;
        top: 30px;
        right: 0;
        background: #96cf04;
        padding: 5px 10px;
        color: #fff;
        display: none;
        width: 180px;
        white-space: initial !important;
        text-overflow: ellipsis;
        z-index: 99999;
        border-radius: 10px;
        box-shadow: 0 14px 28px rgba(150, 207, 4, 0.25), 0 10px 10px rgba(150, 207, 4, 0.25);
        &::before {
          content: '';
          position: absolute;
          top: -9px;
          right: 4px;
          width: 0;
          height: 0;
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;
          border-bottom: 14px solid #96cf04;
        }
      }

      &:hover {
        .game_tooltip_hidden {
          display: block;
        }
      }
    }


    &.page_break {
      background-color: red;
      border: none;

      p {
        width: calc(calc(100% - 22px));
        text-align: center;
      }
    }

    &.page_break_read_only {
      border: none;
      height: 100%;
      padding: 0;

      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        background: #96cf04;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .final_sec_input {
      width: 76%;
      border: none;
      margin-top: 7px;
    }

    &.note_ele_handle {
      border: 2px solid #96cf04;
      border-radius: 10px;

      .handle_sec {
        width: 100% !important;
        max-width: 250px;
      }
    }

    .notes_sec_input {
      width: 76%;
      border: none;
    }

    .note_ele_text {
      color: #fff;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      padding: 0 5px 0 0;
      line-height: 130%;
      font-size: 14px;
      text-align: center;
    }
  }
}

.custom_bracket_chart_popup {
  .library_preview_popup {
    height: 95vh;
    background: #282828 !important;
    border: 2px solid #96CF04;

    .inner_popup {
      background: #282828 !important;

      .close_popup {
        i {
          color: #fff;
        }
      }
    }
  }
}

.preview_loading_sec {
  height: 100%;

  .preview_loading_section {
    display: flex;
    height: 100%;
  }
}

.no_preview_data{
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom_popup .popup_pad {
  &.custom_popup_720 {
    max-width: 720px !important;

    .add_library_form {
      .library_form {
        width: 100%;
      }
    }
  }
}

.custom_bracket_chart_sec {
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 15px;
}

.bracket_container{
  max-width: 1584px;
  margin: 0 auto;
}

.custom_drag_sec .handle .final_section_line .source_list .final_para{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 250px;
}

.source_list{
  .cancel{
    margin-right: 2px;
  }
}